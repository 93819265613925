import PropTypes from "prop-types";
import React, { Component } from "react";
import { Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReactTable from "react-table";
import { getConsulenteId, isLoggedUserAConsulente, isSuperconsulente } from "../../../utils/storage";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: "0 60px 60px 60px",
    borderRadius: "20px",
    boxShadow: theme.shadows[2],
    background: "transparent",
  },
  selectedRow: {
    backgroundColor: theme.palette.cndColors.yellow,
  },
});

class ResponsabileEsercizioCommerciale extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    selectedRowId: PropTypes.number,
    onSelectionChange: PropTypes.func.isRequired,
    responsabiliSicurezza: PropTypes.arrayOf(PropTypes.object),
  };

  render() {
    const { classes } = this.props;
    const consulenteId = isLoggedUserAConsulente()
    ? Number.parseInt(getConsulenteId(), 10)
    : null;
    const superconsulenteView = isSuperconsulente() === "true";
    const columns = [
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        width: 180,
        filterable: true,
      },
      {
        Header: "Email responsabile",
        id: "email",
        accessor: (d) => d.email,
        filterable: true,
      },
      {
        Header: "Associa",
        accessor: "associa",
        width: 180,
        show:(consulenteId === null || superconsulenteView),
        Cell: (row) => (
          <input
            type="radio"
            name="association"
            checked={this.props.selectedRowId === row.original.id}
            onChange={() => {
              this.props.onSelectionChange(row.original.id);
            }}
          />
        ),
      },
    ];

    return (
      <Paper className={classes.paper}>
        <Typography
          variant="h6"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          Responsabile presso punto vendita
        </Typography>
        <ReactTable
          data={this.props.responsabiliSicurezza}
          columns={columns}
          defaultPageSize={10}
          resizable={true}
          previousText="Precedente"
          nextText="Successivo"
          noDataText="Nessun record"
          pageText="Pagina"
          ofText="di"
          rowsText="righe"
          pageJumpText="Vai a pagina"
          rowsSelectorText="righe per pagina"
          defaultFilterMethod={(filter, row, _column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              : true;
          }}
          style={{ height: 450, width: "65vw", alignSelf: "center" }}
          getTrProps={(_state, rowInfo) => ({
            className:
              rowInfo && rowInfo.row.id === this.props.selectedRowId
                ? classes.selectedRow
                : "",
          })}
          defaultSorted={[
            {
              id: "id",
              desc: false, // 'false' per ordinamento crescente
            },
          ]}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(ResponsabileEsercizioCommerciale);
