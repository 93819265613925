import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
  
export const getAbbonamenti = (gruppoId, consulenteId, esercizioId, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = '/api/abbonamenti';
  let query = "?"
    + "page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby + ".dir=ASC"
    ;
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  if (consulenteId !== null) {
    query = query + "&consulenteId.equals=" + consulenteId;
  }
  if (esercizioId !== null) {
    query = query + "&esercizioCommercialeId.equals=" + esercizioId;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
        headers: {
          'Authorization' : authtoken
        },
        withCredentials: true,
        credentials: 'include'
    }), errorFunction
  );
}

export const registerPagamento = (requestBody) => {
  const token = retrieveToken();
  const PATH = '/api/abbonamenti/pagamenti/registrazione';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Registrazione del pagamento non riuscita: dati non corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
          'Authorization' : authtoken,
          'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}
