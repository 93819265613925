import React from "react";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";

import UploadImageComponent from "../components/UploadImageComponent";

import PropTypes from 'prop-types';

const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        marginTop: '20px',
        marginBottom: '20px',
    },
    title: {
        margin: '20px 0px',
    },
    description1: {
        margin: '0px 0px 5px 0px',
    },
    description2: {
        margin: '5px 0px 10px 0px',
    },
}

export default function UploadLogo (props) {
    const description1 = "E' possibile caricare fino a 2 loghi per il brand. La dimensione massima consentita è 150KB.";
    const description2 = "I file caricati devono essere in formato JPEG (o JPG) o PNG.";
    return (
        <Container maxWidth='md' style={styles.root}>
            <Typography variant="h6" style={styles.title}>
                Carica nuovo logo del brand
            </Typography>
            <Typography variant="body1" style={styles.description1}>
                {description1}
            </Typography>
            <Typography variant="body1" style={styles.description2}>
                {description2}
            </Typography>
            <UploadImageComponent
                disabled={props.disabled}
                loading={props.loading}
                onSubmit={props.onUpload}
                accept={'image/*'}
            />
        </Container>
    );
}

UploadLogo.propTypes = {
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onUpload: PropTypes.func.isRequired,
}
