import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getBrands = (page, size, sortby) => {
  const PATH = '/api/brand';
  let URL = BASE_URL + PATH 
   + "?page=" + page
    + "&size=" + size
    + "&sort=" + sortby
   // + nome ? "&nome.contains=" +nome : ""
    + "&" + sortby + ".dir=ASC";
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
    }), errorFunction
  );
}

export const getBrand = (brandId) => {
  const PATH = "/api/brand/" + brandId; 
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 404) {
      message = "Caricamento dati fallito: nessun gruppo corrispondente all'ID indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
    }), errorFunction
  );
}

export const createBrand = (brandName) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/brand';
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: null,
    nome: brandName,
    loghi: []
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Registrazione del brand non riuscita: dati non corretti.';
    } else if (status === 409) {
      message = "Registrazione del brand non riuscita: esiste già un brand registrato con il nome indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const updateLoghiBrand = (brandId, nomeBrand, loghiIds) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/brand';
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: brandId,
    nome: nomeBrand,
    loghi: loghiIds
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      // If error 404, one of the ids does not correspond to an existing logo.
      message = 'Salvataggio non riuscito: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
          'Authorization' : authtoken,
          'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}