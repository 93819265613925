import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line

const CustomPaginationComponent = ({ page, pages, onPageChange }) => {
  const [inputValue, setInputValue] = useState(page + 1);

  useEffect(() => {
    if (pages === 0) {
      setInputValue(page);
    } else {
      setInputValue(page + 1);
    }
  }, [page, pages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      let newPage = parseInt(inputValue, 10);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= pages) {
        onPageChange(newPage - 1);
      }
      if (newPage > pages) {
        onPageChange(pages - 1);
        setInputValue(pages);
      }

      if (newPage < 1) {
        onPageChange(0);
        setInputValue(1);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.value > pages) {
      setInputValue(pages);
    } else {
      setInputValue(e.target.value);
    }
  };

  const handleNextClick = () => {
    const nextPage = page + 1;
    if (nextPage < pages) {
      setInputValue(nextPage + 1);
      onPageChange(nextPage);
    }
  };

  const handlePreviousClick = () => {
    const previousPage = page - 1;
    if (previousPage >= 0) {
      setInputValue(previousPage + 1);
      onPageChange(previousPage);
    }
  };

  return (
    <div className=".ReactTable pagination">
      <button
        className=".ReactTable previous"
        onClick={handlePreviousClick}
        disabled={page === 0}
      >
        Precedente
      </button>
      <span className=".ReactTable  pageIndex">
        Pagina
        <input
          id="myNumberInput"
          type="number"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={{ width: "50px", marginTop: "2px" }}
          min={1}
        />
        di {pages}
      </span>

      <button
        className=".ReactTable next"
        onClick={handleNextClick}
        disabled={page === pages - 1 || pages === 0}
      >
        Successivo
      </button>
    </div>
  );
};

const BasicTable = ({
  data,
  columns,
  defaultPageSize,
  onPageChange,
  pages,
  style,
  page,
  loading,
}) => {
  return (
    <div>
      <ReactTable
        loading={loading}
        loadingText=""
        data={data}
        columns={columns}
        sortable={false}
        filterable
        resizable
        defaultPageSize={defaultPageSize}
        pages={pages}
        manual
        style={style}
        onPageChange={onPageChange}
        page={page}
        defaultFilterMethod={(filter, row, _column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
            : true;
        }}
        showPageSizeOptions={false}
        PaginationComponent={CustomPaginationComponent}
        noDataText="Nessun record"
      />
    </div>
  );
};

export default BasicTable;
