import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getComponentiParagrafo = (paragrafoId) => {
  const PATH = "/api/paragrafi/contenuto/" + paragrafoId;
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Record non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateComponentiParagrafo = (paragrafo) => {
  const PATH = "/api/paragrafi/contenuto";
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Si è verificato un errore durante il salvataggio.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(paragrafo),
    }),
    errorFunction
  );
};

export const getSchedaHaccp = (schedaHaccpId) => {
  const PATH = "/api/paragrafi/schedehaccp/" + schedaHaccpId;
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Record non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const cloneMultipleSchedaHaccp = (paragrafoId, schedeHaccpIds) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi/schedehaccp/clona/multi";
  const URL = BASE_URL + PATH;
  const requestBody = {
    paragrafoId,
    schedeHaccpIds,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      //404 if Paragrafo is not found.
      message = "Copia della scheda Haccp non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
export const cloneSchedaHaccp = (paragrafoId, schedaHaccpId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/paragrafi/schedehaccp/clona";
  const URL = BASE_URL + PATH;
  const requestBody = {
    paragrafoId,
    schedaHaccpId,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      //404 if Paragrafo is not found.
      message = "Copia della scheda Haccp non riuscita: dati non corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const getPuntiDiControlloForModelloBase = (
  manualeId,
  page,
  size,
  sortby
) => {
  const PATH = "/api/paragrafi/schedehaccp/punti_controllo";
  let query =
    "?" +
    "manualeId.equals=" +
    manualeId +
    "&page=" +
    page +
    "&size=" +
    size +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC";
  const URL = BASE_URL + PATH + query;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateSchedaHaccp = (
  schedaId,
  paragrafoId,
  titolo,
  punticontrollo
) => {
  const PATH = "/api/paragrafi/schedehaccp";
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const requestBody = {
    id: schedaId,
    paragrafoId,
    titolo,
    punticontrollo,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Si è verificato un errore durante il salvataggio.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const getProceduraHaccp = (proceduraId) => {
  const PATH = "/api/paragrafi/procedurehaccp/" + proceduraId;
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Record non trovato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateProceduraHaccp = (requestBody) => {
  const PATH = "/api/paragrafi/procedurehaccp";
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Si è verificato un errore durante il salvataggio.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const uploadProceduraImage = (file) => {
  const PATH = "/api/paragrafi/procedurehaccp/immagini/";
  const URL = BASE_URL + PATH;
  let formData = new FormData();
  formData.append("file", file);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 413) {
      message =
        "Caricamento fallito: il formato dell'immagine o la dimensione dell'immagine non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "POST",
      body: formData,
    }),
    errorFunction
  );
};

export const updateImage = (id, nome) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/loghi";
  const URL = BASE_URL + PATH;
  const requestBody = {
    id,
    nomeFile: nome,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // Status 404 if id does not correspond to a registered Logo.
      message = "Aggiornamento logo non riuscito: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};

export const getControlliSenzaAttrezzature = (manualeId) => {
  const token = retrieveToken();
  const authtoken = `Bearer ${token}`;
  const PATH = `/api/paragrafi/procedurehaccp/controlli_senza_attrezzature?manualeId=${manualeId}`;
  const URL = BASE_URL + PATH;

  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
