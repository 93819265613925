import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";

import CustomTable from "../components/CustomTable";
import ActionButton from "../../../components/ActionButton";

import moment from 'moment';
import 'moment/locale/it';

import PropTypes from 'prop-types';
import theme from '../../../theme.js';

const CustomCheckbox = withStyles({
    root: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        margin: '30px 0px',
        padding: '10px 10px',
    },
}

const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default function TabellaModelliPiattaforma(props) {

    const isSelected = (record) => {
        let isChecked = false;
        for (let i = 0; i < props.selectedModelli.length; i++) {
            if(props.selectedModelli[i].id === record.id) {
                isChecked = true;
                break;
            }
        }
        return isChecked;
    }

    const getGruppo = (record) => {
        let gruppo = '';
        for (let i = 0; i < props.gruppi.length; i++) {
            if(props.gruppi[i].id === record.gruppoId) {
                gruppo = props.gruppi[i].nome;
                break;
            }
        }
        return gruppo;
    }

    const columns = [
        {
            id: 'seleziona',
            Header: 'Seleziona',
            accessor: row => row,
            sortable: false,
            filterable: false,
            width: 124,
            Cell: propsCell => 
                <CustomCheckbox
                    name="isSelected"
                    checked={isSelected(propsCell.value)}
                    onChange={e => { props.onSelectionChanged(propsCell.value, e)}}
                />
        },
        {
            Header: 'ID',
            id: 'id',
            accessor: d => d.id,
            width: 182,
        },
        {
            Header: 'Data ultima modifica',
            id: 'dataUltimoAggiornamento',
            sortable: false,
            filterable: false,
            accessor: d => d.dataUltimoAggiornamento ? moment(d.dataUltimoAggiornamento).locale("it").format(dateTimeFormat) : '-',
            width: 196,
        },
        {
            Header: 'Titolo',
            id: 'titolo',
            accessor: d => d.titolo,
            minWidth: 120,
        },
        {
            Header: 'Regolamenti',
            id: 'regolamenti',
            accessor: d => d.regolamenti,
            minWidth: 120,
        },
        {
            Header: 'Gruppo di appartenenza',
            id: 'gruppo',
            accessor: row => row,
            width: 216,
            Cell: propsCell => getGruppo(propsCell.value)
        },
    ];
    return (
        <Container maxWidth={false} style={styles.root}>
            <Typography variant="body1" >
                Selezionare i modelli da clonare dal seguente elenco dei modelli pubblicati disponibili sulla piattaforma BS-CONAD:
            </Typography>
            <CustomTable 
                columns={columns}
                records={props.modelli}
                loading={false}
                filterable={true}
                resizable={true}
                sortable={true}
                defaultPageSize={5}
            />
            <Grid container justify="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <ActionButton 
                        label={props.selectedModelli.length === 0 ? "Clona i modelli selezionati" : "Clona i " + props.selectedModelli.length + " modelli selezionati" }
                        disabled={props.selectedModelli.length === 0}
                        onClick={props.onSubmit}
                        grayVersion={false}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

TabellaModelliPiattaforma.propTypes = {
    modelli: PropTypes.array.isRequired,
    gruppi: PropTypes.array.isRequired,
    selectedModelli: PropTypes.array.isRequired,
    onSelectionChanged: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}