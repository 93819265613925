import React, { Component } from "react";
import { TextField, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';

import 'moment/locale/it';
import moment from 'moment';

import PropTypes from 'prop-types';

import ActionButton from '../../../components/ActionButton';

const styles = {
    modalcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '500px',
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        //marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "row",
        justifyContent: 'center',
    },
}

export default class ModalDettagliAbbonamento extends Component {

    render() {
        const dateFormat = "DD-MM-YYYY HH:mm";
        let modalita = this.props.Abbonamento.pagamento !== null? (
            this.props.Abbonamento.pagamento.modalita === null ? 'PAYPAL' : this.props.Abbonamento.pagamento.modalita
        ) : '-';
        let abbonamento = this.props.Abbonamento;
        if (abbonamento.pagamento === null) {
            abbonamento.pagamento = {
                id: null,
                dataCreazione: null,
                dataAggiornamento: null,
                stato: null,
                importoLordo: null,
                importoNetto: null,
                tariffaServizio: null,
                iva: null,
                nomePayer: null,
                cognomePayer: null,
                emailPayer: null,
                modalita: null,
                transazioneId: null,
                dataPagamento: null
            }
        }
        return (
            <Modal
                open={this.props.modal}
                onClose={this.props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={styles.modalcontainer}>
                    <div style={styles.modalsectioncontainer}>
                        <Grid container justify="center" alignItems="center">
                            <Typography variant="h6" style={{ paddingTop:'8px', paddingBottom:'8px'}} >
                                Dettagli pagamento abbonamento
                            </Typography>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                <Grid container style={styles.userdatacontainer}>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                            <TextField
                                                label="Modalità pagamento"
                                                disabled
                                                value={modalita}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="ID Pagamento"
                                                disabled
                                                value={abbonamento.pagamento.id === null ? "-" : abbonamento.pagamento.id}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="Data creazione"
                                                disabled
                                                value={abbonamento.pagamento.dataCreazione === null ? "-" : moment(abbonamento.pagamento.dataCreazione).format(dateFormat)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            {
                                                modalita === 'PAYPAL' ?
                                                <TextField
                                                    label="Data aggiornamento"
                                                    disabled
                                                    value={abbonamento.pagamento.dataAggiornamento === null ? "-" : moment(abbonamento.pagamento.dataAggiornamento).format(dateFormat)}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                :
                                                <TextField
                                                    label="Data pagamento"
                                                    disabled
                                                    value={abbonamento.pagamento.dataPagamento === null ? "-" : moment(abbonamento.pagamento.dataPagamento).format(dateFormat)}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                            }
                                            { modalita === 'PAYPAL' ?
                                                <TextField
                                                    label={"ID ordine PayPal"}
                                                    disabled
                                                    value={abbonamento.pagamento.paypalOrderId === null ? "-" : abbonamento.pagamento.paypalOrderId}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                :
                                                <TextField
                                                    label={"ID transazione"}
                                                    disabled
                                                    value={abbonamento.pagamento.transazioneId === null ? "-" : abbonamento.pagamento.transazioneId}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                            }
                                            <TextField
                                                label="Stato pagamento"
                                                disabled
                                                value={abbonamento.pagamento.stato === null ? "-" : abbonamento.pagamento.stato}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="Importo lordo"
                                                disabled
                                                value={abbonamento.pagamento.importoLordo === null ? "-" : abbonamento.pagamento.importoLordo + ' €'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                            <TextField
                                                label={modalita === 'PAYPAL' ? 'Tariffa PayPal' : 'Tariffa servizio'}
                                                disabled
                                                value={abbonamento.pagamento.tariffaServizio === null ? "-" : abbonamento.pagamento.tariffaServizio + ' €'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="IVA"
                                                disabled
                                                value={abbonamento.pagamento.iva === null ? "-" : abbonamento.pagamento.iva + ' €'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label={'Totale acquisto'}
                                                disabled
                                                value={abbonamento.totaleNetto === null ? "-" : abbonamento.totaleNetto + ' €'}

                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label={this.props.isConsulente ? "Importo netto" : "Importo incassato"}
                                                disabled
                                                value={abbonamento.pagamento.importoNetto === null ? "-" : abbonamento.pagamento.importoNetto + ' €'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="Nome acquirente"
                                                disabled
                                                value={abbonamento.pagamento.nomePayer === null ? "-" : abbonamento.pagamento.nomePayer}

                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />

                                            <TextField
                                                label="Cognome acquirente"
                                                disabled
                                                value={abbonamento.pagamento.cognomePayer === null ? "-" : abbonamento.pagamento.cognomePayer}

                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                            <TextField
                                                label="Email acquirente"
                                                disabled
                                                value={abbonamento.pagamento.emailPayer === null ? "-" : abbonamento.pagamento.emailPayer}

                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                style={styles.textfield}
                                            />
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </Grid>
                            <ActionButton
                                label="CHIUDI"
                                onClick={this.props.handleClose}
                                disabled={false}
                                grayVersion={false}
                            />
                        </Grid>
                    </div>
                </div>
            </Modal>
        )
    }
}
// ModalDettagliAbbonamenti.defaultProps = {
//     isConsulente: false
// };

ModalDettagliAbbonamento.propTypes = {
    handleClose: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired,
    Abbonamento: PropTypes.object.isRequired,
    isConsulente: PropTypes.bool.isRequired,
}
