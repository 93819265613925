import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import ActionButton from "../../../components/ActionButton";

import PropTypes from 'prop-types';

const styles = {
    root: {
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    texts: {
        marginBottom: '20px'
    },
    sectioncontainer: {
        // border: '1px solid #e0e0e0',
        // borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '320px'
    },
}

export default function FormModificaGruppo(props) {
    return (
        <Container maxWidth='md' style={styles.root}>
            <Grid container justify="center" alignItems="center" style={styles.sectioncontainer}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={styles.texts} >
                        Dati del gruppo
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="ID gruppo"
                                    disabled
                                    value={props.gruppo.id === null ? "" : props.gruppo.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Nome del brand"
                                    disabled
                                    value={props.gruppo.brand.nome}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="Nome gruppo"
                                    name="nome"
                                    value={props.gruppo.nome}
                                    onChange={props.onChange}
                                    disabled={props.disabled}
                                    style={styles.textfield}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <ActionButton 
                        label="Salva modifiche"
                        disabled={props.disabled || props.gruppo.nome.length === 0}
                        onClick={props.onSubmit}
                        grayVersion={false}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

FormModificaGruppo.propTypes = {
    gruppo: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}