import React from "react";
import {
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import {
  getAttrezzatureWithManualeIdAndTipologia,
  getAttrezzatureWithOnlyManualeId,
  getTipologiaStrumentazione,
} from "../../../utils/api/locali_e_attrezzature_api";
import { getProceduraHaccpTramiteId } from "../../../utils/api/procedure_api";
import {
  updateProceduraHaccp,
  uploadProceduraImage,
} from "../../../utils/api/componenti_paragrafi_api";
import ErrorDialog from "../../../components/ErrorDialog";
import { getCategorieControlli } from "../../../utils/api/categorie_controlli_api";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { Edit } from "@material-ui/icons";
import ModalSelezioneLimiti from "./ModalSelezioneLimiti";
import ModalSelezioneAssociazione from "./ModalSelezioneAssociazione";
import ModalSelezioneOrario from "./ModalSelezioneOrario";
import { getReparti } from "../../../utils/api/reparti";
import IndietroButtonColorFul from "../../../components/IndietroButtonColorful";
import ModalListaAttrezzatura from "./ModalListaAttrezzatura";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "100vh",
  },
  typo: {
    align: "center",
  },
  modalTitle: {
    fontSize: 20,
    alignSelf: "center",
  },
};

class DettaglioProceduraNuovoManuale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      records: [],
      loading: true,
      procedura: null,
      immaginiProcedure: [],
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: "",
      isOrarioIconDisabled: false,
      uploadingImage: false,
      isLimitiModalOpen: false,
      cadenzaPassata: "GIORNALIERA",
      isAssociazioneModalOpen: false,
      isAttrezzatureModalOpen: false,
      attrezzatureToRead: null,
      attrezzature: [],
      reparti: [],
      limitiMode: "CONTROLLO_TEMPERATURE",
      controlloSelezionato: null,
      orarioMode: "Other",
      categorieControlli: [],
      associazioneMode: "Reparti",
      tipologieElementi: [],
      uploadDisabled: false,
      totalPages: 0,
      isOrarioModalOpen: false,
      indexToUpdate: 0,
      readyToSend: true,
      edit: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let a = this.generateProceduraColumns();
    this.setState({
      columns: a,
    });
    this.getProceduraData();
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    //clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: "Errore",
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  openSuccessDialog = (successMessage) => {
    this.setState({
      errorDialogTitle: "Operazione effettuata",
      errorDialogVisible: true,
      errorDialogMessage: successMessage,
    });
  };

  handleChangedTitolo = (event) => {
    const value = event.target.value;
    let procedura = this.state.procedura;
    procedura.titolo = value;
    this.setState({
      procedura,
      notSaved: true,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  addImage = (id, index) => {
    let image = {
      id: id,
      titolo: null,
      note: null,
      posizione: index,
    };
    return image;
  };

  addControlloToTable = () => {
    let recordsToSave = [...this.state.records];
    let repartiIds = [];
    this.state.reparti.map((item) => {
      repartiIds.push(item.id);
    });
    let newControllo = {
      id: null,
      idParagrafo: this.props.idParagrafo,
      categorieControlli: this.state.categorieControlli,
      tipologieElementi: this.state.tipologieElementi,
      elementiOsservati: "",
      selectedCadenza: "ALTRO",
      selectedTipologiaElemento: 0,
      temperaturaMinima: null,
      temperaturaMassima: null,
      temperaturaConsigliata: null,
      salinitaMinima: null,
      salinitaMassima: null,
      salinitaStandard: null,
      tipoDiIntervento: false,
      selectedCategoriaControllo: "AGGIUNGI CATEGORIA",
      selectedElementoOsservato: null,
      isOrarioIconDisabled: false,
      nonConformita: [],
      limiti: "",
      attrezzature: [],
      reparti: repartiIds,
      Cadenze: ["GIORNALIERA", "SETTIMANALE", "MENSILE", "ANNUALE", "ALTRO"],
      datiCadenze: [],
      altraCadenza: null,
      Bloccato: false,
      Periodica: false,
    };
    recordsToSave.push(newControllo);
    this.setState(
      {
        records: recordsToSave,
      },
      () => {}
    );
    /*let proceduraToChange = this.state.procedura;
        console.log(proceduraToChange);
        let controllo = {
            id:null,
            proceduraHaccpId:this.props.id,
            bloccante:false,
            categoriaControllo:"CONTROLLO_TEMPERATURE",
            cadenza:"GIORNALIERA",
            altraCadenza:null,
            limiti:"temperature a norma",
            descrizioneOsservabile:null,
            categoriaOsservabile:"STRUMENTAZIONE",
            tipologiaStrumentazioneId:null,
            reparti:[],
            attrezzature:[],
            temperaturaMinima:0,
            temperaturaMassima:0,
            temperaturaConsigliata:0,
            periodico:false,
            cadenze:[],
            controlliNonConformita:[],
        }
        proceduraToChange.controlliConformita.push(controllo);
        console.log(proceduraToChange);*/
  };

  setCadenzeToControllo = (cadenze) => {
    let arrayOfControlli = this.state.records[this.state.indexToUpdate];
    arrayOfControlli.datiCadenze = cadenze;
    this.setState({
      isOrarioModalOpen: false,
    });
  };

  addAttrezzatureOrRepartiToControllo = (recordsToAdd, mode) => {
    if (mode === "Reparto") {
      let arrayOfRepartiToAssociate = [];
      recordsToAdd.map((item, index) => {
        if (item.checked) {
          arrayOfRepartiToAssociate.push(item.idReparto);
        }
      });
      let array = [...this.state.records];
      array[this.state.indexToUpdate].reparti = arrayOfRepartiToAssociate;
      array[this.state.indexToUpdate].attrezzature = [];
    } else if (mode === "Attrezzatura") {
      let arrayOfRepartiToAssociate = [];
      let arrayOfAttrezzatureToAssociate = [];
      recordsToAdd.map((item, index) => {
        if (item.checked) {
          arrayOfRepartiToAssociate.push(item.repartoId);
          arrayOfAttrezzatureToAssociate.push(item.id);
        }
      });
      getAttrezzatureWithManualeIdAndTipologia(
        this.props.idManuale,
        this.state.records[this.state.indexToUpdate].selectedTipologiaElemento,
        0,
        100,
        "indiceOrdinamento,ASC"
      )
        .then((response) => {
          let readyToAssociate = true;
          /*arrayOfAttrezzatureToAssociate.map((item) => {
                    response.map((item2) => {
                        console.log(item !== item2.id);
                        if(item !== item2.id){
                            readyToAssociate = false; 
                        }
                    })
                })*/
          if (readyToAssociate === true) {
            let array = [...this.state.records];
            array[this.state.indexToUpdate].reparti = arrayOfRepartiToAssociate;
            array[this.state.indexToUpdate].attrezzature =
              arrayOfAttrezzatureToAssociate;
            this.setState({
              readyToSend: true,
            });
          } else {
            this.openErrorDialog(
              "Attenzione, una o più attrezzature non sono associate alla tipologia desiderata"
            );
            this.setState({
              readyToSend: false,
            });
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.openErrorDialog(error.message);
          }
        });
    }
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };

  handleChangedDescrizione = (event) => {
    const value = event.target.value;
    let procedura = this.state.procedura;
    procedura.descrizione = value;
    this.setState({
      procedura,
      notSaved: true,
    });
  };

  uploadImage = (file, event) => {
    this.setState({
      uploadingImage: true,
    });
    uploadProceduraImage(file)
      .then((result) => {
        let id = result;
        this.confirmImageRegistration(id);
      })
      .catch((error) => {
        this.setState({
          loading: false,
          uploadingImage: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  confirmImageRegistration = (id) => {
    let procedura = this.state.procedura;
    let immaginiProcedure = this.state.immaginiProcedure;
    immaginiProcedure.push(this.addImage(id, immaginiProcedure.length));
    procedura.immagini = immaginiProcedure;
    let uploadDisabled = false;
    this.setState({
      immaginiProcedure,
      procedura,
      uploadDisabled,
      uploadingImage: false,
      changesNotSaved: true,
      disableSubmitButton: false,
      notSaved: true,
    });
  };

  deleteImage = (id, clickEvent) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    let newarray = immaginiProcedure.filter((item) => item.id !== id);
    let procedura = this.state.procedura;
    procedura.immagini = newarray;
    newarray.forEach((a, index) => (a.posizione = index));
    this.setState({
      immaginiProcedure: newarray,
      procedura,
      uploadDisabled: false,
      changesNotSaved: true,
      disableSubmitButton: false,
      notSaved: true,
    });
  };

  changeTitolo = (value, index) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    immaginiProcedure[index].titolo = value;

    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };

  changeNote = (value, index) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    immaginiProcedure[index].note = value;

    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };

  moveComponente = (fromIndex, toIndex) => {
    let immaginiProcedure = this.state.immaginiProcedure;
    let element = immaginiProcedure[fromIndex];
    immaginiProcedure.splice(fromIndex, 1);
    immaginiProcedure.splice(toIndex, 0, element);
    immaginiProcedure.forEach((a, index) => (a.posizione = index));
    this.setState({
      immaginiProcedure,
      notSaved: true,
    });
  };

  deleteRow = (cellInfo) => {
    let recordsToSave = [...this.state.records];
    recordsToSave.splice(cellInfo._index, 1);
    this.setState({
      records: recordsToSave,
    });
  };

  populateTable = (response) => {
    let controlliToAppend = response.controlliConformita;
    let arrayOfData = [];
    let isOrarioIconDisabled = false;
    controlliToAppend.map((item) => {
      let selectedTipologiaElemento = item.tipologiaStrumentazioneId;
      let selectedCadenza = item.cadenza;
      if (item.tipologiaStrumentazioneId === null) {
        selectedTipologiaElemento = 0;
      }
      if (item.cadenza === null && item.altraCadenza === null) {
        selectedCadenza = 0;
      } else if (item.cadenza === null) {
        selectedCadenza = "ALTRO";
        isOrarioIconDisabled = false;
      }
      let oldControllo = {
        id: item.id,
        idParagrafo: this.props.idParagrafo,
        categorieControlli: this.state.categorieControlli,
        tipologieElementi: this.state.tipologieElementi,
        elementiOsservati: item.tipoDiIntervento
          ? "Schema A-B-C"
          : item.descrizioneOsservabile,
        selectedCadenza: selectedCadenza,
        selectedTipologiaElemento: selectedTipologiaElemento,
        temperaturaMinima:
          item.temperaturaMinima === null
            ? null
            : parseFloat(item.temperaturaMinima),
        temperaturaMassima:
          item.temperaturaMassima === null
            ? null
            : parseFloat(item.temperaturaMassima),
        temperaturaConsigliata:
          item.temperaturaConsigliata === null
            ? null
            : parseFloat(item.temperaturaConsigliata),
        salinitaMinima:
          item.salinitaMinima === null ? null : parseInt(item.salinitaMinima),
        salinitaMassima:
          item.salinitaMassima === null ? null : parseInt(item.salinitaMassima),
        salinitaStandard:
          item.salinitaStandard === null
            ? null
            : parseInt(item.salinitaStandard),
        selectedCategoriaControllo: item.categoriaControllo,
        selectedElementoOsservato: item.categoriaOsservabile,
        nonConformita: item.controlliNonConformita,
        limiti: item.limiti,
        tipoDiIntervento:
          item.tipoDiIntervento === null
            ? false
            : /*item.descrizioneOsservabile === "Schema A-B-C" ? true : */ item.tipoDiIntervento,
        isOrarioIconDisabled: isOrarioIconDisabled,
        attrezzature: item.attrezzature,
        reparti: item.reparti,
        Cadenze: ["GIORNALIERA", "SETTIMANALE", "MENSILE", "ANNUALE", "ALTRO"],
        datiCadenze: item.cadenze,
        altraCadenza: item.altraCadenza,
        Bloccato: item.bloccante,
        Periodica: item.periodico,
      };
      arrayOfData.push(oldControllo);
    });
    this.setState(
      {
        records: arrayOfData,
      },
      () => {}
    );
  };

  getAttrezzatureName = (attrezzature) => {
    let arrayOfNames = [];
    for (let i = 0; i < attrezzature.length; i++) {
      this.state.attrezzature.map((item) => {
        if (item.id === attrezzature[i]) {
          arrayOfNames.push(item.nome);
        }
      });
    }
    return arrayOfNames;
  };

  getProceduraData = () => {
    getProceduraHaccpTramiteId(this.props.id)
      .then((response) => {
        this.setState(
          {
            procedura: response,
            immaginiProcedure: response.immagini,
            loading: false,
          },
          () => {
            /*let records = [];
                let controlliToAppend = response.controlliConformita;
                console.log(controlliToAppend);
                let arrayOfData = [];
                controlliToAppend.map((item) => {
                    let oldControllo = {
                        id:response.id,
                        idParagrafo:this.props.idParagrafo,
                        categorieControlli:this.state.categorieControlli,
                        tipologieElementi:this.state.tipologieElementi,
                        elementiOsservati:response.descrizioneOsservabile,
                        selectedCadenza:response.cadenza,
                        selectedTipologiaElemento:response.tipologiaStrumentazioneId,
                        temperaturaMinima:response.temperaturaMinima,
                        temperaturaMassima:response.temperaturaMassima,
                        temperaturaConsigliata:response.temperaturaConsigliata,
                        selectedCategoriaControllo:response.CategoriaControllo,
                        selectedElementoOsservato:response.categoriaOsservabile,
                        nonConformita:response.controlliNonConformita,
                        limiti:response.limiti,
                        attrezzature:response.attrezzature,
                        reparti:response.reparti,
                        Cadenze:["GIORNALIERA","SETTIMANALE","MENSILE","ANNUALE","ALTRO"],
                        datiCadenze:response.cadenze,
                        altraCadenza:response.altraCadenza,
                        Bloccato:response.bloccante,
                        Periodica:response.periodico,
                    } 
                    arrayOfData.push(oldControllo);
                })
                this.setState({
                    records:arrayOfData
                })*/
          }
        );
      })
      .then((response) => {
        getCategorieControlli()
          .then((response) => {
            /*let categorieControlli = [];
                response.map((item) => {
                    if(item.name === "PULIZIA_SANIFICAZIONI" || item.name === "CONTROLLO_TEMPERATURE" || item.name === "CONTROLLO_SALINITA" || item.name === "VERIFICA_BUON_FUNZIONAMENTO" || item.name === "INTERVENTI_STRAORDINARI"){
                        categorieControlli.push(item);
                    }
                })*/
            let categorieControlli = response;
            this.setState({
              categorieControlli: categorieControlli,
            });
          })
          .then((response) => {
            getTipologiaStrumentazione(this.props.idManuale, 0, 100, "nome,ASC")
              .then((response) => {
                let tipologieElementi = response;
                this.setState({
                  tipologieElementi: tipologieElementi,
                });
              })
              .then((response) => {
                getAttrezzatureWithOnlyManualeId(
                  this.props.idManuale,
                  0,
                  100,
                  "indiceOrdinamento,ASC"
                )
                  .then((response) => {
                    this.setState({
                      attrezzature: response,
                    });
                  })
                  .then((response) => {
                    getReparti(this.props.idManuale, 0, 100, "nome,asc").then(
                      (response) => {
                        this.setState(
                          {
                            reparti: response,
                          },
                          () => {
                            this.populateTable(this.state.procedura);
                          }
                        );
                      }
                    );
                  });
              });
          });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  handleControlloSave = () => {
    if (this.state.readyToSend) {
      let send = true;
      let errorMessage = "";
      let arrayToUse = [...this.state.records];
      let arrayOfControlliConformita = [];
      let isThereASanificazione = arrayToUse.find(
        (item) => item.selectedCategoriaControllo === "PULIZIA_SANIFICAZIONI"
      );
      let ruleOfSanificazioni = arrayToUse.indexOf(isThereASanificazione);
      let valueOfSanificazioneModel = undefined;
      if (isThereASanificazione !== undefined) {
        valueOfSanificazioneModel = isThereASanificazione.tipoDiIntervento;
      }
      arrayToUse.map((item, index) => {
        let cadenzaToInsert = item.selectedCadenza;
        let altraCadenza = null;
        if (!item.Periodica) {
          if (
            item.selectedCadenza !== "GIORNALIERA" &&
            item.selectedCadenza !== "SETTIMANALE" &&
            item.selectedCadenza !== "MENSILE" &&
            item.selectedCadenza !== "ANNUALE"
          ) {
            cadenzaToInsert = null;
            if (item.altraCadenza !== null) altraCadenza = item.altraCadenza;
            else {
              altraCadenza = "inserire cadenza";
            }
            item.datiCadenze = [];
          } else {
            if (item.datiCadenze.length === 0) {
              send = false;
              errorMessage =
                "E' necessario specificare almeno una cadenza per un controllo con cadenza non nulla";
            }
          }
        } else {
          if (
            item.selectedCadenza !== "GIORNALIERA" &&
            item.selectedCadenza !== "SETTIMANALE" &&
            item.selectedCadenza !== "MENSILE" &&
            item.selectedCadenza !== "ANNUALE"
          ) {
            cadenzaToInsert = null;
            if (item.altraCadenza !== null) altraCadenza = item.altraCadenza;
            else {
              altraCadenza = null;
            }
          } else {
            if (item.datiCadenze.length === 0) {
              let record = {};
              if (item.selectedCadenza === "GIORNALIERA") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = null;
                record.giornoMese = null;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "SETTIMANALE") {
                record.orario = "08:00:00";
                record.giornoSettimana = "MONDAY";
                record.mese = null;
                record.giornoMese = null;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "MENSILE") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = null;
                record.giornoMese = 1;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              } else if (item.selectedCadenza === "ANNUALE") {
                record.orario = "08:00:00";
                record.giornoSettimana = null;
                record.mese = "JANUARY";
                record.giornoMese = 1;
                record.schedaId = null;
                record.controlloConformitaId = item.id;
                record.id = null;
              }
              item.datiCadenze.push(record);
            }
          }
        }
        let tipologiaId = item.selectedTipologiaElemento;
        let categoria = "STRUMENTAZIONE";
        let repartiToPass = item.reparti;
        let descrizione = item.elementiOsservati;
        if (tipologiaId === 0 || tipologiaId === null) {
          tipologiaId = null;
          categoria = "ALTRO";
        }
        if (categoria === "STRUMENTAZIONE") {
          descrizione = null;
          repartiToPass = [];
          //   if (item.attrezzature.length === 0) {
          //     errorMessage =
          //       "E' necessario associare almeno un'attrezzatura per un controllo con categoriaOsservabile di tipo STRUMENTAZIONE.";
          //     send = false;
          //   }
        } else {
          let arrayToUseToFill = [...this.state.reparti];
          arrayToUseToFill.map((item) => {
            if (item.repartoDefault) {
              repartiToPass.push(item.id);
            }
          });
          if (item.attrezzature.length > 0) {
            item.attrezzature = [];
          }
          if (repartiToPass.length === 0) {
            send = false;
            errorMessage =
              "E' necessario associare almeno un reparto per un controllo con categoriaOsservabile diverso da STRUMENTAZIONE";
          }
        }
        let temperaturaMinima = null;
        let temperaturaMassima = null;
        let temperaturaConsigliata = null;
        let salinitaMinima = null;
        let salinitaMassima = null;
        let salinitaStandard = null;
        let categoriaControllo = item.selectedCategoriaControllo;
        if (categoriaControllo === "AGGIUNGI CATEGORIA") {
          send = false;
          errorMessage =
            "Attenzione, occorre scegliere una categoria di controllo prima di procedere!";
        }
        /*if(categoriaControllo !== "CONTROLLO_TEMPERATURE" || categoriaControllo !== "CONTROLLO_SALINITA" || 
            categoriaControllo !== "VERIFICA_BUON_FUNZIONAMENTO" || categoriaControllo !== "INTERVENTI_STRAORDINARI" || categoriaControllo !== "PULIZIA_SANIFICAZIONI"
            ){
                send = false;
                errorMessage = "Attenzione, categoria controllo non valida!";
            }*/
        if (categoriaControllo === "CONTROLLO_TEMPERATURE") {
          if (
            item.temperaturaMinima !== null &&
            item.temperaturaMinima !== ""
          ) {
            temperaturaMinima = parseFloat(item.temperaturaMinima);
          }
          if (
            item.temperaturaMassima !== null &&
            item.temperaturaMassima !== ""
          ) {
            temperaturaMassima = parseFloat(item.temperaturaMassima);
          }
          if (
            item.temperaturaConsigliata !== null &&
            item.temperaturaConsigliata !== ""
          ) {
            temperaturaConsigliata = parseFloat(item.temperaturaConsigliata);
          }
          if (temperaturaMinima > temperaturaMassima) {
            send = false;
            errorMessage =
              "Attenzione, la temperatura minima è maggiore della temperatura massima";
          }
          if (temperaturaMassima < temperaturaMinima) {
            send = false;
            errorMessage =
              "Attenzione, la temperatura massima è minore della temperatura minima";
          }
          if (
            temperaturaConsigliata !== null &&
            (temperaturaConsigliata < temperaturaMinima ||
              temperaturaConsigliata > temperaturaMassima)
          ) {
            send = false;
            errorMessage =
              "Attenzione, valore della temperatura standard fuori dall'intervallo impostato!";
          }
          if (temperaturaMinima === null || temperaturaMassima === null) {
            send = false;
            errorMessage =
              "Attenzione, occorre inserire i valori minimi e massimi di temperatura prima di procedere!";
          }
        }
        if (categoriaControllo === "CONTROLLO_SALINITA") {
          if (item.salinitaMinima !== null && item.salinitaMinima !== "") {
            salinitaMinima = parseInt(item.salinitaMinima);
          }
          if (item.salinitaMassima !== null && item.salinitaMassima !== "") {
            salinitaMassima = parseInt(item.salinitaMassima);
          }
          if (item.salinitaStandard !== null && item.salinitaStandard !== "") {
            salinitaStandard = parseInt(item.salinitaStandard);
          }
          if (salinitaMinima > salinitaMassima) {
            send = false;
            errorMessage =
              "Attenzione, la salinità minima è maggiore della salinità massima";
          }
          if (salinitaMassima < salinitaMinima) {
            send = false;
            errorMessage =
              "Attenzione, la salinità massima è minore della salinità minima";
          }
          if (
            salinitaStandard !== null &&
            (salinitaStandard < salinitaMinima ||
              salinitaStandard > salinitaMassima)
          ) {
            send = false;
            errorMessage =
              "Attenzione, valore della salinità standard fuori dall'intervallo impostato!";
          }
          if (salinitaMinima === null || salinitaMassima === null) {
            send = false;
            errorMessage =
              "Attenzione, occorre inserire i valori minimi e massimi di salinità prima di procedere!";
          }
        }
        if (categoriaControllo === "VERIFICA_BUON_FUNZIONAMENTO") {
          if (
            item.salinitaMinima !== null ||
            item.salinitaMassima !== null ||
            item.salinitaStandard !== null
          ) {
            //console.log("Inside")
            if (item.salinitaMinima !== null && item.salinitaMinima !== "") {
              salinitaMinima = parseInt(item.salinitaMinima);
            }
            if (item.salinitaMassima !== null && item.salinitaMassima !== "") {
              salinitaMassima = parseInt(item.salinitaMassima);
            }
            if (
              item.salinitaStandard !== null &&
              item.salinitaStandard !== ""
            ) {
              salinitaStandard = parseInt(item.salinitaStandard);
            }
            if (salinitaMinima > salinitaMassima) {
              send = false;
              errorMessage =
                "Attenzione, la salinità minima è maggiore della salinità massima";
            }
            if (salinitaMassima < salinitaMinima) {
              send = false;
              errorMessage =
                "Attenzione, la salinità massima è minore della salinità minima";
            }
            if (
              salinitaStandard !== null &&
              (salinitaStandard < salinitaMinima ||
                salinitaStandard > salinitaMassima)
            ) {
              send = false;
              errorMessage =
                "Attenzione, valore della salinità standard fuori dall'intervallo impostato!";
            }
            if (salinitaMinima === null || salinitaMassima === null) {
              send = false;
              errorMessage =
                "Attenzione, occorre inserire i valori minimi e massimi di salinità associati al controllo di verifica buon funzionamento prima di procedere!";
            }
          } else {
            if (
              item.temperaturaMinima !== null &&
              item.temperaturaMinima !== ""
            ) {
              temperaturaMinima = parseFloat(item.temperaturaMinima);
            }
            if (
              item.temperaturaMassima !== null &&
              item.temperaturaMassima !== ""
            ) {
              temperaturaMassima = parseFloat(item.temperaturaMassima);
            }
            if (
              item.temperaturaConsigliata !== null &&
              item.temperaturaConsigliata !== ""
            ) {
              temperaturaConsigliata = parseFloat(item.temperaturaConsigliata);
            }
            if (temperaturaMinima > temperaturaMassima) {
              send = false;
              errorMessage =
                "Attenzione, la temperatura minima è maggiore della temperatura massima";
            }
            if (temperaturaMassima < temperaturaMinima) {
              send = false;
              errorMessage =
                "Attenzione, la temperatura massima è minore della temperatura minima";
            }
            if (
              temperaturaConsigliata !== null &&
              (temperaturaConsigliata < temperaturaMinima ||
                temperaturaConsigliata > temperaturaMassima)
            ) {
              send = false;
              errorMessage =
                "Attenzione, valore della temperatura standard fuori dall'intervallo impostato!";
            }
            if (temperaturaMinima === null || temperaturaMassima === null) {
              send = false;
              errorMessage =
                "Attenzione, occorre inserire i valori minimi e massimi di temperatura associati al controllo di verifica buon funzionamento prima di procedere!";
            }
          }
        }
        let tipoDiIntervento = item.tipoDiIntervento;
        if (categoriaControllo !== "PULIZIA_SANIFICAZIONI") {
          tipoDiIntervento = false;
        }
        if (
          isThereASanificazione !== undefined &&
          index > ruleOfSanificazioni &&
          categoriaControllo === "PULIZIA_SANIFICAZIONI"
        ) {
          if (item.tipoDiIntervento !== valueOfSanificazioneModel) {
            send = false;
            errorMessage =
              "Attenzione, c'è una discrepanza tra un tipo di intervento e l'altro";
          }
        }
        let controlloToPush = {
          id: item.id,
          proceduraHaccpId: this.state.procedura.id,
          bloccante: item.Bloccato,
          categoriaControllo: categoriaControllo,
          cadenza: cadenzaToInsert,
          altraCadenza: altraCadenza,
          limiti: item.limiti,
          descrizioneOsservabile: descrizione,
          tipologiaStrumentazioneId: tipologiaId,
          reparti: repartiToPass,
          attrezzature: item.attrezzature,
          categoriaOsservabile: categoria,
          periodico: item.Periodica,
          cadenze: item.datiCadenze,
          controlliNonConformita: item.nonConformita,
          temperaturaMinima: temperaturaMinima,
          temperaturaMassima: temperaturaMassima,
          temperaturaConsigliata: temperaturaConsigliata,
          salinitaMinima: salinitaMinima,
          salinitaMassima: salinitaMassima,
          salinitaStandard: salinitaStandard,
          tipoDiIntervento: tipoDiIntervento,
        };
        arrayOfControlliConformita.push(controlloToPush);
      });
      let descrizioneProcedura = this.state.procedura.descrizione;
      if (descrizioneProcedura === null) {
        descrizioneProcedura = "";
      }
      let objToSave = {
        id: this.state.procedura.id,
        paragrafoId: parseInt(this.props.idParagrafo),
        parentComponentId: null,
        numeroComponente: this.state.procedura.numeroComponente,
        stato: this.state.procedura.stato,
        titolo: this.state.procedura.titolo,
        descrizione: descrizioneProcedura,
        punticontrollo: [],
        controlliConformita: arrayOfControlliConformita,
        immagini: this.state.immaginiProcedure,
      };
      if (send) {
        updateProceduraHaccp(objToSave)
          .then((response) => {
            this.getProceduraData();
            this.setState({
              edit: false,
            });
            this.openSuccessDialog("Elemento salvato con successo");
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.openErrorDialog(error.message);
            }
          });
      } else {
        this.openErrorDialog(errorMessage);
      }
    } else {
      this.openErrorDialog(
        "Attenzione, uno dei dati inseriti è ancora inconsistente"
      );
    }
  };

  onAddNonConformita = () => {};

  passLimiti = (limiti, tempMin, tempMax, tempCons) => {
    let arrayOfControlli = [...this.state.records];
    /*let controlliCause = [];
        limiti.map((item) => {
            console.log("This is the original item: ");
            console.log(limiti);
            let azioniCorrettiveArray = [];
            
            let objToAddToCause = {
                id:null,
                controlloNonConformitaId:null,
                limite:"",
                azioniCorrettive:[]
            }
        })
        let nonConformitaToPush = {
            id:null,
            controlloConformitaId:null,
        }*/
    arrayOfControlli[this.state.indexToUpdate].nonConformita = limiti;
    if (tempMin === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura minima non è un numero"
      );
    } else if (tempMax === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura massima non è un numero"
      );
    } else if (tempCons === "") {
      this.openErrorDialog(
        "Attenzione, il valore della temperatura standard non è un numero"
      );
    } else {
      arrayOfControlli[this.state.indexToUpdate].temperaturaMinima = tempMin;
      arrayOfControlli[this.state.indexToUpdate].temperaturaMassima = tempMax;
      arrayOfControlli[this.state.indexToUpdate].temperaturaConsigliata =
        tempCons;
      this.setState({
        isLimitiModalOpen: false,
      });
    }
  };

  passLimitiBuonFunzionamento = (
    limiti,
    tempMin,
    tempMax,
    tempCons,
    salMin,
    salMax,
    salCons,
    isSal
  ) => {
    var arrayOfControlli = [...this.state.records];
    /*let controlliCause = [];
        limiti.map((item) => {
            console.log("This is the original item: ");
            console.log(limiti);
            let azioniCorrettiveArray = [];
            
            let objToAddToCause = {
                id:null,
                controlloNonConformitaId:null,
                limite:"",
                azioniCorrettive:[]
            }
        })
        let nonConformitaToPush = {
            id:null,
            controlloConformitaId:null,
        }*/
    arrayOfControlli[this.state.indexToUpdate].nonConformita = limiti;
    if (!isSal) {
      if (tempMin === "") {
        this.openErrorDialog(
          "Attenzione, il valore della temperatura minima non è un numero"
        );
      } else if (tempMax === "") {
        this.openErrorDialog(
          "Attenzione, il valore della temperatura massima non è un numero"
        );
      } else if (tempCons === "") {
        this.openErrorDialog(
          "Attenzione, il valore della temperatura standard non è un numero"
        );
      } else {
        arrayOfControlli[this.state.indexToUpdate].temperaturaMinima = tempMin;
        arrayOfControlli[this.state.indexToUpdate].temperaturaMassima = tempMax;
        arrayOfControlli[this.state.indexToUpdate].temperaturaConsigliata =
          tempCons;
        arrayOfControlli[this.state.indexToUpdate].salinitaMinima = null;
        arrayOfControlli[this.state.indexToUpdate].salinitaMassima = null;
        arrayOfControlli[this.state.indexToUpdate].salinitaStandard = null;
        this.setState({
          isLimitiModalOpen: false,
        });
      }
    } else {
      if (salMin === "") {
        this.openErrorDialog(
          "Attenzione, il valore della salinità minima non è un numero"
        );
      } else if (salMax === "") {
        this.openErrorDialog(
          "Attenzione, il valore della salinità massima non è un numero"
        );
      } else if (salCons === "") {
        this.openErrorDialog(
          "Attenzione, il valore della salinità standard non è un numero"
        );
      } else {
        arrayOfControlli[this.state.indexToUpdate].salinitaMinima = salMin;
        arrayOfControlli[this.state.indexToUpdate].salinitaMassima = salMax;
        arrayOfControlli[this.state.indexToUpdate].salinitaStandard = salCons;
        arrayOfControlli[this.state.indexToUpdate].temperaturaMinima = null;
        arrayOfControlli[this.state.indexToUpdate].temperaturaMassima = null;
        arrayOfControlli[this.state.indexToUpdate].temperaturaConsigliata =
          null;
        this.setState({
          isLimitiModalOpen: false,
        });
      }
    }
  };

  passLimitiSalinita = (limiti, salMin, salMax, salCons) => {
    var arrayOfControlli = [...this.state.records];
    /*let controlliCause = [];
        limiti.map((item) => {
            console.log("This is the original item: ");
            console.log(limiti);
            let azioniCorrettiveArray = [];
            
            let objToAddToCause = {
                id:null,
                controlloNonConformitaId:null,
                limite:"",
                azioniCorrettive:[]
            }
        })
        let nonConformitaToPush = {
            id:null,
            controlloConformitaId:null,
        }*/
    arrayOfControlli[this.state.indexToUpdate].nonConformita = limiti;
    if (salMin === "") {
      this.openErrorDialog(
        "Attenzione, il valore della salinita minima non è un numero"
      );
    } else if (salMax === "") {
      this.openErrorDialog(
        "Attenzione, il valore della salinita massima non è un numero"
      );
    } else if (salCons === "") {
      this.openErrorDialog(
        "Attenzione, il valore della salinita standard non è un numero"
      );
    } else {
      arrayOfControlli[this.state.indexToUpdate].salinitaMinima = salMin;
      arrayOfControlli[this.state.indexToUpdate].salinitaMassima = salMax;
      arrayOfControlli[this.state.indexToUpdate].salinitaStandard = salCons;
      this.setState({
        isLimitiModalOpen: false,
      });
    }
  };

  generateProceduraColumns = () => {
    return [
      {
        Header: "Categoria Controllo",
        id: "CategoriaControllo",
        width: 260,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            defaultValue={"AGGIUNGI CATEGORIA"}
            value={props.row.CategoriaControllo.selectedCategoriaControllo}
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].selectedCategoriaControllo =
                event.target.value;
              if (
                event.target.value === "CONTROLLO_TEMPERATURE" ||
                event.target.value === "VERIFICA_BUON_FUNZIONAMENTO"
              ) {
                arrayOfRecords[props.row._index].temperaturaMinima = null;
                arrayOfRecords[props.row._index].temperaturaMassima = null;
                arrayOfRecords[props.row._index].temperaturaConsigliata = null;
              }
              if (
                event.target.value !== "PULIZIA_SANIFICAZIONI" &&
                arrayOfRecords[props.row._index].tipoDiIntervento
              ) {
                arrayOfRecords[props.row._index].elementiOsservati = "";
              }
              if (event.target.value !== "PULIZIA_SANIFICAZIONI") {
                arrayOfRecords[props.row._index].tipoDiIntervento = false;
              }
              this.setState(
                {
                  records: arrayOfRecords,
                  edit: true,
                },
                () => {}
              );
            }}
            style={{ marginTop: 10, width: "100%" }}
          >
            <MenuItem key={0} value={"AGGIUNGI CATEGORIA"}>
              Seleziona una categoria
            </MenuItem>
            {props.row.CategoriaControllo.categorieControlli.map(
              (item, index) => {
                return (
                  <MenuItem key={index + 1} value={item.name}>
                    {item.label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        ),
      },
      {
        Header: "Tipologia elemento",
        id: "TipologiaElemento",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            defaultValue={0}
            value={props.row.TipologiaElemento.selectedTipologiaElemento}
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].attrezzature = [];
              arrayOfRecords[props.row._index].selectedTipologiaElemento =
                event.target.value;
              if (event.target.value === 0) {
                if (props.row.TipoIntervento) {
                  arrayOfRecords[props.row._index].elementiOsservati =
                    "Schema A-B-C";
                } else {
                  arrayOfRecords[props.row._index].descrizioneOsservabile =
                    null;
                  arrayOfRecords[props.row._index].elementiOsservati = "";
                }
              } else if (event.target.value !== 0 && props.row.TipoIntervento) {
                arrayOfRecords[props.row._index].elementiOsservati =
                  "Schema A-B-C";
              } else {
                this.state.attrezzature.map((item, index) => {
                  arrayOfRecords[props.row._index].elementiOsservati =
                    item.nome + " ";
                });
              }
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            style={{ marginTop: 10, width: "100%" }}
          >
            <MenuItem value={0}>Altra</MenuItem>
            {props.row.TipologiaElemento.tipologieElementi.map(
              (item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.nome}
                  </MenuItem>
                );
              }
            )}
          </Select>
        ),
      },
      {
        Header: "Tipo di intervento",
        id: "TipoIntervento",
        width: 150,
        accessor: (d) => d.tipoDiIntervento,
        Cell: (props) =>
          props.row.CategoriaControllo.selectedCategoriaControllo ===
          "PULIZIA_SANIFICAZIONI" ? (
            <Checkbox
              style={{
                marginTop: 10,
                color: theme.palette.cndColors.yellow,
                "&.MaterialUiChecked": {
                  color: theme.palette.cndColors.yellow,
                },
              }}
              value={props.row.TipoIntervento}
              checked={props.row.TipoIntervento}
              onChange={(event) => {
                let arrayOfRecords = [...this.state.records];
                arrayOfRecords[props.row._index].tipoDiIntervento =
                  event.target.checked;
                if (arrayOfRecords[props.row._index].tipoDiIntervento) {
                  arrayOfRecords[props.row._index].elementiOsservati =
                    "Schema A-B-C";
                } else {
                  arrayOfRecords[props.row._index].elementiOsservati = "";
                }
                this.setState({
                  records: arrayOfRecords,
                  edit: true,
                });
              }}
            />
          ) : null,
      },
      {
        Header: "Elemento Osservato",
        id: "ElementoOsservato",
        width: 250,
        accessor: (d) => d.elementiOsservati,
        Cell: (props) =>
          props.row.TipologiaElemento.selectedTipologiaElemento === 0 ||
          props.row.TipoIntervento ? (
            <TextField
              inputProps={{ min: 0, style: { textAlign: "center" } }}
              value={props.row.ElementoOsservato}
              disabled={props.row.TipoIntervento}
              onInput={(event) => {
                let arrayToChange = [...this.state.records];
                arrayToChange[props.row._index].elementiOsservati =
                  event.target.value;
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });
                this.setState({
                  records: arrayToChange,
                  edit: true,
                });
              }}
              fullWidth
              style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}
            />
          ) : (
            <ActionButton
              label={"Mostra Attrezzature"}
              disabled={false}
              grayVersion={false}
              style={{ marginLeft: 10 }}
              onClick={() =>
                this.openAttrezzaturaModal(
                  props.row.TipologiaElemento.attrezzature
                )
              }
            />
          ),
        /*<Typography style={{marginTop:10}}>{this.getAttrezzatureName(props.row.TipologiaElemento.attrezzature) this.state.attrezzature.map((item,index) => {
                if((item.tipologiaStrumentazioneId === props.row.TipologiaElemento.selectedTipologiaElemento) && index < 3){
                    if(index !== 2){
                    return item.nome+" ";
                    }
                    else{
                        return item.nome+"..."
                    }
                }
               })}</Typography>*/
      },
      /*{
                Header:"Limiti",
                id: "Limiti",
                width:200,
                accessor:d => d.limiti,
                Cell: props => <TextField onInput={(event) => {
                     let arrayOfRecords = [...this.state.records];
                     arrayOfRecords[props.row._index].limiti = event.target.value;
                     const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
                    this.setState({
                        records:arrayOfRecords,
                        edit:true,
                    })
                }} 
                    inputProps={{min: 0, style: { textAlign: 'center' }}}  value={props.row.Limiti} fullWidth style={{marginTop:10,paddingLeft:20, paddingRight:20}}></TextField> 
            },*/
      {
        Header: "Cadenza",
        id: "Cadenza",
        width: 250,
        accessor: (d) => d,
        Cell: (props) => (
          <Select
            onChange={(event) => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].selectedCadenza =
                event.target.value;
              arrayOfRecords[props.row._index].datiCadenze = [];
              /*this.setState({
                        controlloSelezionato:props.row.Cadenza,
                    },() => {
                    let cadenze = this.state.controlloSelezionato.datiCadenze;
                    console.log("These are the cadenze");
                    console.log(cadenze);
                    })*/
              if (
                event.target.value !== "GIORNALIERA" &&
                event.target.value !== "SETTIMANALE" &&
                event.target.value !== "MENSILE" &&
                event.target.value !== "ANNUALE" &&
                event.target.value !== 0 &&
                event.target.value !== "ALTRO"
              ) {
                arrayOfRecords[props.row._index].isOrarioIconDisabled = true;
              } else {
                arrayOfRecords[props.row._index].isOrarioIconDisabled = false;
              }
              this.setState({
                edit: true,
                records: arrayOfRecords,
              });
            }}
            value={props.row.Cadenza.selectedCadenza}
            style={{ marginTop: 10, width: "100%" }}
          >
            {/*<MenuItem value={0}>Selezionare una cadenza</MenuItem>*/}
            {/*props.row.Cadenza.altraCadenza != null && <MenuItem value={props.row.Cadenza.altraCadenza}>{props.row.Cadenza.altraCadenza}</MenuItem>*/}
            {props.row.Cadenza.Cadenze.map((item, index) => {
              if (!props.row.Periodica.Periodica) {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              } else {
                if (
                  item === "GIORNALIERA" ||
                  item === "SETTIMANALE" ||
                  item === "MENSILE" ||
                  item === "ANNUALE"
                ) {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                }
              }
            })}
          </Select>
        ),
      },
      {
        Header: "Periodica",
        id: "Periodica",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <Checkbox
            onClick={() => {
              let arrayOfRecords = [...this.state.records];
              arrayOfRecords[props.row._index].Periodica =
                !arrayOfRecords[props.row._index].Periodica;
              this.setState({
                records: arrayOfRecords,
                edit: true,
              });
            }}
            disabled={
              props.row.Cadenza.selectedCadenza === 0 ||
              props.row.Cadenza.selectedCadenza === "ALTRO"
            }
            checked={props.row.Periodica.Periodica}
            style={{
              margin: 10,
              color:
                props.row.Cadenza.selectedCadenza === 0 ||
                props.row.Cadenza.selectedCadenza === "ALTRO"
                  ? theme.palette.disabled.main
                  : theme.palette.cndColors.yellow,
              "&.MaterialUiChecked": {
                color: theme.palette.cndColors.yellow,
              },
            }}
          />
        ),
      },
      /*{
                Header:"Bloccato",
                id: "Bloccato",
                width:100,
                accessor:d => d.Bloccato,
                Cell: props => <Checkbox onClick={() => {
                    let arrayOfRecords = [...this.state.records];
                    arrayOfRecords[props.row._index].Bloccato = !arrayOfRecords[props.row._index].Bloccato;
                    this.setState({
                        records:arrayOfRecords,
                        edit:true,
                    })
                }} checked={props.row.Bloccato} style={{marginTop:10}}></Checkbox>
            },*/
      {
        Header: "Lista Orari",
        id: "ListaOrari",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            disabled={
              props.row.ListaOrari.isOrarioIconDisabled ||
              props.row.Periodica.Periodica
            }
            onClick={() =>
              this.openOrarioModal(
                props.row.Cadenza.selectedCadenza,
                props.row.Cadenza,
                props.row._index
              )
            }
            size="small"
            style={{ margin: 15 }}
          >
            <Edit
              htmlColor={
                props.row.ListaOrari.isOrarioIconDisabled ||
                props.row.Periodica.Periodica
                  ? "#e0e0e0"
                  : "#f7a616"
              }
            />
          </IconButton>
        ),
      },
      {
        Header: "Limiti di conformità",
        id: "IsConformita",
        width: 150,
        accessor: (d) => d,
        Cell: (props) =>
          props.row.CategoriaControllo.selectedCategoriaControllo ===
            "VERIFICA_BUON_FUNZIONAMENTO" ||
          props.row.CategoriaControllo.selectedCategoriaControllo ===
            "CONTROLLO_TEMPERATURE" ||
          props.row.CategoriaControllo.selectedCategoriaControllo ===
            "CONTROLLO_SALINITA" ? (
            <IconButton
              onClick={() =>
                this.openLimitiModal(
                  props.row.IsConformita,
                  props.row._index,
                  props.row.CategoriaControllo.selectedCategoriaControllo
                )
              }
              size="small"
              style={{ margin: 15 }}
            >
              <Edit htmlColor="#f7a616" />
            </IconButton>
          ) : null,
      },
      {
        Header: "Associazioni",
        id: "Associazioni",
        width: 100,
        accessor: (d) => d,
        Cell: (props) => (
          <IconButton
            size="small"
            onClick={() =>
              this.openAssociazioneModal(
                props.row.TipologiaElemento.selectedTipologiaElemento,
                props.row._index,
                props.row.Associazioni
              )
            }
            style={{ margin: 15 }}
          >
            <Edit htmlColor="#f7a616" />
          </IconButton>
        ),
      },
      {
        Header: "Elimina",
        id: "Elimina",
        width: 120,
        accessor: (d) => d,
        Cell: (props) => (
          <ActionButton
            isRed
            disabled={false}
            grayVersion={false}
            label="Elimina"
            onClick={() => this.deleteRow(props.row)}
          />
        ),
      },
    ];
  };

  closeOrarioModal = () => {
    this.setState({
      isOrarioModalOpen: false,
    });
  };

  openOrarioModal = (cadenza, controllo, index) => {
    if (cadenza === 0 || cadenza === "ALTRO") {
      this.setState({
        orarioMode: "Other",
      });
    } else {
      this.setState({
        orarioMode: "Normal",
        cadenzaPassata: cadenza,
      });
    }
    this.setState({
      isOrarioModalOpen: true,
      controlloSelezionato: controllo,
      indexToUpdate: index,
    });
  };

  handleCadenza = (altraCadenza, index) => {
    let arrayOfRecords = [...this.state.records];
    if (altraCadenza !== "") {
      arrayOfRecords[index].altraCadenza = altraCadenza;
      this.setState({
        records: arrayOfRecords,
        isOrarioModalOpen: false,
      });
    } else {
      this.openErrorDialog(
        "Attenzione, bisogna inserire un valore di altra cadenza per salvarne il valore"
      );
    }
  };

  openLimitiModal = (controllo, index, mode) => {
    this.setState({
      limitiMode: mode,
      isLimitiModalOpen: true,
      controlloSelezionato: controllo,
      indexToUpdate: index,
    });
  };

  closeLimitiModal = () => {
    this.setState({
      isLimitiModalOpen: false,
    });
  };

  openAttrezzaturaModal = (attrezzature) => {
    let attrezzatureToPass = this.getAttrezzatureName(attrezzature);

    this.setState({
      isAttrezzatureModalOpen: true,
      attrezzatureToRead: attrezzatureToPass,
    });
  };

  closeAttrezzaturaModal = () => {
    this.setState({
      isAttrezzatureModalOpen: false,
      attrezzatureToRead: null,
    });
  };

  openAssociazioneModal = (tipologia, index, controlloSelezionato) => {
    if (tipologia === 0) {
      this.setState({
        associazioneMode: "Reparti",
      });
    } else {
      this.setState({
        associazioneMode: "Attrezzature",
      });
    }
    this.setState({
      isAssociazioneModalOpen: true,
      indexToUpdate: index,
      controlloSelezionato: controlloSelezionato,
    });
  };

  closeAssociazioneModal = () => {
    this.setState({
      isAssociazioneModalOpen: false,
    });
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        {/*this.state.loading ?
                                <SpinnerComponent size={32} />
                                : <Container>
            <OneLineTextInputComponent
                                            label={"Titolo:"}
                                            fieldname={"titolo"}
                                            fieldvalue={this.state.procedura.titolo}
                                            onChangeEvent={this.handleChangedTitolo}
                                        />
                                        <MultilineTextInputComponent
                                            label={"Descrizione procedura"}
                                            fieldname={"descrizione"}
                                            fieldvalue={this.state.procedura.descrizione}
                                            onChangeEvent={this.handleChangedDescrizione}
                                        />
                                        {/*
                                    TO BE REMOVED: for now this component is just hidden,
                                    it could be removed with next updates.

                                    <PuntiDiControlloTable
                                        puntiDiControllo={this.state.procedura.punticontrollo}
                                        onAggiungiButtonClicked={this.openPuntiControlloSelectionPage}
                                        onRimuoviButtonClicked={this.removePuntoDiControllo}
                                    /> */}
        {/*<UploadImages
                                            disabled={this.state.uploadDisabled}
                                            loading={this.state.uploadingImage}
                                            onUpload={this.uploadImage}
                                        />
                                        <ImmaginiProcedure
                                            loading={this.state.uploadingImage}
                                            immaginiProcedure={this.state.immaginiProcedure}
                                            onRimuoviImage={this.deleteImage}
                                            onMove={this.moveComponente}
                                            onChangeTitolo={this.changeTitolo}
                                            onChangeNote={this.changeNote}
                                            //submitButtonDisabled={this.state.disableSubmitButton}
                                        />
            </Container>
            */}
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/*(!this.state.currentTabValue.obbligatorio) ? (<Typography style={styles.typo} variant="h4">
              {this.state.currentTabValue.label}
            </Typography>) : (
              <Typography style={styles.typo} variant="h4">
              {this.state.modalTitle}
              </Typography>
            )*/}
            <Typography style={styles.typo} variant="h4">
              Controlli di Conformità
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButton
                onClick={this.addControlloToTable}
                label="Aggiungi"
                grayVersion={false}
                disabled={false}
              />
            </div>
            <ReactTable
              filterable={false}
              resizable={true}
              enableEditing={true}
              showPageSizeOptions={false}
              showPageJump={true}
              defaultPageSize={10}
              style={{
                width: "90vw",
                alignSelf: "center",
              }}
              data={this.state.records}
              columns={this.state.columns}
              /*manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={ (state, instance) => { 
                  let a = 0;
                  getTotaleLocali(this.props.idManuale).then(result => {
                    a = result;
                        console.log(a);
                        this.setState({
                          totalPages:Math.ceil(a/state.pageSize),
                    })
                  }).then(result => {
                    getLocaliManuale(this.props.idManuale,state.page,state.pageSize,"id").then(result => {
                      this.setState({
                          records:result,
                      })
                    })
                  }).catch(error => {
                    if (error.status === 403) {
                      this.props.handleInvalidToken();
                      } else {
                      this.props.handleError(true, error.message);
                      }
                  })
                }}*/
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
          </div>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
          title={this.state.errorDialogTitle}
        />
        {this.state.isOrarioModalOpen && (
          <ModalSelezioneOrario
            open={this.state.isOrarioModalOpen}
            onClose={this.closeOrarioModal}
            mode={this.state.orarioMode}
            addCadenza={this.handleCadenza}
            cadenza={this.state.cadenzaPassata}
            cadenze={this.state.controlloSelezionato.datiCadenze}
            indexToUpdate={this.state.indexToUpdate}
            setCadenzeToControllo={this.setCadenzeToControllo}
            controlloConformita={this.state.controlloSelezionato}
          />
        )}
        {this.state.isLimitiModalOpen && (
          <ModalSelezioneLimiti
            open={this.state.isLimitiModalOpen}
            onClose={this.closeLimitiModal}
            reparti={this.state.reparti}
            passLimiti={this.passLimiti}
            passLimitiSalinita={this.passLimitiSalinita}
            passLimitiBuonFunzionamento={this.passLimitiBuonFunzionamento}
            mode={this.state.limitiMode}
            controlloconformita={this.state.controlloSelezionato}
            onAddNonConformita={this.onAddNonConformita}
            manualeId={this.props.idManuale}
          />
        )}
        {this.state.isAssociazioneModalOpen && (
          <ModalSelezioneAssociazione
            open={this.state.isAssociazioneModalOpen}
            onClose={this.closeAssociazioneModal}
            mode={this.state.associazioneMode}
            reparti={this.state.reparti}
            attrezzature={this.state.attrezzature}
            controlloSelezionato={this.state.controlloSelezionato}
            saveValues={this.addAttrezzatureOrRepartiToControllo}
          />
        )}
        {this.state.isAttrezzatureModalOpen && (
          <ModalListaAttrezzatura
            open={this.state.isAttrezzatureModalOpen}
            onClose={this.closeAttrezzaturaModal}
            attrezzatureToRead={this.state.attrezzatureToRead}
          />
        )}
        {this.state.loading ? null : (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <IndietroButtonColorFul
              alert={this.state.edit}
              isDisabled={false}
            />
            <ActionButton
              disabled={false}
              grayVersion={false}
              onClick={this.handleControlloSave}
              label="Salva"
            />
          </div>
        )}
      </div>
    );
  }
}

DettaglioProceduraNuovoManuale.propTypes = {
  id: PropTypes.string.isRequired,
  idManuale: PropTypes.string.isRequired,
  idParagrafo: PropTypes.string.isRequired,
};

export default DettaglioProceduraNuovoManuale;
