import DateFnsUtils from "@date-io/date-fns";
import {
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { CancelRounded, Edit } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import React, { Component, Fragment } from "react";
import { default as PrintIcon } from "../../../Print.svg";
import { ReactComponent as CalendarLogo } from "../../../Calendario.svg";
import { ReactComponent as FrecciaSu } from "../../../frecciaSu.svg";
import { ReactComponent as FrecciaGiu } from "../../../frecciaGiu.svg";
import theme from "../../../theme";

class SottoparagrafoParti extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.item4) !== JSON.stringify(this.props.item4)) {
      return true;
    }
    if (JSON.stringify(nextProps.item3) !== JSON.stringify(this.props.item3)) {
      return true;
    }
    if (
      nextProps.activateMovement !== this.props.activateMovement &&
      this.props.item3.isTabOpen
    ) {
      return true;
    }
    return false;

    /**
     *  key={index4}
                                    item4={item4}
                                    titolo={item4.titolo}
                                    base={item4.base}
                                    daEditare={item4.daEditare}
                                    data={item4.data}
                                    id={item4.id}
                                    isSelectedForPDF={item4.isSelectedForPDF}
                                    ul={item4.ul}
                                    sv={item4.sv}
                                    area1={item4.area1}
                                    area2={item4.area2}
                                    isOrganigramma={item4.hasOrganigramma}
                                    tipologia={item4.tipologia}
                                    index={this.props.index}
                                    index2={this.props.index2}
                                    index3={this.props.index3}
                                    index4={index4}
                                    item3={this.props.item3}
                                    item2={this.props.item2}
                                    posizione={item4.posizione}
                                    numeroIndice={item4.numeroIndice}
     */
  }

  render() {
    return (
      <Fragment key={this.props.index4}>
        <TableRow>
          <TableCell style={{ border: 0 }} align="center">
            {!this.props.isModello ? (
              <Fragment>
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <img
                    style={{
                      width: 23,
                      height: 23,
                      alignSelf: "center",
                    }}
                    src={PrintIcon}
                    alt=""
                  />
                </div>
                <Checkbox
                  checked={
                    this.props.item4.isSelectedForPDF &&
                    this.props.item3.isSelectedForPDF
                  }
                  onClick={() => {
                    this.props.handleAggiuntaAlManualeSottoparagrafo(
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    );
                  }}
                  value={
                    this.props.item4.isSelectedForPDF &&
                    this.props.item3.isSelectedForPDF
                  }
                  disabled={
                    !this.props.item3.isSelectedForPDF ||
                    this.props.activateMovement
                  }
                  style={{
                    color:
                      !this.props.item3.isSelectedForPDF ||
                      this.props.activateMovement
                        ? theme.palette.disabled.main
                        : theme.palette.cndColors.yellow,
                    "&.MaterialUiChecked": {
                      color: theme.palette.cndColors.yellow,
                    },
                  }}
                />
              </Fragment>
            ) : null}
          </TableCell>
          <TableCell
            style={{ border: "2px solid lightgray", borderRight: 0 }}
            align="center"
            component="th"
            scope="row"
          >
            {
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioIndiceSottoparagrafo(
                    e.target.value,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.numeroIndice || ""}
              />
            }
          </TableCell>
          <TableCell
            style={{ border: "2px solid lightgray", borderRight: 0 }}
            align="center"
          >
            {
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioTitoloSottoparagrafo(
                    e.target.value,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  if (this.props.item4.titolo === "") {
                    alert(
                      "Attenzione, questo sottoparagrafo non verrà inserito nell'indice di stampa, in quanto privo di titolo"
                    );
                  }
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                fullWidth
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.titolo || ""}
              />
            }
          </TableCell>
          <TableCell
            style={
              /*!this.props.isModello ? {border:"2px solid lightgray",borderRight:0, backgroundColor:"#eeeeee"} : */ {
                border: "2px solid lightgray",
                borderRight: 0,
              }
            }
            align="center"
          >
            {
              /*!this.props.isModello ? this.props.item4.base :*/
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioBaseSottoparagrafo(
                    e,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.base || this.props.item3.base || ""}
              />
            }
          </TableCell>
          {!this.props.isModello ? (
            <TableCell
              style={{ border: "2px solid lightgray", borderRight: 0 }}
              align="center"
            >
              {
                <TextField
                  fullWidth
                  multiline
                  disabled={this.props.activateMovement}
                  onInput={(e) => {
                    this.props.handleCambioSVSottoparagrafo(
                      e,
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    );
                    /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                  }}
                  onBlur={() => {
                    this.props.saveSottoparagrafoData(this.props.item4);
                  }}
                  inputProps={{
                    style: { textAlign: "center", color: "black" },
                  }}
                  InputProps={{ disableUnderline: true }}
                  value={this.props.item4.sv || this.props.item3.sv || ""}
                />
              }
            </TableCell>
          ) : null}
          <TableCell
            style={{ border: "2px solid lightgray", borderRight: 0 }}
            align="center"
          >
            {
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioULSottoparagrafo(
                    e,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.ul || this.props.item3.ul || ""}
              />
            }
          </TableCell>
          <TableCell
            style={{ border: "2px solid lightgray", borderRight: 0 }}
            align="center"
          >
            {
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioArea1Sottoparagrafo(
                    e,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.area1 || this.props.item3.area1 || ""}
              />
            }
          </TableCell>
          <TableCell
            style={{ border: "2px solid lightgray", borderRight: 0 }}
            align="center"
          >
            {
              <TextField
                multiline
                disabled={this.props.activateMovement}
                onInput={(e) => {
                  this.props.handleCambioArea2Sottoparagrafo(
                    e,
                    this.props.index,
                    this.props.index2,
                    this.props.index3,
                    this.props.index4
                  );
                  /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                }}
                onBlur={() => {
                  this.props.saveSottoparagrafoData(this.props.item4);
                }}
                inputProps={{ style: { textAlign: "center", color: "black" } }}
                InputProps={{ disableUnderline: true }}
                value={this.props.item4.area2 || this.props.item3.area2 || ""}
              />
            }
          </TableCell>
          <TableCell
            style={
              this.props.item4.daEditare
                ? this.props.item4.timeStamp === null || this.props.isModello
                  ? {
                      border: "2px solid lightgray",
                      backgroundColor: "#fde99f",
                    }
                  : { border: "2px solid lightgray" }
                : { border: "2px solid lightgray" }
            }
            align="center"
          >
            {this.props.isModello &&
            !(
              localStorage.getItem("superconsulente") !== "true" &&
              localStorage.getItem("consulenteId") !== "null" &&
              localStorage.getItem("consulenteId") !== null
            ) ? (
              <Select
                defaultValue={false}
                disabled={this.props.activateMovement}
                variant="standard"
                value={
                  this.props.item4.daEditare !== undefined
                    ? this.props.item4.daEditare
                    : false
                }
              >
                <MenuItem
                  value={true}
                  onClick={() => {
                    this.props.handleCambioDaEditareSottoparagrafo(
                      true,
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    );
                  }}
                >
                  Si
                </MenuItem>
                <MenuItem
                  value={false}
                  onClick={() => {
                    this.props.handleCambioDaEditareSottoparagrafo(
                      false,
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    );
                  }}
                >
                  No
                </MenuItem>
              </Select>
            ) : this.props.item4.daEditare ? (
              "Si"
            ) : (
              "No"
            )}
          </TableCell>
          <TableCell style={{ border: "2px solid lightgray" }} align="center">
            {
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                  format="dd/MM/yyyy"
                  value={
                    this.props.item4.dataEditing !== null
                      ? this.props.item4.dataEditing
                      : this.props.item3.dataEditing
                  }
                  onChange={(date) =>
                    this.props.handleCambioDataSottoparagrafo(
                      date,
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    )
                  }
                  disabled={this.props.activateMovement}
                  TextFieldComponent={({ InputProps, ...params }) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...InputProps,
                        disableUnderline: true,
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <CalendarLogo
                                width={21}
                                height={21}
                                fill={
                                  this.props.activateMovement
                                    ? theme.palette.disabled.main
                                    : theme.palette.cndColors.yellow
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            }
          </TableCell>
          <TableCell style={{ border: "2px solid lightgray" }} align="center">
            <IconButton
              disabled={this.props.activateMovement}
              onClick={() => {
                this.props.handleParagrafoRedirect(
                  this.props.item3,
                  this.props.item4.id
                );
              }}
            >
              <Edit
                htmlColor={this.props.activateMovement ? "#e0e0e0" : "#f7a616"}
              />
            </IconButton>
          </TableCell>
          <TableCell style={{ border: 0 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.props.index4 !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 3,
                  }}
                >
                  <IconButton
                    disabled={!this.props.activateMovement}
                    size="small"
                    onClick={() => {
                      this.props.handleSottoparagrafoMovementUpward(
                        this.props.index,
                        this.props.index2,
                        this.props.index3,
                        this.props.index4
                      );
                    }}
                  >
                    <FrecciaSu
                      width={15}
                      height={15}
                      fill={this.props.activateMovement ? "#f7a616" : "#e0e0e0"}
                    />
                  </IconButton>
                  <IconButton
                    disabled={!this.props.activateMovement}
                    size="small"
                    onClick={() => {
                      this.props.handleSottoparagrafoMovementDownward(
                        this.props.index,
                        this.props.index2,
                        this.props.index3,
                        this.props.index4
                      );
                    }}
                  >
                    <FrecciaGiu
                      width={15}
                      height={15}
                      fill={this.props.activateMovement ? "#f7a616" : "#e0e0e0"}
                    />
                  </IconButton>
                </div>
              ) : null}
              {this.props.isOrganigramma ||
              this.props.tipologia === "TABELLA_REPARTI" ||
              this.props.tipologia === "TABELLA_LOCALI" ||
              this.props.tipologia === "TABELLA_PROCEDURE" ? null : (
                <IconButton
                  size="medium"
                  disabled={this.props.activateMovement}
                  onClick={() => {
                    this.props.handleSottoparagrafoDelete(
                      this.props.index,
                      this.props.index2,
                      this.props.index3,
                      this.props.index4
                    );
                  }}
                >
                  <CancelRounded
                    htmlColor={
                      this.props.activateMovement ? "#e0e0e0" : "#ff4600"
                    }
                  />
                </IconButton>
              )}
            </div>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}
export default SottoparagrafoParti;
