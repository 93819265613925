import React, { Component } from "react";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import PropTypes from "prop-types";

const Table = selectTableHOC(ReactTable);

class SelectTable extends Component {
  static propTypes = {
    selectedValue: PropTypes.object,
    onSelectionChange: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultPageSize: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedElement: this.props.selectedValue
        ? this.props.selectedValue.id
        : -1,
      selectionList: this.props.selectedValue ? [this.props.selectedValue] : [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedValue &&
      (!prevProps.selectedValue ||
        this.props.selectedValue.id !== prevProps.selectedValue.id)
    ) {
      this.setState({
        selectedElement: this.props.selectedValue.id,
        selectionList: [this.props.selectedValue],
      });
    }
  }

  toggleSelection = (key, _shift, row) => {
    const selectedElementId = parseInt(key.split("select-")[1], 10);

    if (this.state.selectedElement !== selectedElementId) {
      this.setState(
        { selectedElement: selectedElementId, selectionList: [row] },
        () => this.props.onSelectionChange(row)
      );
    }
  };

  isSelected = (key) => {
    return this.state.selectionList.some((e) => e.id === key);
  };

  render() {
    return (
      <Table
        selectType="radio"
        ref={(r) => {
          this.checkboxTable = r;
        }}
        toggleSelection={this.toggleSelection}
        isSelected={this.isSelected}
        keyField={"id"}
        data={this.props.data}
        columns={this.props.columns}
        defaultPageSize={this.props.defaultPageSize}
        previousText="Precedente"
        nextText="Successivo"
        noDataText="Nessun punto vendita Trovato"
        pageText="Pagina"
        ofText="di"
        rowsText="righe"
        pageJumpText="Vai a pagina"
        rowsSelectorText="righe per pagina"
        defaultFilterMethod={(filter, row, _column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
            : true;
        }}
        style={{ height: 450, width: "50vw", alignSelf: "center" }}
      />
    );
  }
}

export default SelectTable;
