
export const retrieveToken = () => {
    return localStorage.getItem('token');
}

export const saveToken = (token) => {
    localStorage.setItem('token', token);
}

export const clearToken = () => {
    localStorage.clear();   
}

export const setConsulenteId = (consulenteId) => {
    localStorage.setItem('consulenteId', consulenteId);
}

export const getConsulenteId = () => {
    return localStorage.getItem('consulenteId');
}

export const isLoggedUserAConsulente = () => {
    return localStorage.getItem('consulenteId') !== null && localStorage.getItem('consulenteId') !== "null";
}

export const setSuperconsulente = (flag) => {
    localStorage.setItem('superconsulente', flag);
}

export const isSuperconsulente = () => {
    return localStorage.getItem('superconsulente');
}

export const setGruppoId = (gruppoId) => {
    localStorage.setItem('gruppoId', gruppoId);
}

export const getGruppoId = () => {
    return localStorage.getItem('gruppoId');
}

export const setBrandId = (id) => {
    localStorage.setItem('brand', id);
}

export const getBrandId = () => {
    return localStorage.getItem('brand');
}
export const setChapterIndex = (chapterIndex) => {
    localStorage.setItem('chapterIndex', chapterIndex);
}
export const getChapterIndex = () => {
    return localStorage.getItem('chapterIndex') !== null ? localStorage.getItem('chapterIndex') : '0';
}
export const setParagraphIndex = (paragraphIndex) => {
    localStorage.setItem('paragraphIndex', paragraphIndex);
}
export const getParagraphIndex = () => {
    return localStorage.getItem('paragraphIndex') !== null ? localStorage.getItem('paragraphIndex') : '0';
}
export const setOpenParti = (openParti) => {
    localStorage.setItem('openParti', openParti);
}
export const getOpenParti = () => {
    return localStorage.getItem('openParti');
}
