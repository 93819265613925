import {Component} from "react";
import React from "react";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ActionButton from "../../../components/ActionButton";
import theme from '../../../theme';
import { Checkbox, IconButton, ListItemIcon, MenuItem, Select, TextField } from "@material-ui/core";
import { getReparti, updateReparti } from "../../../utils/api/reparti";
import ModalResponsabiliPerReparto from "../manualecomponents/paragrafo4/ModalResponsabiliPerReparto";
import { ArrowDownward, ArrowUpward, Edit, Settings,Close, KeyboardArrowUp, FormatListBulleted } from "@material-ui/icons";
import EditRecordButton from "../components/EditRecordButton";
import { getResponsabiliSicurezzaPerEsercizioDatoManualeId } from "../../../utils/api/responsabili_sicurezza_api";
import { getContenutoParagrafo, updateSottoparagrafi } from "../../../utils/api/paragrafi_api";
import { getComponentiParagrafo, getProceduraHaccp } from "../../../utils/api/componenti_paragrafi_api";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { getLocaliManuale, getTotaleLocali, updateLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";
import ModalSelezioneAttrezzatura from "./ModalSelezioneAttrezzatura";
import ModalSelezioneModalitaAutocontrollo from "./ModalSelezioneModalitaAutocontrollo";
import GoToIcon from "./GoToIcon";

const styles = {
    mainContainer: {
      textAlign: 'center',
      color: 'black'
    }
};

export default class CustomTableForManuale extends Component {
  constructor(props){
    super(props);
    this.state = {
        columns:[],
        records: [],
        reparti:[],
        isBlocked: this.props.isParagrafoBlocked,
        isModello:true,
        selectedReparto:null,
        responsabiliSicurezza:[],
        sottoParagrafiDataAfterSave:this.props.sottoParagrafiData,
        selectProcedureAutocontrolloModalOpen:false,
        titoloInizialeSottoparagrafo:this.props.titoloInizialeSottoparagrafo,
        firstElement:null,
        openModalResponsabili:false,
        selectedLocale:null,
        openModalAttrezzatura:false,
        indexTab:this.props.indexTab,
        isTitleEditable:false,
        totalPages:0,
        title:this.props.titolo,
        rowWidth:0,
        editMode:false,
        dataToPassForProcedure:this.props.sottoParagrafiData,
        mode:"",
        firstRender:true,
        id:0,
    }
  }
  deleteElement = (cellInfo) => {
    let array = [...this.state.records]
    array.splice(cellInfo.index,1);
    this.setState({
      records:array,
    })
  }
  
  swapElements = (arr, i1, i2) => {
    let temp = arr[i1];

    arr[i1] = arr[i2];
  
    arr[i2] = temp;

  }
  swapElementsLocali = (arr, i1, i2) => {
    let temp = arr[i1];
    let tempIndice = arr[i1].indiceOrdinamento;
    let tempIndice2 = arr[i2].indiceOrdinamento;
    arr[i1] = arr[i2];
    arr[i2] = temp;
    arr[i2].indiceOrdinamento = tempIndice;
    arr[i1].indiceOrdinamento = tempIndice2;
  }
  getResponsabiliMailFromId = (ids) => {
    let mails = "";
    let responsabili = [...this.state.responsabiliSicurezza];
    ids.map((item,index) => { 
      responsabili.map((item2,index2) => {
        if(item === item2.id){
          if(ids.length-1 === index){
          mails += item2.email
          }
          else{
          mails += item2.email + " , "
          }
        }
      })
    })
    return mails;
  }
  moveElementUpward = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index > 0){
      this.swapElements(array,cellInfo.index,cellInfo.index-1);
      this.setState({
        records:array,
      })
    }
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
  }
  }
  moveElementUpwardLocali = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index > 0){
      this.swapElementsLocali(array,cellInfo.index,cellInfo.index-1);
      this.setState({
        records:array,
      },() => {
        this.aggiornaLocali()
      })
    }
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
  }
  }
  moveElementUpwardOrganigramma = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index > 0){
      this.swapElements(array,cellInfo.index,cellInfo.index-1);
      this.setState({
        records:array,
      },() => {
        this.aggiornaOrganigrammaAfterInput();
      })
    }
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
  }
  }
  moveElementUpwardAutocontrolli = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    let sottoparagrafiArray = [...this.state.dataToPassForProcedure];
    let logicalArray = sottoparagrafiArray[1].componenti;
    if(cellInfo.index > 0){
      this.swapElements(array,cellInfo.index,cellInfo.index-1);
      this.swapElements(logicalArray,cellInfo.index,cellInfo.index-1);
      sottoparagrafiArray.componenti = logicalArray;
      this.setState({
        records:array,
        dataToPassForProcedure:sottoparagrafiArray,
      },() => {
        this.props.bringDataAfterExchange(sottoparagrafiArray.componenti,this.state.indexTab);
      })
      //this.aggiornaDefinizioneProcedureAutocontrollo();
    }
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
  }
  }
  moveElementDownWardAutocontrolli = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    let sottoparagrafiArray = [...this.state.dataToPassForProcedure];
    let logicalArray = sottoparagrafiArray[1].componenti;
    if(cellInfo.index < array.length-1){
      this.swapElements(array,cellInfo.index,cellInfo.index+1);
      this.swapElements(logicalArray,cellInfo.index,cellInfo.index+1);
      sottoparagrafiArray.componenti = logicalArray;
      this.setState({
        records:array,
        dataToPassForProcedure:sottoparagrafiArray,
      },() => {
        this.props.bringDataAfterExchange(sottoparagrafiArray.componenti,this.state.indexTab);
      })
      //this.aggiornaDefinizioneProcedureAutocontrollo();
    }
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
  }
  }
  moveElementDownWard = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index < array.length-1){
      this.swapElements(array,cellInfo.index,cellInfo.index+1);
      this.setState({
        records:array,
      })
    }}else{
      alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
    }
  }
  moveElementDownWardLocali = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index < array.length-1){
      this.swapElementsLocali(array,cellInfo.index,cellInfo.index+1);
      this.setState({
        records:array,
      },() => {
        this.aggiornaLocali()
      })
    }}else{
      alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
    }
  }
  moveElementDownWardOrganigramma = (cellInfo) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    if(cellInfo.index < array.length-1){
      this.swapElements(array,cellInfo.index,cellInfo.index+1);
      this.setState({
        records:array,
      },() => {
        this.aggiornaOrganigrammaAfterInput();
      })
    }}else{
      alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
    }
  }
  onAssociaButtonClicked = (reparto) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    this.setState({
        openModalResponsabili: true,
        selectedReparto: reparto
    });
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
  }
  }
  onCloseModalResponsabili = () => {
    this.setState({
        openModalResponsabili: false
    });
}
onCloseModalSelezioneModalitaAutocontrollo = () => {
  this.setState({
    selectProcedureAutocontrolloModalOpen: false
  })
}
onAttrezzaturaButtonClicked = (locale) => {
  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
  this.setState({
      openModalAttrezzatura: true,
      selectedLocale: locale,
  });
}else{
  alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
}
}
onCloseModalAttrezzatura = () => {
  this.setState({
    openModalAttrezzatura: false
});
}

getNomeRepartoForDropdown = (data,index) =>{
  return data.nomeReparto;
}
getNomeRepartoFromId = (array,id) => {
  for (let i = 0; i < array.length; i++) {
    let record = array[i];
    if (record.id === id) {
      return record.nome;
    }
  }
}

fetchResponsabiliSicurezza = (manualeInCostruzioneId) => {
  getResponsabiliSicurezzaPerEsercizioDatoManualeId(manualeInCostruzioneId)
      .then(result => {
          this.setState({
              responsabiliSicurezza: result,
              //loading: false,
              //initialLoading: false
          });
      }).catch(error => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
}
onResponsabiliSelected = (responsabiliIds) => {
  // Create a new array of reparti with responsabili ids:
  let arrayReparti = [];
  let reparti = this.state.records;
  if(reparti !== undefined){
  for (let i = 0; i < reparti.length; i++) {
      let record = reparti[i];
      if (this.state.selectedReparto.id === reparti[i].id) {
          record.responsabili = responsabiliIds;
      } else {
          let arrayResponsabili = [];
          for (let j = 0; j < record.responsabili.length; j++) {
              arrayResponsabili.push(record.responsabili[j]);
          }
          record.responsabili = arrayResponsabili;
      }
      arrayReparti.push(record);
  }
  updateReparti(this.props.idManuale, arrayReparti)
      .then(result => {
          this.getRepartiData();
          this.onCloseModalResponsabili();
      })
      .catch(error => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
    }else{
      alert("Attenzione, selezionare almeno un consulente");
      this.setState({
        loading: false
    });
    }
  }

  aggiornaReparti = () => {
    /*updateReparti(this.props.idManuale, this.state.records)
      .then(result => {
          this.getRepartiData();
      })
      .catch(error => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
    });*/
  }
  
  aggiornaLocali = () => {
    let arrayToPass = [...this.state.records];
    /*arrayToPass.map(item => {
      delete item['nomeReparto']
      delete item['repartiToSelect']
    })*/
    this.props.aggiornaLocali(arrayToPass);
    /*let body = {
      manualeId:this.props.idManuale,
      locali:arrayToPass,
    }
    updateLocaliManuale(body).then(result => {
      this.getLocaliForTabella();
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })*/
  }

  aggiornaOrganigramma = (body) => {
    updateSottoparagrafi(body).then(result =>  {
      this.getOrganigrammaData();
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })
  }

  

  aggiornaTabellaCustom = (body) => {
    updateSottoparagrafi(body).then(result => {

    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })
  }

  aggiungiDefinizioneProcedureAutocontrollo = (body) => {
    let dataToPass = this.state.dataToPassForProcedure;
    let object = dataToPass[1].componenti;
    dataToPass[1].componenti.push(body);
    let bodyToPassForSottoparagrafi = {
      id: this.props.idParagrafo,
      manualeId:this.props.idManuale,
      titolo:"Definizione procedure autocontrollo",
      componenti:dataToPass,
    }
    updateSottoparagrafi(bodyToPassForSottoparagrafi).then(result => {
      this.getSchedeHCCP();
      this.props.bringDataToParent(bodyToPassForSottoparagrafi,this.state.indexTab);
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })
  }
  aggiornaDefinizioneProcedureAutocontrollo = () => {
    let dataToPass = [...this.state.dataToPassForProcedure];
    let bodyToPassForSottoparagrafi = {
      id: this.props.idParagrafo,
      manualeId:this.props.idManuale,
      titolo:"Definizione procedure autocontrollo",
      componenti:dataToPass,
    }
    updateSottoparagrafi(bodyToPassForSottoparagrafi).then(result => {
      this.getSchedeHCCP();
    }).catch(error => {
      
    })
  }
  handleCustomTableRow = (item) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let array = [...this.state.records];
    array.splice(item._index, 1);
    this.setState({
      records: array,
    },() => {this.aggiornaTabellaCustomHandler()})
  }else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
  }
  }

  aggiornaTabellaCustomHandler = () => {
    let array = [...this.state.records];
    let arrayOfValues = [];
    array.map(item => {
      delete item["Elimina"];
      arrayOfValues.push(Object.values(item));
    })
    let sottoParagrafiToPass = [...this.state.sottoParagrafiDataAfterSave];
    let idParagrafo = sottoParagrafiToPass[0].paragrafoId;
    sottoParagrafiToPass[this.props.indexTab].componenti[0].contenuto = JSON.stringify(arrayOfValues);
    sottoParagrafiToPass[this.props.indexTab].componenti[0].titolo = this.state.titoloInizialeSottoparagrafo;
    this.props.updateCustomTable(sottoParagrafiToPass);
    /*let bodyToPassForSottoparagrafi = {
      id: sottoParagrafiToPass[0].paragrafoId,
      manualeId:this.props.idManuale,
      titolo:this.props.titoloParagrafo,
      componenti:sottoParagrafiToPass,
    }
    this.aggiornaTabellaCustom(bodyToPassForSottoparagrafi);*/
  }

  aggiornaOrganigrammaAfterInput = () => {
    let array = [...this.state.records];
    let arrayToPass = [];
    array.map(item => {
      let objectArray = [];
      objectArray.push(item.NomeECognome);
      objectArray.push(item.Ruolo);
      objectArray.push(item.Mansione);
      objectArray.push(item.Identificativo);
      arrayToPass.push(objectArray);
    })
   let dataToPass = [...this.props.sottoParagrafiData];
    let object = JSON.parse(dataToPass[this.props.indexTab].componenti[0].contenuto);
    let objToPassToSave = {
      columns:["Nome e cognome","Ruolo","Mansione","Identificativo"],
      rows:arrayToPass
    }
    dataToPass.map(item => {
      if(item.titolo === "Organigramma"){
        //console.log(JSON.stringify(objToPassToSave));
        item.componenti[0].contenuto = JSON.stringify(objToPassToSave);
      }
    })
    
    let bodyToPassForSottoparagrafi = {
      id: this.props.idParagrafo,
      manualeId:this.props.idManuale,
      titolo:"Organigramma",
      componenti:dataToPass,
    }
    this.props.changeEditMode();
    //this.aggiornaOrganigramma(bodyToPassForSottoparagrafi);
  }

  componentDidMount = () => {
    this.initializeTable();
  }
  componentDidUpdate = (prevProps,prevState) => {
    if(this.props.mode !== this.state.mode){
      this.initializeTable();
    }
    if(this.props.titoloInizialeSottoparagrafo !== prevProps.titoloInizialeSottoparagrafo){
      this.setState({
        titoloInizialeSottoparagrafo : this.props.titoloInizialeSottoparagrafo,
        sottoParagrafiDataAfterSave:this.props.sottoParagrafiData,
      })
      if(this.props.mode === "Definizione_Reparto"){
        getReparti(this.props.idManuale,0,100,"id").then(result => {
          let arrayToSave = [];
          result.map(item => {
            let arrayResponsabiliToSave = [];
            item.responsabili.map(ids => {
              arrayResponsabiliToSave.push(ids.id);
            })
            arrayToSave.push({
              id:item.id,
              manualeId:item.manualeId,
              nome:item.nome,
              repartoDefault:item.repartoDefault,
              responsabileSicurezzaId:item.responsabileSicurezzaId,
              responsabileSicurezzaEmail:item.responsabileSicurezzaEmail,
              responsabileSicurezzaNome:item.responsabileSicurezzaNome,
              responsabileSicurezzaCognome:item.responsabileSicurezzaCognome,
              responsabili:arrayResponsabiliToSave,
            })
          })
          this.setState({
            records:arrayToSave,
            selectedReparto:result[0],
            firstElement:result[0],
            firstRender:false,
          })
        }).then(result => {
          this.fetchResponsabiliSicurezza(this.props.idManuale);
        }).catch(error => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
        
      }
    }
  }
  initializeTable = () => {
    let a = null;
    this.setState({
      mode:this.props.mode
    })
    if(this.props.kind === "modello"){
      this.setState({
        isModello:true,
      })
    }
    else{
      this.setState({
        isModello:false,
      })
    }
    if(this.props.mode === "Organigramma"){
      a = this.getColumnsForOrganigramma();
    }
    else if(this.props.mode === "Definizione_Reparto"){
      a = this.getColumnsForDefinizioneReparto();
    }
    else if(this.props.mode === "Descrizione_Reparto"){
      a = this.getColumnsForDescrizioneReparti();
    }
    else if(this.props.mode === "Definizione_Procedure_Autocontrollo"){
      a = this.getColumnsForElencoProcedureAutocontrollo();
    }
    else{
      a = this.getColumns();
    }   
    this.setState({
        columns: a,
    }); 
    this.setState({
      rowWidth : 100/this.state.columns.length,
    })
  }
  getColumns = () => {
    let passedColumns = this.props.columns;
    let content = this.props.contenuto;
    if(content !== undefined){
    let contentToSave = JSON.parse(content);
    let recordsToSave = [];
    contentToSave.map((item2,index2) => {
      let objectToSaveInArray = {};
    passedColumns.map((item,index) => {
      objectToSaveInArray[item] = contentToSave[index2][index];
    })
    recordsToSave.push(objectToSaveInArray);
    })
    this.setState({
      records:recordsToSave,
    },() => {
      
    })
  }
    let generatedColumns = [];
    if(passedColumns[0].header === undefined){
      passedColumns.map((item) => {
        generatedColumns.push({
          Header:item,
          id:item,
          accessor:d => d[item],
          Cell:props => <TextField
          style = {{width: "100%"}} 
          InputProps={{ disableUnderline: true}} 
          inputProps={{style: { textAlign: 'center',width:"100%" }}}
          placeholder="Inserisci qui i dati..." 
          disabled={!this.state.editMode}
          onBlur={this.aggiornaTabellaCustomHandler}
          value={props.row[item]}
          onInput={(event) => {
            let records = [...this.state.records];
            records.map((object,index) => {
            if(index === props.row._index){
            records[index][item] = event.target.value;
            const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
            this.setState({
              records:records,
            })
            }
            })
          }}
          />
        })
      })
    }else{
    passedColumns.map((item,index) => {
      generatedColumns.push({
        Header:item.header,
        id:item.id,
        accessor:d => d[item.header],
        Cell:props => <TextField value={props.row[item.id]} 
        placeholder="Inserisci qui i dati..." 
        disabled={!this.state.editMode}
          onBlur={this.aggiornaTabellaCustomHandler}
          style = {{width: "100%"}} 
          InputProps={{ disableUnderline: true}} 
          inputProps={{min: 0, style: { textAlign: 'center' }}} 
          onInput={(event) => {
            let records = [...this.state.records];
            records.map((object,index) => {
            if(index === props.row._index){
            records[index][item.id] = event.target.value;
            const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
            this.setState({
              records:records,
            })
            }
            })
          }}/>
      })
    })}
    generatedColumns.push({
      Header:"Elimina",
      id:"Elimina",
      accessor:d => d,
      width:120,
      Cell:props => <ActionButton label="Elimina" disabled={false} grayVersion={false} onClick={() => this.handleCustomTableRow(props.row)}></ActionButton>
    })
    return generatedColumns/*[
        {
            Header:"Colonna 1",
            id:"Colonna1",
            width:this.state.rowWidth.toString+"%",
        },
        {
          Header:"Colonna 2",
          id:"Colonna2",
          width:this.state.rowWidth.toString+"%",
        },
        {
          Header:"Colonna 3",
          id:"Colonna3",
          width:this.state.rowWidth.toString+"%",
        },
        {
          Header:"Colonna 4",
          id:"Colonna4",
          width:this.state.rowWidth.toString+"%",
        },
        {
          Header:"Colonna 5",
          id:"Colonna5",
          width:this.state.rowWidth.toString+"%",
        },
    ]*/
}

getColumnsForOrganigramma = () => {
  this.getOrganigrammaData();
  return [
      {
          Header:"Nome e Cognome",
          id:"NomeECognome",
          width:200,
          accessor: d => d.NomeECognome,
          Cell: props => <TextField
          onBlur={this.aggiornaOrganigrammaAfterInput}
          InputProps={{ disableUnderline: true }} 
          inputProps={{min: 0, style: { textAlign: 'center' }}} 
          disabled={!this.state.editMode}
          onChange={(event) => {
          props.row.NomeECognome = event.target.value;
          var OrganigrammaToChange = [...this.state.records];
          OrganigrammaToChange[props.index].NomeECognome = event.target.value;
          const caret = event.target.selectionStart
      const element = event.target
      window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
      })
          this.setState({
            records:OrganigrammaToChange,
          })
          }}
          style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
          value={props.row.NomeECognome}></TextField>
      },
      {
        Header:"Ruolo",
        id:"Ruolo",
        width:this.state.rowWidth.toString+"%",
        accessor: d => d.Ruolo,
        Cell: props => <TextField
          onBlur={this.aggiornaOrganigrammaAfterInput} 
          InputProps={{ disableUnderline: true }} 
          inputProps={{min: 0, style: { textAlign: 'center' }}} 
          disabled={!this.state.editMode}
          onChange={(event) => {
          props.row.Ruolo = event.target.value;
          var OrganigrammaToChange = [...this.state.records];
          OrganigrammaToChange[props.index].Ruolo = event.target.value;
          const caret = event.target.selectionStart
      const element = event.target
      window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
      })
          this.setState({
            records:OrganigrammaToChange,
          })
          }}
          style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
          value={props.row.Ruolo}></TextField>
      },
      {
        Header:"Mansione",
        id:"Mansione",
        width:this.state.rowWidth.toString+"%",
        accessor: d => d.Mansione,
        Cell: props => <TextField
          onBlur={this.aggiornaOrganigrammaAfterInput} 
          InputProps={{ disableUnderline: true }} 
          inputProps={{min: 0, style: { textAlign: 'center' }}} 
          disabled={!this.state.editMode}
          onChange={(event) => {
          props.row.Mansione = event.target.value;
          var OrganigrammaToChange = [...this.state.records];
          OrganigrammaToChange[props.index].Mansione = event.target.value;
          const caret = event.target.selectionStart
      const element = event.target
      window.requestAnimationFrame(() => {
        element.selectionStart = caret
        element.selectionEnd = caret
      })
          this.setState({
            records:OrganigrammaToChange,
          })
          }}
          style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
          value={props.row.Mansione}></TextField>
      },
      {
        Header:"Identificativo",
        id:"Identificativo",
        width:this.state.rowWidth.toString+"%",
        accessor: d => d.Identificativo,
        Cell: props => <TextField
          onBlur={this.aggiornaOrganigrammaAfterInput} 
          InputProps={{ disableUnderline: true }} 
          inputProps={{min: 0, style: { textAlign: 'center' }}} 
          disabled={!this.state.editMode}
          onChange={(event) => {
          props.row.Identificativo = event.target.value;
          var OrganigrammaToChange = [...this.state.records];
          OrganigrammaToChange[props.index].Identificativo = event.target.value;
          const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
          this.setState({
            records:OrganigrammaToChange,
          })
          }}
          style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
          value={props.row.Identificativo}></TextField>
      },
      {
        Header:"Sposta",
        id:"SpostaOrganigramma",
        width:100,
        accessor:d => d,
        Cell:props => <div style={{
          display: "flex",

          flexDirection: 'column',
          flex: 1,
      }}>
        <IconButton size="small" onClick={() => {this.moveElementUpwardOrganigramma(props)}}>
        <KeyboardArrowUp></KeyboardArrowUp>
        </IconButton>
        <IconButton size="small" onClick={() => {this.moveElementDownWardOrganigramma(props)}}>
        <KeyboardArrowDown></KeyboardArrowDown>
        </IconButton>
      </div>
      },
      {
        Header:"Elimina",
        id:"EliminaOrganigramma",
        width:120,
        accessor:d => d,
        Cell:props => <Button type="button"
        variant="contained"
        size="medium"
        disabled={props.value.nome === "intera struttura"}
        onClick={() => this.deleteElementOrganigramma(props)}
        style={{
        margin:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: props.value.nome === "intera struttura" ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        >ELIMINA</Button>
      }
  ]
}

getOrganigrammaData = () => {
  getContenutoParagrafo(this.props.idParagrafo).then(result => {
    let arrayOfRecords = [];
    result.componenti.map(item => {
      if(item.titolo === "Organigramma"){
        let organigrammaDataToUse = JSON.parse(item.componenti[0].contenuto);
        if(organigrammaDataToUse.rows.length > 0){
        organigrammaDataToUse.rows.map(itemToPass => {
          arrayOfRecords.push({
            NomeECognome:itemToPass[0],
            Ruolo:itemToPass[1],
            Mansione:itemToPass[2],
            Identificativo:itemToPass[3],
          });
        })
      }
      }
    })
    this.setState({
      records:arrayOfRecords,
      firstRender:false,
    })
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  })
}

getSchedeHCCP = () => {
  getContenutoParagrafo(this.props.idParagrafo).then(result => {
    let recordsToSave = [];
    this.setState({
      dataToPassForProcedure:result.componenti,
    })
    let indexToUseToKeepTrack = result.componenti.findIndex(item => item.tipoContenuto === "TABELLA_PROCEDURE");
    result.componenti[indexToUseToKeepTrack].componenti.map(item => {
      let objectToSave = {};
      if(item.tipo === "PROCEDURA_HACCP"){
      objectToSave = {
        titolo:item.titolo,
        isScheda:false,
        isProceduraHaccp:true,
        id:item.id,
        idParagrafo:item.paragrafoId,
      }
      }
      else if(item.tipo === "SCHEDA"){
        objectToSave = {
          titolo:item.titolo,
          isScheda:true,
          isProceduraHaccp:false,
          id:item.id,
          idParagrafo:item.paragrafoId,
        }
      }
      recordsToSave.push(objectToSave);
    })
    this.setState({
      records:recordsToSave,
      firstRender:false,
    })
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  })
}

getRepartiData = () => {
  getReparti(this.props.idManuale,0,100,"id").then(result => {
    let arrayToSave = [];
    result.map(item => {
      let arrayResponsabiliToSave = [];
      item.responsabili.map(ids => {
        arrayResponsabiliToSave.push(ids.id);
      })
      arrayToSave.push({
        id:item.id,
        manualeId:item.manualeId,
        nome:item.nome,
        repartoDefault:item.repartoDefault,
        responsabileSicurezzaId:item.responsabileSicurezzaId,
        responsabileSicurezzaEmail:item.responsabileSicurezzaEmail,
        responsabileSicurezzaNome:item.responsabileSicurezzaNome,
        responsabileSicurezzaCognome:item.responsabileSicurezzaCognome,
        responsabili:arrayResponsabiliToSave,
      })
    })
    this.setState({
      records:arrayToSave,
      selectedReparto:result[0],
      firstElement:result[0],
    })
  }).then(result => {
    this.fetchResponsabiliSicurezza(this.props.idManuale);
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  });
}


getColumnsForDefinizioneReparto = () => {
  if(this.state.firstRender){
  getReparti(this.props.idManuale,0,100,"id").then(result => {
    let arrayToSave = [];
    result.map(item => {
      let arrayResponsabiliToSave = [];
      item.responsabili.map(ids => {
        arrayResponsabiliToSave.push(ids.id);
      })
      arrayToSave.push({
        id:item.id,
        manualeId:item.manualeId,
        nome:item.nome,
        repartoDefault:item.repartoDefault,
        responsabileSicurezzaId:item.responsabileSicurezzaId,
        responsabileSicurezzaEmail:item.responsabileSicurezzaEmail,
        responsabileSicurezzaNome:item.responsabileSicurezzaNome,
        responsabileSicurezzaCognome:item.responsabileSicurezzaCognome,
        responsabili:arrayResponsabiliToSave,
      })
    })
    this.setState({
      records:arrayToSave,
      selectedReparto:result[0],
      firstElement:result[0],
      firstRender:false,
    })
  }).then(result => {
    this.fetchResponsabiliSicurezza(this.props.idManuale);
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  });
  }
  return [
    {
      Header:"Reparto",
      id:"Reparto",
      width:this.state.rowWidth.toString+"%",
      accessor: d => d.nome,
      Cell: props => <TextField
       onBlur={() => this.props.aggiornaReparti(this.state.records,props.row._index)} 
       InputProps={{ disableUnderline: true }} 
       disabled={!this.state.editMode}
       onChange={(event) => {
        props.row.Reparto = event.target.value;
        var repartiToChange = [...this.state.records];
        repartiToChange[props.index].nome = event.target.value;
        const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
        this.setState({
          records:repartiToChange,
        }) 
       }}
       style={{marginLeft:60,padding:10,outline: `0 solid transparent`}} 
       value={props.row.Reparto}></TextField>
    },
    {
      Header:"Email Utenza",
      id:"EmailUtente",
      width:this.state.rowWidth.toString+"%",
      accessor: d => d,
      Cell: props => <TextField
      InputProps={{ disableUnderline: true }} 
      disabled={true}
      style={{marginLeft:40,padding:10,width:"100%",outline: `0 solid transparent`}} 
      value={this.getResponsabiliMailFromId(props.row.EmailUtente.responsabili)}></TextField>
    },
    {
      Header:"Associa Utenza",
      id:"AssociaUtente",
      width:200,
      accessor:d => d,
      Cell: props => <Button type="button" variant="contained"
      size="medium"
      disabled={this.state.isModello}
      onClick={() => this.onAssociaButtonClicked(props.value)}
      style={{
        marginTop:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: this.state.isModello ? theme.palette.disabled.main : theme.palette.primary.main,
      }}
      >
        Associa utente
      </Button>
    },
    /*{
      Header:"Sposta",
      id:"SpostaDefinizioneReparto",
      width:100,
      Cell:props => <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
        <IconButton onClick={() => {this.moveElementDownWard(props)}}>
        <ArrowDownward></ArrowDownward>
        </IconButton>
        <IconButton onClick={() => {this.moveElementUpward(props)}}>
        <ArrowUpward></ArrowUpward>
        </IconButton>
      </div>
    },*/
    {
      Header:"Elimina",
      id:"EliminaDefinizioneReparto",
      width:120,
      accessor: d => d,
      Cell:props => <Button type="button"
      variant="contained"
      size="medium"
      disabled={props.value.repartoDefault}
      onClick={() => this.deleteElementReparto(props)}
      style={{
        margin:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: props.value.repartoDefault ? theme.palette.disabled.main : theme.palette.primary.main,
      }}
      >ELIMINA</Button>
    },
    /*{
      Header:"Modifica",
      id:"ModificaDefinizioneReparto",
      width:100,
      accessor: d => d,
      Cell: props => !this.state.editMode ? <IconButton style={{color:theme.palette.primary.main}} onClick={() => this.setState({editMode:!this.state.editMode})}><Edit></Edit></IconButton> 
      : <IconButton style={{color:theme.palette.primary.main}} onClick={() => this.setState({editMode:!this.state.editMode})}><Close></Close></IconButton> 
    }*/
  ]
}

getLocaliForTabella = () => {
  let a = 0;
  getTotaleLocali(this.props.idManuale).then(result => {
    a = result;
    this.setState({
      totalPages:Math.ceil(a/10),
    })
  }).then(result => {
    getLocaliManuale(this.props.idManuale,0,100000,"indiceOrdinamento").then(result => {
      let arrayOfLocali = result;
      getReparti(this.props.idManuale,0,100,"id").then(result => {
        let arrayOfReparti = result;
        arrayOfLocali.map(item => {
          item.repartiToSelect= arrayOfReparti;
        })
        this.setState({
          records:arrayOfLocali,
        },() => {
         
        })
      })
    },() => {

    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  })
}


getColumnsForDescrizioneRepartiAfterFirstLoading = () => {
  return [
    {
      Header:"Reparti",
      id:"RepartiDescrizione",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
      Cell: props => 
      <>
      { props.row.RepartiDescrizione.repartiToSelect !== undefined ? <Select disabled={!(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null"))} variant="outlined" onChange={(event) => {
        props.row.RepartiDescrizione.repartoId = event.target.value;
        var LocaliToChange = [...this.state.records];
          LocaliToChange[props.index].repartoId = event.target.value;
          let nome = this.getNomeRepartoFromId(props.row.RepartiDescrizione.repartiToSelect,event.target.value);
          LocaliToChange[props.index].nomeReparto = this.getNomeRepartoFromId(props.row.RepartiDescrizione.repartiToSelect,event.target.value);
          this.setState({
            records:LocaliToChange,
          })
          this.aggiornaLocali();
      }} value={props.row.RepartiDescrizione.repartoId} style={{paddingTop:0,width:"100%"}}>
        {
          props.row.RepartiDescrizione.repartiToSelect !== undefined ? 
          props.row.RepartiDescrizione.repartiToSelect.map((item,index) =>
          <MenuItem key={index} value={item.id} onClick={() => {}}>{item.nome}</MenuItem>
         ) : null}
      </Select> : <Select></Select>}
      </>
    },
    {
      Header:"Locale",
      id:"Locale",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d.nome,
      Cell: props => <TextField
      InputProps={{ disableUnderline: true }} 
      onBlur={this.aggiornaLocali} 
      onChange={(event) => {
          props.row.Locale = event.target.value;
          var LocaliToChange = [...this.state.records];
          LocaliToChange[props.index].nome = event.target.value;
          const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
          this.setState({
            records:LocaliToChange,
          })
      }}
      inputProps={{min: 0, style: { textAlign: 'center' }}} 
      disabled={!this.state.editMode}
      style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
      value={props.row.Locale}></TextField>
    },
    {
      Header:"Dimensioni",
      id:"Dimensioni",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d.dimensione,
      Cell: props => <TextField
      onBlur={this.aggiornaLocali} 
      onChange={(event) => {
          props.row.Dimensioni = event.target.value;
          var DimensioniToChange = [...this.state.records];
          DimensioniToChange[props.index].dimensione = event.target.value;
          this.setState({
            records:DimensioniToChange,
          })
      }}
      InputProps={{ disableUnderline: true }} 
      type="number"
      inputProps={{min: 0, style: { textAlign: 'center' }}} 
      disabled={!this.state.editMode}
      style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
      value={props.row.Dimensioni}></TextField>
    },
    {
      Header:"Attrezzature",
      id:"Attrezzature",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
      Cell: props => <IconButton onClick = {() => this.onAttrezzaturaButtonClicked(props.row.Attrezzature)}><Settings></Settings></IconButton>
    },
    {
      Header:"Sposta",
      id:"SpostaDescrizioneReparto",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
        Cell:props => <div style={{
          display: "flex",
          flexDirection: 'column',
          flex: 1,
      }}>
        <IconButton size="small" onClick={() => {this.moveElementUpwardLocali(props)}}>
        <KeyboardArrowUp></KeyboardArrowUp>
        </IconButton>
        <IconButton size="small" onClick={() => {this.moveElementDownWardLocali(props)}}>
        <KeyboardArrowDown></KeyboardArrowDown>
        </IconButton>
      </div>
    },
    {
      Header:"Elimina",
      id:"EliminaDescrizioneReparto",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
        Cell:props => <Button type="button"
        variant="contained"
        size="medium"
        onClick={() => this.deleteElementDescrizioneReparto(props)}
        style={{
        margin:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: props.value.nome === "intera struttura" ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        >ELIMINA</Button>
    },
  ]
}
getColumnsForDescrizioneReparti = () => {
  let a = 0;
  this.getLocaliForTabella();
  return [
    {
      Header:"Reparti",
      id:"RepartiDescrizione",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
      Cell: props => 
      <>
      { props.row.RepartiDescrizione.repartiToSelect !== undefined ? <Select disabled={!(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null"))} variant="outlined" onChange={(event) => {
        //props.row.RepartiDescrizione.repartoId = event.target.value;
        var LocaliToChange = [...this.state.records];
          LocaliToChange[props.index].repartoId = event.target.value;
          let nome = this.getNomeRepartoFromId(props.row.RepartiDescrizione.repartiToSelect,event.target.value);
          LocaliToChange[props.index].nomeReparto = this.getNomeRepartoFromId(props.row.RepartiDescrizione.repartiToSelect,event.target.value);
          this.setState({
            records:LocaliToChange,
          },() => {
            this.aggiornaLocali();
          })
      }} value={props.row.RepartiDescrizione.repartoId} style={{paddingTop:0,width:"100%"}}>
        {
          props.row.RepartiDescrizione.repartiToSelect !== undefined ? 
          props.row.RepartiDescrizione.repartiToSelect.map((item,index) =>
          <MenuItem key={index} value={item.id} onClick={() => {}}>{item.nome}</MenuItem>
         ) : null}
      </Select> : <Select></Select>}
      </>
    },
    {
      Header:"Locale",
      id:"Locale",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d.nome,
      Cell: props => <TextField
      InputProps={{ disableUnderline: true }} 
      onBlur={this.aggiornaLocali} 
      onChange={(event) => {
          props.row.Locale = event.target.value;
          var LocaliToChange = [...this.state.records];
          LocaliToChange[props.index].nome = event.target.value;
          const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
          this.setState({
            records:LocaliToChange,
          })
      }}
      inputProps={{min: 0, style: { textAlign: 'center' }}} 
      disabled={!this.state.editMode}
      style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
      value={props.row.Locale}></TextField>
    },
    {
      Header:"Dimensioni",
      id:"Dimensioni",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d.dimensione,
      Cell: props => <TextField
      onBlur={this.aggiornaLocali} 
      onChange={(event) => {
          props.row.Dimensioni = event.target.value;
          var DimensioniToChange = [...this.state.records];
          DimensioniToChange[props.index].dimensione = event.target.value;
          this.setState({
            records:DimensioniToChange,
          })
      }}
      type="number"
      InputProps={{ disableUnderline: true }} 
      inputProps={{min: 0, style: { textAlign: 'center' }}} 
      disabled={!this.state.editMode}
      style={{textAlign:"center",padding:10,outline: `0 solid transparent`}} 
      value={props.row.Dimensioni}></TextField>
    },
    {
      Header:"Attrezzature",
      id:"Attrezzature",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
      Cell: props => <IconButton style={{ color: theme.palette.primary.main }} onClick = {() => this.onAttrezzaturaButtonClicked(props.row.Attrezzature)}><Settings></Settings></IconButton>
    },
    {
      Header:"Sposta",
      id:"SpostaDescrizioneReparto",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
        Cell:props => <div style={{
          display: "flex",
          flexDirection: 'column',
          flex: 1,
      }}>
        <IconButton size="small" onClick={() => {this.moveElementUpwardLocali(props)}}>
        <KeyboardArrowUp></KeyboardArrowUp>
        </IconButton>
        <IconButton size="small" onClick={() => {this.moveElementDownWardLocali(props)}}>
        <KeyboardArrowDown></KeyboardArrowDown>
        </IconButton>
      </div>
    },
    {
      Header:"Elimina",
      id:"EliminaDescrizioneReparto",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d,
        Cell:props => <Button type="button"
        variant="contained"
        size="medium"
        onClick={() => this.deleteElementDescrizioneReparto(props)}
        style={{
        margin:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: props.value.nome === "intera struttura" ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        >ELIMINA</Button>
    },
  ]
}
getColumnsForElencoProcedureAutocontrollo = () => {
  this.getSchedeHCCP();
  return [
    {
      Header:"Titolo",
      id:"TitoloProcedureAutocontrollo",
      width:this.state.rowWidth.toString+"%",
      accessor:d => d.titolo,
      Cell: props => <TextField  
      disabled={!this.state.editMode} 
      InputProps={{ disableUnderline: true }}
      style={{textAlign:"center",width:"100%",padding:10,outline: `0 solid transparent`}} 
      inputProps={{min: 0, style: { textAlign: 'center' }}}
      value={props.row.TitoloProcedureAutocontrollo}
      //onBlur={() => this.aggiornaDefinizioneProcedureAutocontrollo()}
      onChange={(event) => {
        let newValues = [...this.state.records];
        newValues[props.index].titolo = event.target.value;
        const caret = event.target.selectionStart
          const element = event.target
          window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
          })
        this.setState({
          records:newValues,
        })
        let dataToPass = this.state.dataToPassForProcedure;
        var item = dataToPass[1].componenti.findIndex(item => newValues[props.index].id === item.id);
        dataToPass[1].componenti[item].titolo = event.target.value;
        this.setState({
          dataToPassForProcedure:dataToPass,
        })
        this.props.bringTextToParent(dataToPass,this.state.indexTab,props.row._index);
      }}
      >
      </TextField>
    },
    {
      Header:"Controllo",
      id:"ControlloProcedureAutocontrollo",
      width:130,
      accessor:d => d,
      Cell : props => <Checkbox disabled checked={props.row.ControlloProcedureAutocontrollo.isProceduraHaccp}></Checkbox>
    },
    {
      Header:"Scheda",
      id:"SchedaProcedureAutocontrollo",
      width:130,
      accessor:d => d,
      Cell : props => <Checkbox disabled checked={props.row.SchedaProcedureAutocontrollo.isScheda}></Checkbox>
    },
    {
      Header:"Modifica",
      id:"ModificaProcedureAutocontrollo",
      width:100,
      accessor: d => d,
      Cell: props => //<ActionButton onClick = {() => console.log(props.row.ModificaProcedureAutocontrollo)}></ActionButton>
      props.row.ModificaProcedureAutocontrollo.isScheda ? <GoToIcon mode = "Scheda" idManuale={this.props.idManuale} id={props.row.ModificaProcedureAutocontrollo.id} idParagrafo = {props.row.ModificaProcedureAutocontrollo.idParagrafo}></GoToIcon> 
      : (props.row.ModificaProcedureAutocontrollo.isProceduraHaccp) ? <GoToIcon mode="Procedura"  idManuale={this.props.idManuale} id={props.row.ModificaProcedureAutocontrollo.id} idParagrafo = {props.row.ModificaProcedureAutocontrollo.idParagrafo}></GoToIcon> : 
      <ActionButton onClick = {() => console.log("")}></ActionButton>},
    {
      Header:"Sposta",
      id:"ModificaProcedureAutocontrollo",
      width:100,
      accessor:d => d,
        Cell:props => <div style={{
          display: "flex",
          flexDirection: 'column',
          flex: 1,
      }}>
        <IconButton size="small" onClick={() => {this.moveElementUpwardAutocontrolli(props)}}>
        <KeyboardArrowUp></KeyboardArrowUp>
        </IconButton>
        <IconButton size="small" onClick={() => {this.moveElementDownWardAutocontrolli(props)}}>
        <KeyboardArrowDown></KeyboardArrowDown>
        </IconButton>
      </div>
    },
    {
      Header:"Elimina",
      id:"EliminaProcedureAutocontrollo",
      width:120,
      accessor:d => d,
        Cell:props => <Button type="button"
        variant="contained"
        size="medium"
        onClick={() => this.deleteProcedureAutocontrollo(props)}
        style={{
        margin:5,
                            color: theme.palette.secondary.main,
                            backgroundColor: props.value.nome === "intera struttura" ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        >ELIMINA</Button>
    },
  ]
}
deleteProcedureAutocontrollo = (cellInfo) => {
  let array = Array.from(this.state.records);
  array.splice(cellInfo.index,1);
  let itemToPass = this.state.dataToPassForProcedure[1].componenti;
  let dataToPassForSottoparagrafi = this.state.dataToPassForProcedure;
  itemToPass.splice(cellInfo.index,1);
  dataToPassForSottoparagrafi[1].componenti = itemToPass;
  let bodyToPassForSottoparagrafi = {
    id: this.props.idParagrafo,
    manualeId:this.props.idManuale,
    titolo:"Definizione procedure autocontrollo",
    componenti:dataToPassForSottoparagrafi,
  }
  updateSottoparagrafi(bodyToPassForSottoparagrafi).then(result => {
    this.getSchedeHCCP();
    this.props.bringDataToParent(bodyToPassForSottoparagrafi,this.state.indexTab);
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  })

}


deleteElementReparto = (cellInfo) => {
  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
  let array = Array.from(this.state.records);
  array.splice(cellInfo.index,1);
  this.setState({
    records:array,
  },() => {
    this.props.aggiornaReparti(array);
  })
  /*updateReparti(this.state.records[0].manualeId,array).then(result => {
    this.getRepartiData();
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  });*/
}else{
  alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
}
}

deleteElementOrganigramma = (cellInfo) => {
  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
  let array = Array.from(this.state.records);
  array.splice(cellInfo.index,1);
  this.setState({records:array});
  let arrayToPass = [];
  array.map(item => {
    let objectArray = [];
    objectArray.push(item.NomeECognome);
    objectArray.push(item.Ruolo);
    objectArray.push(item.Mansione);
    objectArray.push(item.Identificativo);
    arrayToPass.push(objectArray);
  })
 let dataToPass = [...this.props.sottoParagrafiData];
  let object = JSON.parse(dataToPass[this.state.indexTab].componenti[0].contenuto);
  let objToPassToSave = {
    columns:["Nome e cognome","Ruolo","Mansione","Identificativo"],
    rows:arrayToPass
  }
  dataToPass.map(item => {
    if(item.titolo === "Organigramma"){
      //console.log(JSON.stringify(objToPassToSave));
      item.componenti[0].contenuto = JSON.stringify(objToPassToSave);
    }
  })
  
  let bodyToPassForSottoparagrafi = {
    id: this.props.idParagrafo,
    manualeId:this.props.idManuale,
    titolo:"Organigramma",
    componenti:dataToPass,
  }
  //this.aggiornaOrganigramma(bodyToPassForSottoparagrafi);
}else{
  alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
}
}

deleteElementDescrizioneReparto = (cellInfo) => {
  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
  let array = Array.from(this.state.records);
  array.splice(cellInfo.index,1);
  this.setState({
    records:array,
  },()=>{
    this.aggiornaLocali()
  })
  /*let body = {
    manualeId:this.props.idManuale,
    locali:array,
  }
  updateLocaliManuale(body).then(result => {
    this.getLocaliForTabella();
  }).catch(error => {
    if (error.status === 403) {
      this.props.handleInvalidToken();
    } else {
      this.props.handleError(true, error.message);
    }
  });*/}else{
    alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
  }
}

arrayToString = (a, b) => {
  let parse = {};
  //let parse = JSON.parse(a.componenti[2].componenti[0].contenuto);
  //delete parse.rows;
  let string = null;
  let arr = []
  for (let i = 0; i < b.length; i++) {
      let array = []
      const element = b[i].Responsabile;
      const element1 = b[i].Mansione;
      const element2 = b[i].NomeECognome;
      const element3 = b[i]['Identificativo operatore'];
      array.push(element, element1, element2, element3);
      arr.push(array);
  }
  parse.columns = this.state.columns;
  parse.rows = arr
  string = JSON.stringify(parse);
  return string
  //     this.setState({string})
}

handleNewRow = () => {
  if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
  let newRowRecords = Array.from(this.state.records);
    /*let obj = newRowRecords[0];
    console.log(obj);
    newRowRecords.push(obj);
    console.log(newRowRecords);
    updateReparti(obj.manualeId,newRowRecords).then(result => {
      this.getRepartiData();
    }).catch(error => {
      console.log(error);
    })*/
   /* console.log("I am empty");
    let emptyObj = this.state.firstElement;
    newRowRecords.push(emptyObj);
    console.log(newRowRecords);*/
  if(this.props.mode === "Definizione_Reparto"){
    let objToAppend = {
      id:null,
      manualeId:this.state.records[0].manualeId,
      nome:"Nuovo reparto",
      repartoDefault:false,
      responsabileSicurezzaCognome:null,
      responsabileSicurezzaEmail:null,
      responsabileSicurezzaId:null,
      responsabileSicurezzaNome:null,
      responsabili:[]
    }
    newRowRecords.push(objToAppend);
    this.setState({
      records:newRowRecords,
    },() => {
      this.props.aggiornaReparti(this.state.records)
    })
    /*updateReparti(objToAppend.manualeId,newRowRecords).then(result => {
      this.getRepartiData();
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    });*/
  }
  else if(this.props.mode === "Organigramma"){
    let records = [...this.state.records];
    let objectToAdd = {
      NomeECognome:"",
      Ruolo:"",
      Mansione:"",
      Identificativo:"",
    }
    records.push(objectToAdd);
    this.setState({
      records:records,
    })
    let newOrganigrammaRecord = [...this.state.records];
    newOrganigrammaRecord.push(objectToAdd);
    let dataToPass = [...this.props.sottoParagrafiData];
    let object = JSON.parse(dataToPass[this.state.indexTab].componenti[0].contenuto);
    let objToPassToSave = {
      columns:["Nome e cognome","Ruolo","Mansione","Identificativo"],
      rows:[...object.rows,["","","",""]]
    }
    dataToPass.map(item => {
      if(item.titolo === "Organigramma"){
        item.componenti[0].contenuto = JSON.stringify(objToPassToSave);
      }
    })
    let bodyToPassForSottoparagrafi = {
      id: this.props.idParagrafo,
      manualeId:this.props.idManuale,
      titolo:"Organigramma",
      componenti:dataToPass,
    }
    //this.aggiornaOrganigramma(bodyToPassForSottoparagrafi);
    //console.log(bodyToPassForSottoparagrafi);*/
   /* updateSottoparagrafi(bodyToPassForSottoparagrafi).then(result => {
      newRowRecords.push(objToAppend);
      console.log(newRowRecords);
      this.getColumnsForOrganigramma();
    }).catch(error => {
      /*if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
      console.log(error);
    })*/
  }
  else if(this.props.mode === "Descrizione_Reparto"){
    getReparti(this.props.idManuale,0,100,"id").then(result => {
    let objToAppend = {
      id:null,
      manualeId:this.props.idManuale,
      repartoId:result[0].id,
      nome:"Nuovo Locale",
      dimensione:0,
      indiceOrdinamento:0,
    }
    newRowRecords.push(objToAppend);
    let body = {
      manualeId:this.props.idManuale,
      locali:newRowRecords,
    }
    updateLocaliManuale(body).then(result => {
      this.getLocaliForTabella();
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })


    /*updateLocaliManuale(body).then(result => {
      this.getLocaliForTabella();
    }).catch(error => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    })
  }*/});
  }
  else if(this.props.mode === "Definizione_Procedure_Autocontrollo"){
    this.setState({
      selectProcedureAutocontrolloModalOpen:true,
    })
  }
  else{
    let columnsOfTable = [...this.state.columns];
    let rowsOfTable = [...this.state.records];
    let newObject = {}
    columnsOfTable.map((item,index) => {
      newObject[""+item.id] = "";
    })
    rowsOfTable.push(newObject);
    this.setState({
      records:rowsOfTable,
    })
  }
}else{
  alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato");
}
}
  handleNewRowProcedureAutocontrollo = (tipologia) => {
    if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
    let objToPass = {};
    let sottoparagrafoIdToPass = null;
    this.state.dataToPassForProcedure.map(item => {
      if(item.tipoContenuto === "TABELLA_PROCEDURE"){
        sottoparagrafoIdToPass = item.id;
      }
    })
    if(tipologia === "Scheda"){
    objToPass = {
      tipo:"SCHEDA",
      id:null,
      paragrafoId:this.props.idParagrafo,
      parentComponentId:sottoparagrafoIdToPass,
      titolo:"Nuova Scheda",
    }
    }
    else if(tipologia === "Controllo"){
    objToPass = {
      tipo:"PROCEDURA_HACCP",
      id:null,
      paragrafoId:this.props.idParagrafo,
      parentComponentId:sottoparagrafoIdToPass,
      titolo:"Nuova Procedura Haccp",
    }}
    this.setState({
      selectProcedureAutocontrolloModalOpen:false,
    })
    this.aggiungiDefinizioneProcedureAutocontrollo(objToPass);
    //console.log("Adding in definizione procedure autocontrollo");
  }else{
    alert("Attenzione,operazione non permessa poichè il paragrafo è bloccato")
  }
  }

  

  render(){
    return(
      <div style={styles.mainContainer}>
      <div style={{display:"flex",flexDirection:"column"}}>
      <div style={{display:"flex",flexDirection:"row",alignSelf:"center",width: "100%"}}>
      <TextField InputProps={{ disableUnderline: true,style:{fontSize:30} }} inputProps={{min: 0, style: { textAlign: 'center' }}}  style={{alignSelf:"center",width:"45%",marginLeft:"25%"}} InputLabelProps={{style: {fontSize: 30}}} disabled={!this.state.isTitleEditable} onInput={(e) => {
                this.setState({
                titoloInizialeSottoparagrafo:e.target.value,
              })}} 
              onBlur={() => {
                                  this.props.bringTitleToParent(this.state.titoloInizialeSottoparagrafo,this.props.indexTab);
              }}
              value={this.state.titoloInizialeSottoparagrafo}></TextField>
              <div style={{display:"flex",flexDirection:"row"}}>
              {(!this.props.firstElement || this.props.mode === "Definizione_Procedure_Autocontrollo")  && <IconButton style={{alignContent:"flex-start"}} onClick={() => {
                if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
                this.setState(
                {
                  isTitleEditable:!this.state.isTitleEditable
                }
              )}else{
                alert("Attenzione, operazione non permessa poichè il paragrafo è bloccato")
              }}}><Edit></Edit></IconButton>}
              {!this.props.firstElement && <IconButton onClick={() => {
                this.props.handleRimuoviTabella(this.props.indexTab);
              }}><CloseIcon></CloseIcon></IconButton>}
               {!this.props.firstElement && <IconButton  size="small" onClick = {() => {
                this.props.moveUpward(this.props.indexTab);
              }}><ArrowUpward></ArrowUpward></IconButton>}
               {!this.props.firstElement && <IconButton size="small" onClick = {() => {
                this.props.moveDownward(this.props.indexTab);
              }}><ArrowDownward></ArrowDownward></IconButton>}
              </div>
          </div>
          <div style={{display: "flex",
                        justifyContent: "right",width:"100%"}}>
          <ActionButton  disabled={false}
                        grayVersion={false} label = "Aggiungi" onClick={this.handleNewRow}></ActionButton>
          <Button
                                                            type="button"
                                                            onClick={() => {
                                                              if(!this.state.isBlocked || (localStorage.getItem("superconsulente") === "true" || localStorage.getItem("consulenteId") === "null")){
                                                              this.setState({ editMode: !this.state.editMode })
                                                              }else{
                                                                alert("Attenzione, operazione non permessa perchè il paragrafo è bloccato")
                                                              }}}
                                                            variant="contained"
                                                            size="medium"
                                                            style={{
                                                                color: theme.palette.secondary.main, 
                                                                margin: '10px',
                                                                backgroundColor: this.state.editMode ? 'green' : theme.palette.primary.main
                                                            }}
                                                            
                                                        >
                                                            {this.state.editMode ? 'edit on' : 'edit off'}
                                                        </Button>
          </div>
          </div>
      <div style={{fontSize:25}}>
        {this.state.title}
      </div>
      <div style={{width:"100%"}}>
      {this.props.mode !== "Descrizione_Reparto" ? <ReactTable
                filterable={false}
                resizable={true}
                enableEditing={true}
                showPageSizeOptions={false}
                showPageJump={true}
                defaultPageSize={10}
                style={{
                  height:400,
                  width:"100%"
                }}
                //pages={this.state.pages}
                data={this.state.records}
                columns={this.state.columns}
                //manual // informs React Table that you'll be handling sorting and pagination server-side
                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                previousText='Precedente'
                nextText='Successivo'
                noDataText='Nessun record'
                pageText='Pagina'
                ofText='di'
                rowsText='righe'
                pageJumpText='Vai a pagina'
                rowsSelectorText='righe per pagina'
                /> : (
                <ReactTable
                filterable={false}
                resizable={true}
                enableEditing={true}
                showPageSizeOptions={false}
                showPageJump={true}
                defaultPageSize={10}
                style={{
                  height:400,
                  width:"100%"
                }}
                //pages={this.state.pages}
                data={this.state.records}
                columns={this.state.columns}
                //manual // informs React Table that you'll be handling sorting and pagination server-side
                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                previousText='Precedente'
                nextText='Successivo'
                noDataText='Nessun record'
                pageText='Pagina'
                ofText='di'
                rowsText='righe'
                pageJumpText='Vai a pagina'
                rowsSelectorText='righe per pagina'
                />
                )}
      </div>
      {this.state.openModalResponsabili && <ModalResponsabiliPerReparto
                            open={!this.state.errorDialogVisible && this.state.openModalResponsabili}
                            onClose={this.onCloseModalResponsabili}
                            onSubmit={this.onResponsabiliSelected}
                            reparto={this.state.selectedReparto}
                            responsabiliEsercizio={this.state.responsabiliSicurezza}
      />} 
      {this.state.openModalAttrezzatura && <ModalSelezioneAttrezzatura manualeId={this.props.idManuale}
      titolo={this.props.modalTitle}
      open={this.state.openModalAttrezzatura}
      onClose={this.onCloseModalAttrezzatura}
      handleInvalidToken={this.props.handleInvalidToken}
      handleError={this.props.handleError}
      selectedLocale={this.state.selectedLocale}
      />}
      {this.state.selectProcedureAutocontrolloModalOpen && <ModalSelezioneModalitaAutocontrollo
        open={this.state.selectProcedureAutocontrolloModalOpen}
        onClose={this.onCloseModalSelezioneModalitaAutocontrollo}
        handleNewRow={this.handleNewRowProcedureAutocontrollo}
      />}
      </div>
    )
  }
}