import React from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import theme from '../../../theme.js';

import PropTypes from 'prop-types';
import { getNumeroPartiManuale, getPartiManuale } from "../../../utils/api/parti_api.js";
import { getCapitoliCount } from "../../../utils/api/capitoli_api.js";
import { Settings } from "@material-ui/icons";
import { setOpenParti } from "../../../utils/storage.js";

export default function GoToPaginaCostruzioneManuale(props) {
  let history = useHistory();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      style={{
        color: theme.palette.secondary.main,
        backgroundColor: props.disabled ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
        borderRadius: '30px',
        margin: '10px',
      }}
      onClick={() => { 
        setOpenParti(JSON.stringify({
          parti:[],
          capitoli:[],
          paragrafi:[]
        }))
        getCapitoliCount(props.manualeId).then((result) => {
          if(result > 0){
            history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale/true/"+props.revisioneId);
          }
          else{
            history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale/false/"+props.revisioneId)
          }
        }).catch((error) => {
          console.log(error);
        })
        //history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale") 
      }}
    >
      <Settings></Settings>
    </Button>
  );
}

GoToPaginaCostruzioneManuale.propTypes = {
  manualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}