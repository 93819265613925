import React from "react";
import {
  getSingoloSottoparagrafo,
  getSottoparagrafoIntroduttivo,
  putSingoloSottoparagrafo,
  putSottoparagrafoIntroduttivo,
} from "../../../utils/api/paragrafi_api";
import { clearToken } from "../../../utils/storage";
import ErrorDialog from "../../../components/ErrorDialog";
import ActionButton from "../../../components/ActionButton";
import GoBackButton from "../components/GoBackButton";
import CustomTableForManualeForParti from "./CustomTableForManualeParti";
import NewSottoparagrafoTestoEImmagineForParti from "./NewSottoparagrafoTestoEImmagineForParti";
import {
  getLocaliManuale,
  updateLocaliManuale,
} from "../../../utils/api/locali_e_attrezzature_api";
import { getReparti, updateReparti } from "../../../utils/api/reparti";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { Button } from "@material-ui/core";
import theme from "../../../theme";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import PropTypes from "prop-types";
import BoldButton from "../../../components/BoldButton";

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
    margin: "auto",
  },
  typo: {
    align: "center",
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  buttonContainer: {},
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  sectioncontainer: {
    // border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    //marginTop: '10px',
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  rowcontainer: {
    flexDirection: "row",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  periodo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tableModalText: {
    marginTop: "2%",
    marginBottom: "2%",
  },
  notSelectedTab: {
    display: "flex",
    flexDirection: "row",
  },
  selectedTab: {
    display: "flex",
    flexDirection: "row",
  },
};

export default class ModificaSottoparagrafoManualeConParti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      isDialogOpen: false,
      errorDialogVisible: false,
      manualeId: this.props.manualeId,
      loading: true,
      sottoparagrafoData: {},
      tableMode: "",
      isModello: this.props.mode === "true",
      tipologia: "",
      colonneTabellaCustom: null,
      contenutoTabellaCustom: null,
      cursorPosition: 0,
      selectedCellX: null,
      selectedCellY: null,
      updatedReparti: [],
      updatedLocali: [],
    };
  }
  componentDidMount = () => {
    if (this.props.sottoparagrafo === "null") {
      getSottoparagrafoIntroduttivo(this.props.paragrafo)
        .then((result) => {
          this.setState({
            sottoparagrafoData: result,
            loading: false,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    } else {
      getSingoloSottoparagrafo(this.props.sottoparagrafo)
        .then((result) => {
          let tipologia = result.tipoContenuto;
          let colonneTabellaCustom = null;
          let contenutoTabellaCustom = null;
          if (tipologia === null) {
            tipologia = result.componenti[0].tipo;
          }
          if (tipologia === "TABELLA" && result.componenti[0].organigramma) {
            tipologia = "Organigramma";
          } else if (tipologia === "TABELLA_PERSONALIZZATA") {
            colonneTabellaCustom = result.componenti[0].definizioniColonne;
            contenutoTabellaCustom = result.componenti[0].contenuto;
            if (colonneTabellaCustom !== null) {
              colonneTabellaCustom = JSON.parse(
                "[" + colonneTabellaCustom + "]"
              )[0];
            }
          } else if (tipologia === "TABELLA_REPARTI") {
            getReparti(this.props.manualeId, 0, 100, "id")
              .then((result) => {
                this.setState({
                  updatedReparti: result,
                });
              })
              .catch((error) => {
                if (error.status === 403) {
                  this.handleInvalidToken();
                } else {
                  this.handleError(true, error.message);
                }
              });
          } else if (tipologia === "TABELLA_LOCALI") {
            getLocaliManuale(
              this.props.manualeId,
              0,
              100000,
              "indiceOrdinamento"
            )
              .then((result) => {
                this.setState({
                  updatedLocali: result,
                });
              })
              .catch((error) => {
                if (error.status === 403) {
                  this.handleInvalidToken();
                } else {
                  this.handleError(true, error.message);
                }
              });
          }
          this.setState({
            sottoparagrafoData: result,
            loading: false,
            tipologia: tipologia,
            colonneTabellaCustom: colonneTabellaCustom,
            contenutoTabellaCustom: contenutoTabellaCustom,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    }
  };

  bringTitleToParent = (title) => {
    let data = this.state.sottoparagrafoData;
    if (this.props.sottoparagrafo === "null") {
      data.sottoparagrafoIntroduttivo.titolo = title;
    } else {
      data.titolo = title;
    }
    this.setState({
      sottoparagrafoData: data,
    });
  };

  bringDataToParent = (data) => {
    let data1 = this.state.sottoparagrafoData;
    if (this.props.sottoparagrafo === "null") {
      data1.sottoparagrafoIntroduttivo.componenti[0].contenuto = data;
    } else {
      data1.componenti[0].contenuto = data;
    }
    this.setState({
      sottoparagrafoData: data1,
    });
  };

  bringDataToParentAutocontrollo = (data, index) => {
    let newSubparagraphArray = this.state.sottoparagrafoData;
    newSubparagraphArray.componenti = data.componenti;
    this.setState({
      sottoparagrafoData: newSubparagraphArray,
    });
  };

  bringDataToParentAutocontrolloAfterExchange = (data, index) => {
    let newSubparagraphArray = this.state.sottoparagrafoData;
    newSubparagraphArray.componenti = data;
    this.setState({
      sottoparagrafoData: newSubparagraphArray,
    });
  };

  bringTextToParentAutocontrollo = (data, index, indexElement) => {
    let newSubparagraphArray = this.state.sottoparagrafoData;
    newSubparagraphArray.componenti = data.componenti;
    this.setState({
      sottoparagrafoData: newSubparagraphArray,
    });
  };

  bringImageToParent = (image) => {
    let data = this.state.sottoparagrafoData;
    if (this.props.sottoparagrafo === "null") {
      data.sottoparagrafoIntroduttivo.immagini.push(image);
    } else {
      data.immagini.push(image);
    }
    this.setState({
      sottoparagrafoData: data,
    });
  };

  handleEditChange = () => {
    this.setState({
      edit: true,
    });
  };

  deleteImageFromSottoparagrafo = (immagini, index) => {
    let newSubparagraphArray = this.state.sottoparagrafoData;
    if (this.props.sottoparagrafo === "null") {
      newSubparagraphArray.sottoparagrafoIntroduttivo.immagini = immagini;
    } else {
      newSubparagraphArray.immagini = immagini;
    }
    this.setState({
      sottoParagrafoData: newSubparagraphArray,
    });
  };

  setLocaliToTable = (locali) => {
    getLocaliManuale(this.props.manualeId, 0, 100000, "indiceOrdinamento")
      .then((result) => {
        this.setState({
          updatedLocali: result,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  aggiornaReparti = (repartiData, index) => {
    this.setState(
      {
        updatedReparti: repartiData,
        edit: true,
      },
      () => {}
    );
  };

  launchRepartiUpdate = (reparti) => {
    updateReparti(this.state.manualeId, reparti)
      .then((result) => {
        //window.location.reload(true);
        this.handleSuccess(true, "Sottoparagrafo modificato con successo");
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  aggiornaLocali = (records) => {
    let recordsToChange = [...records];
    /*recordsToChange.map(item => {
            delete item['nomeReparto']
            delete item['repartiToSelect']
          })*/
    this.setState(
      {
        updatedLocali: recordsToChange,
        edit: true,
      },
      () => {}
    );
  };

  launchLocaliUpdate = (locali) => {
    let recordsToSend = [...locali];
    recordsToSend.map((item) => {
      //delete item["nomeReparto"];
      //delete item["repartiToSelect"];
    });
    let body = {
      manualeId: this.state.manualeId,
      locali: recordsToSend,
    };
    updateLocaliManuale(body)
      .then((result) => {
        this.handleSuccess(true, "Sottoparagrafo modificato con successo");
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorTitle: "Errore",
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  handleSuccess = (showModal, successMessage) => {
    this.setState({
      errorTitle: "Successo",
      errorDialogVisible: showModal,
      errorDialogMessage: successMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  insertBoldText = () => {
    const { cursorPosition, sottoparagrafoData } = this.state;
    if (cursorPosition === undefined) return;

    let stateToUpdate = JSON.parse(JSON.stringify(sottoparagrafoData)); // deep copy

    if (this.props.sottoparagrafo === "null") {
      let stringToProcess =
        stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto;
      let output = [
        stringToProcess.slice(0, cursorPosition) +
          "<b> inserire testo in grassetto </b>" +
          stringToProcess.slice(cursorPosition),
      ].join("");
      stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto = output;
    } else {
      let stringToProcess = stateToUpdate.componenti[0].contenuto;
      let output = [
        stringToProcess.slice(0, cursorPosition) +
          "<b> inserire testo in grassetto </b>" +
          stringToProcess.slice(cursorPosition),
      ].join("");
      stateToUpdate.componenti[0].contenuto = output;
    }

    this.setState({
      sottoparagrafoData: stateToUpdate,
    });
  };

  insertCheckedCheckbox = () => {
    if (this.state.cursorPosition !== undefined) {
      if (this.props.sottoparagrafo === "null") {
        let stateToUpdate = JSON.parse(
          JSON.stringify(this.state.sottoparagrafoData)
        );
        let stringToProcess =
          stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto;
        let output = [
          stringToProcess.slice(0, this.state.cursorPosition) +
            "- [x]" +
            stringToProcess.slice(this.state.cursorPosition),
        ].join("");
        stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto =
          output;
        this.setState({
          sottoparagrafoData: stateToUpdate,
        });
      } else {
        let stateToUpdate = JSON.parse(
          JSON.stringify(this.state.sottoparagrafoData)
        );
        let stringToProcess = stateToUpdate.componenti[0].contenuto;
        let output = [
          stringToProcess.slice(0, this.state.cursorPosition) +
            "- [x]" +
            stringToProcess.slice(this.state.cursorPosition),
        ].join("");
        stateToUpdate.componenti[0].contenuto = output;
        this.setState({
          sottoparagrafoData: stateToUpdate,
        });
      }
    }
  };

  insertEmptyCheckbox = () => {
    if (this.state.cursorPosition !== undefined) {
      if (this.props.sottoparagrafo === "null") {
        let stateToUpdate = JSON.parse(
          JSON.stringify(this.state.sottoparagrafoData)
        );
        let stringToProcess =
          stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto;
        let output = [
          stringToProcess.slice(0, this.state.cursorPosition) +
            "- []" +
            stringToProcess.slice(this.state.cursorPosition),
        ].join("");
        stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto =
          output;
        this.setState({
          sottoparagrafoData: stateToUpdate,
        });
      } else {
        let stateToUpdate = JSON.parse(
          JSON.stringify(this.state.sottoparagrafoData)
        );
        let stringToProcess = stateToUpdate.componenti[0].contenuto;
        let output = [
          stringToProcess.slice(0, this.state.cursorPosition) +
            "- []" +
            stringToProcess.slice(this.state.cursorPosition),
        ].join("");
        stateToUpdate.componenti[0].contenuto = output;
        this.setState({
          sottoparagrafoData: stateToUpdate,
        });
      }
    }
  };

  setCursorPosition = (position) => {
    this.setState(
      {
        cursorPosition: position,
      },
      () => {}
    );
  };

  aggiornaTabellaCustomHandler = (recordData) => {
    this.setState({
      edit: true,
      selectedCellX:
        recordData.componenti[0].contenuto === "[]"
          ? null
          : this.state.selectedCellX,
      selectedCellY:
        recordData.componenti[0].contenuto === "[]"
          ? null
          : this.state.selectedCellY,
    });
  };

  insertCheckedCheckboxInsideTable = () => {
    if (
      this.state.cursorPosition !== undefined &&
      this.state.selectedCellX !== null &&
      this.state.selectedCellY !== null
    ) {
      /*if(this.props.sottoparagrafo === "null"){
      let stateToUpdate = JSON.parse(JSON.stringify(this.state.sottoparagrafoData));
        let stringToProcess = stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto;
        let output = [stringToProcess.slice(0, this.state.cursorPosition)+"- [x]"+stringToProcess.slice(this.state.cursorPosition)].join("");
        stateToUpdate.sottoparagrafoIntroduttivo.componenti[0].contenuto = output;
        this.setState({
          sottoparagrafoData:stateToUpdate
      })
      }else{*/

      let stateToUpdate = JSON.parse(
        JSON.stringify(this.state.sottoparagrafoData)
      );
      let stringToProcess = JSON.parse(stateToUpdate.componenti[0].contenuto)[
        this.state.selectedCellY
      ][this.state.selectedCellX];
      let output = [
        stringToProcess.slice(0, this.state.cursorPosition) +
          "- [x]" +
          stringToProcess.slice(this.state.cursorPosition),
      ].join("");
      //stateToUpdate.componenti[0].contenuto = output;
      let jsonData = JSON.parse(stateToUpdate.componenti[0].contenuto);
      jsonData[this.state.selectedCellY][this.state.selectedCellX] = output;
      stateToUpdate.componenti[0].contenuto = jsonData;
      this.setState({
        cursorPosition: this.state.cursorPosition + 6,
        //sottoparagrafoData:stateToUpdate,
        contenutoTabellaCustom: JSON.stringify(
          stateToUpdate.componenti[0].contenuto
        ),
      });
    }
  };

  insertEmptyCheckboxInsideTable = () => {
    if (this.state.cursorPosition !== undefined) {
      let stateToUpdate = JSON.parse(
        JSON.stringify(this.state.sottoparagrafoData)
      );
      let stringToProcess = JSON.parse(stateToUpdate.componenti[0].contenuto)[
        this.state.selectedCellY
      ][this.state.selectedCellX];
      let output = [
        stringToProcess.slice(0, this.state.cursorPosition) +
          "- []" +
          stringToProcess.slice(this.state.cursorPosition),
      ].join("");
      //stateToUpdate.componenti[0].contenuto = output;
      let jsonData = JSON.parse(stateToUpdate.componenti[0].contenuto);
      jsonData[this.state.selectedCellY][this.state.selectedCellX] = output;
      stateToUpdate.componenti[0].contenuto = jsonData;
      this.setState({
        cursorPosition: this.state.cursorPosition + 6,
        //sottoparagrafoData:stateToUpdate,
        contenutoTabellaCustom: JSON.stringify(
          stateToUpdate.componenti[0].contenuto
        ),
      });
    }
  };
  checkboxCustomButtonUpdateHandler = () => {
    this.setState({
      cursorPosition: 0,
      selectedCellX: null,
      selectedCellY: null,
    });
  };

  setSelectedCell = (cellX, cellY) => {
    this.setState(
      {
        selectedCellX: cellX,
        selectedCellY: cellY,
      },
      () => {}
    );
  };

  handleSalvataggioSottoparagrafo = () => {
    let data = this.state.sottoparagrafoData;
    if (this.props.sottoparagrafo === "null") {
      putSottoparagrafoIntroduttivo(data)
        .then((result) => {
          this.handleSuccess(true, "Sottoparagrafo modificato con successo");
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        });
    } else {
      if (this.state.tipologia === "TABELLA_REPARTI") {
        putSingoloSottoparagrafo(data)
          .then((result) => {
            this.launchRepartiUpdate(this.state.updatedReparti);
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      } else if (this.state.tipologia === "TABELLA_LOCALI") {
        putSingoloSottoparagrafo(data)
          .then((result) => {
            this.launchLocaliUpdate(this.state.updatedLocali);
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          });
      } else {
        if (data.componenti[0].organigramma) {
          let jsonData = JSON.parse(data.componenti[0].contenuto);
          let canSend = true;
          let isRuolo = false;
          let isReparto = false;
          jsonData.rows.map((item) => {
            if (item[1] === "") {
              canSend = false;
              isRuolo = true;
            }
            if (item[2] === "") {
              canSend = false;
              isReparto = true;
            }
          });
          if (canSend) {
            putSingoloSottoparagrafo(data)
              .then((result) => {
                this.handleSuccess(
                  true,
                  "Sottoparagrafo modificato con successo"
                );
              })
              .catch((error) => {
                if (error.status === 403) {
                  this.handleInvalidToken();
                } else {
                  this.handleError(true, error.message);
                }
              });
          } else {
            let errorMessage;
            if (isRuolo && isReparto) {
              errorMessage = "Inserire un ruolo e un reparto per ogni riga";
            } else if (isRuolo) {
              errorMessage = "Inserire un ruolo per ogni riga";
            } else {
              errorMessage = "Inserire un reparto per ogni riga";
            }
            this.setState({
              errorDialogVisible: true,
              errorDialogTitle: "Errore",
              errorDialogMessage: errorMessage,
            });
          }
        } else {
          putSingoloSottoparagrafo(data)
            .then((result) => {
              this.handleSuccess(
                true,
                "Sottoparagrafo modificato con successo"
              );
            })
            .catch((error) => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.handleError(true, error.message);
              }
            });
        }
      }
    }
  };

  render() {
    return this.props.sottoparagrafo !== "null" ? (
      <div style={styles.root}>
        {!this.state.loading ? (
          <div style={styles.mainContainer}>
            {this.state.tipologia === "BLOCCO_TESTO" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: 10,
                  }}
                >
                  <Button
                    startIcon={<CheckBox />}
                    type="button"
                    onClick={() => {
                      this.insertCheckedCheckbox();
                    }}
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      marginBottom: 30,
                      borderRadius: 30,
                      marginTop: 10,
                      backgroundColor: theme.palette.cndColors.yellow,
                    }}
                  >
                    INSERISCI CHECKBOX PIENA
                  </Button>
                  <Button
                    startIcon={<CheckBoxOutlineBlank />}
                    type="button"
                    onClick={() => {
                      this.insertEmptyCheckbox();
                    }}
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      marginBottom: 30,
                      marginLeft: 10,
                      marginTop: 10,
                      borderRadius: 30,
                      backgroundColor: theme.palette.cndColors.yellow,
                    }}
                  >
                    INSERISCI CHECKBOX VUOTA
                  </Button>
                  <BoldButton onClick={this.insertBoldText} />
                </div>
                <NewSottoparagrafoTestoEImmagineForParti
                  isIntroduttivo={false}
                  titoloInizialeSottoparagrafo={
                    this.state.sottoparagrafoData.titolo
                  }
                  testoSottoParagrafo={
                    this.state.sottoparagrafoData.componenti[0].contenuto
                  }
                  bringTitleToParent={this.bringTitleToParent}
                  bringDataToParent={this.bringDataToParent}
                  bringImageToParent={this.bringImageToParent}
                  deleteImage={this.deleteImageFromSottoparagrafo}
                  immaginiParagrafo={this.state.sottoparagrafoData.immagini}
                  isParagrafoBlocked={this.props.isParagrafoBlocked}
                  cursorPosition={this.state.cursorPosition}
                  setCursorPosition={this.setCursorPosition}
                  idImmagine={
                    this.state.sottoparagrafoData.componenti[0].numeroComponente
                  }
                  borderWidth={"100%"}
                  componentHeight={"50%"}
                  componentWidth={"50%"}
                  photoWidth={"100%"}
                  photoHeight={"100%"}
                  handleEditChange={this.handleEditChange}
                  sottoParagrafiData={this.state.sottoparagrafoData}
                  indexValue={
                    this.state.sottoparagrafoData.componenti[0].numeroComponente
                  }
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  handleSalvataggioSottoparagrafo={
                    this.handleSalvataggioSottoparagrafo
                  }
                  isSottoparagrafo={this.props.sottoparagrafo !== "null"}
                />
              </>
            ) : this.state.tipologia === "TABELLA_PERSONALIZZATA" ? (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    startIcon={<CheckBox />}
                    type="button"
                    onClick={() => {
                      this.insertCheckedCheckboxInsideTable();
                    }}
                    disabled={
                      this.state.selectedCellX === null ||
                      this.state.selectedCellY === null
                    }
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      marginBottom: 30,
                      borderRadius: 30,
                      marginTop: 10,
                      backgroundColor:
                        this.state.selectedCellX === null ||
                        this.state.selectedCellY === null
                          ? theme.palette.disabled.main
                          : theme.palette.cndColors.yellow,
                    }}
                  >
                    INSERISCI CHECKBOX PIENA
                  </Button>
                  <Button
                    startIcon={<CheckBoxOutlineBlank />}
                    type="button"
                    onClick={() => {
                      this.insertEmptyCheckboxInsideTable();
                    }}
                    disabled={
                      this.state.selectedCellX === null ||
                      this.state.selectedCellY === null
                    }
                    //onClick={() => this.setState({ editMode: !this.state.editMode })}
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      marginBottom: 30,
                      marginLeft: 10,
                      marginTop: 10,
                      borderRadius: 30,
                      backgroundColor:
                        this.state.selectedCellX === null ||
                        this.state.selectedCellY === null
                          ? theme.palette.disabled.main
                          : theme.palette.cndColors.yellow,
                    }}
                  >
                    INSERISCI CHECKBOX VUOTA
                  </Button>
                </div>
                <CustomTableForManualeForParti
                  cursorPosition={this.state.cursorPosition}
                  setCursorPosition={this.setCursorPosition}
                  selectedCellX={this.state.selectedCellX}
                  selectedCellY={this.state.selectedCellY}
                  setSelectedCell={this.setSelectedCell}
                  isParagrafoBlocked={this.props.isParagrafoBlocked}
                  sottoParagrafoData={this.state.sottoparagrafoData}
                  titoloInizialeSottoparagrafo={
                    this.state.sottoparagrafoData.titolo
                  }
                  bringTitleToParent={this.bringTitleToParent}
                  titolo={this.state.sottoparagrafoData.titolo}
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  mode={"Custom"}
                  kind={this.state.isModello}
                  firstElement={true}
                  idManuale={this.props.manuale}
                  idParagrafo={this.props.paragrafo}
                  contenuto={this.state.contenutoTabellaCustom}
                  columns={this.state.colonneTabellaCustom}
                  updateCustomTable={this.aggiornaTabellaCustomHandler}
                  checkboxCustomButtonUpdate={
                    this.checkboxCustomButtonUpdateHandler
                  }
                />
              </>
            ) : this.state.tipologia === "TABELLA_REPARTI" ? (
              <CustomTableForManualeForParti
                isParagrafoBlocked={this.props.isParagrafoBlocked}
                bringTitleToParent={this.bringTitleToParent}
                sottoParagrafoData={this.state.sottoparagrafoData}
                titoloInizialeSottoparagrafo={
                  this.state.sottoparagrafoData.titolo
                }
                titolo={this.state.sottoparagrafoData.titolo}
                handleError={this.handleError}
                handleInvalidToken={this.handleInvalidToken}
                mode={"Definizione_Reparto"}
                kind={this.state.isModello}
                firstElement={true}
                idManuale={this.state.manualeId}
                idParagrafo={this.props.paragrafo}
                idSottoparagrafo={this.props.sottoparagrafo}
                changeEditMode={this.handleEditChange}
                aggiornaReparti={this.aggiornaReparti}
              />
            ) : this.state.tipologia === "Organigramma" ? (
              <CustomTableForManualeForParti
                isParagrafoBlocked={this.props.isParagrafoBlocked}
                bringTitleToParent={this.bringTitleToParent}
                sottoParagrafoData={this.state.sottoparagrafoData}
                titoloInizialeSottoparagrafo={
                  this.state.sottoparagrafoData.titolo
                }
                titolo={this.state.sottoparagrafoData.titolo}
                handleError={this.handleError}
                handleInvalidToken={this.handleInvalidToken}
                mode={"Organigramma"}
                kind={this.state.isModello}
                firstElement={true}
                idManuale={this.state.manualeId}
                idParagrafo={this.props.paragrafo}
                idSottoparagrafo={this.props.sottoparagrafo}
                changeEditMode={this.handleEditChange}
              />
            ) : this.state.tipologia === "TABELLA_LOCALI" ? (
              <CustomTableForManualeForParti
                key={this.state.sottoparagrafoData.numeroComponente}
                mode={"Descrizione_Reparto"}
                aggiornaLocali={this.aggiornaLocali}
                handleError={this.handleError}
                firstElement={true}
                handleInvalidToken={this.handleInvalidToken}
                bringTitleToParent={this.bringTitleToParent}
                titoloInizialeSottoparagrafo={
                  this.state.sottoparagrafoData.titolo
                }
                setLocaliToTable={this.setLocaliToTable}
                idManuale={this.state.manualeId}
                modalTitle={this.state.sottoparagrafoData.titolo}
                numberOfRows={this.state.numberOfLines}
              />
            ) : this.state.tipologia === "TABELLA_PROCEDURE" ? (
              <CustomTableForManualeForParti
                key={this.state.sottoparagrafoData.numeroComponente}
                indexTab={this.state.sottoparagrafoData.numeroComponente}
                bringTitleToParent={this.bringTitleToParent}
                idParagrafo={this.props.sottoparagrafo}
                mode={"Definizione_Procedure_Autocontrollo"}
                bringDataAfterExchange={
                  this.bringDataToParentAutocontrolloAfterExchange
                }
                bringDataToParent={this.bringDataToParentAutocontrollo}
                bringTextToParent={this.bringTextToParentAutocontrollo}
                sottoParagrafoData={this.state.sottoparagrafoData}
                handleError={this.handleError}
                firstElement={true}
                handleInvalidToken={this.handleInvalidToken}
                rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                titoloInizialeSottoparagrafo={
                  this.state.sottoparagrafoData.titolo
                }
                idManuale={this.state.manualeId}
                modalTitle={this.state.sottoparagrafoData.titolo}
              />
            ) : null}
            {this.state.tipologia === "BLOCCO_TESTO" ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <GoBackButton label="Indietro" />
                <ActionButton
                  label={
                    this.props.sottoparagrafo !== "null"
                      ? "Salva Sottoparagrafo"
                      : "Salva Paragrafo"
                  }
                  disabled={false}
                  grayVersion={false}
                  onClick={() => {
                    this.handleSalvataggioSottoparagrafo();
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div style={styles.mainContainer}>
            <SpinnerComponent size={36} />
          </div>
        )}
        <ErrorDialog
          title={this.state.errorTitle}
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    ) : (
      <div style={styles.root}>
        {!this.state.loading ? (
          <div style={styles.mainContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: 10,
              }}
            >
              <Button
                startIcon={<CheckBox />}
                type="button"
                onClick={() => {
                  this.insertCheckedCheckbox();
                }}
                variant="contained"
                style={{
                  color: theme.palette.secondary.main,
                  marginBottom: 30,
                  marginTop: 10,
                  borderRadius: 30,
                  backgroundColor: theme.palette.cndColors.yellow,
                }}
              >
                INSERISCI CHECKBOX PIENA
              </Button>
              <Button
                type="button"
                startIcon={<CheckBoxOutlineBlank />}
                onClick={() => {
                  this.insertEmptyCheckbox();
                }}
                //onClick={() => this.setState({ editMode: !this.state.editMode })}
                variant="contained"
                style={{
                  color: theme.palette.secondary.main,
                  marginBottom: 30,
                  marginTop: 10,
                  borderRadius: 30,
                  backgroundColor: theme.palette.cndColors.yellow,
                }}
              >
                INSERISCI CHECKBOX VUOTA
              </Button>
              <BoldButton onClick={this.insertBoldText} />
            </div>
            <NewSottoparagrafoTestoEImmagineForParti
              isIntroduttivo={true}
              titoloInizialeSottoparagrafo={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo.titolo
              }
              testoSottoParagrafo={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo
                  .componenti[0].contenuto
              }
              bringTitleToParent={this.bringTitleToParent}
              bringDataToParent={this.bringDataToParent}
              bringImageToParent={this.bringImageToParent}
              deleteImage={this.deleteImageFromSottoparagrafo}
              immaginiParagrafo={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo
                  .immagini
              }
              isParagrafoBlocked={this.props.isParagrafoBlocked}
              idImmagine={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo
                  .componenti[0].numeroComponente
              }
              borderWidth={"100%"}
              componentHeight={"50%"}
              componentWidth={"50%"}
              cursorPosition={this.state.cursorPosition}
              setCursorPosition={this.setCursorPosition}
              photoWidth={"100%"}
              photoHeight={"100%"}
              handleEditChange={this.handleEditChange}
              sottoParagrafiData={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo
              }
              indexValue={
                this.state.sottoparagrafoData.sottoparagrafoIntroduttivo
                  .componenti[0].numeroComponente
              }
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              handleSalvataggioSottoparagrafo={
                this.handleSalvataggioSottoparagrafo
              }
              isSottoparagrafo={this.props.sottoparagrafo !== "null"}
            />
          </div>
        ) : (
          <div style={styles.mainContainer}>
            <SpinnerComponent size={36} />
          </div>
        )}
        <ErrorDialog
          title={this.state.errorTitle}
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}
