import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import ResetPswSuccessPage from "./scenes/resetpsw/ResetPswSuccessPage";
import AppMainComponent from "./AppMainComponent";

import "./App.css";
import Footer from "./components/Footer";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <Switch>
            <Route
              path="/reset_password_success"
              component={ResetPswSuccessPage}
            />
            <Route path="/" render={() => <AppMainComponent />} />
          </Switch>
          <Footer />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}
