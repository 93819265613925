import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import PropTypes from 'prop-types';
import theme from '../../../theme.js';
import { IconButton } from "@material-ui/core";

export default function NewRecordButton(props) {

  let history = useHistory();
  const match = useRouteMatch();
  return (
    <IconButton
      disabled={props.disabled}
      //startIcon={<AddIcon />}
      size="medium"
      style={{ 
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.cndColors.yellow ,
        borderRadius:30,
      }}
      onClick={() => { history.push(`${match.url}/nuovo`) }}
    >
    <AddIcon />
    </IconButton>
  );

}

NewRecordButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string
}