import { Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';
import theme from '../../theme';
import { salvataggioCodiceValidazione } from '../../utils/api/gestione_validazioni_api';
import ErrorDialog from '../../components/ErrorDialog';
import { withRouter } from 'react-router-dom';
const styles = {
    maincontainer: {
        padding: '0px 4px 10px 4px',
        maxWidth: '700px',
        minHeight: '480px'
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        paddingTop: '100px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414'
    },
    root: {
        textAlign: 'center',
        color: 'black',
        padding: '20px 10px 10px 10px',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '30px',
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        justifyContent: 'center',
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px'
    },
}

export const Component = withRouter(({ history, location }) =>{
});

class CodiceValidazioneForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            codiceValidazione:"",
            codiceValidazioneConferma:"",
            isEqualCodeError:false,
            isInsertedCodeError:false,
            isModalOpen:false,
            modalTitle:"",
            modalMessage:""
        }
    }
    onSubmit = (event) => {
        event.preventDefault();
        if(this.state.codiceValidazione !== "" && this.state.codiceValidazioneConferma !== ""){
        if(this.state.codiceValidazione === this.state.codiceValidazioneConferma){
            let body = {
                nuovaPassword: this.state.codiceValidazione,
                confermaNuovaPassword: this.state.codiceValidazioneConferma
            }
            this.setState({
                isEqualCodeError:false,
                isInsertedCodeError:false,
            })
            salvataggioCodiceValidazione(this.props.idEsercizio,body).then((response) => {
                if(response.status === 200){
                   this.setState({
                    isModalOpen:true,
                    modalTitle:"Successo",
                    modalMessage:"Passcode modificato con successo",
                   })
                }
            }).catch((error) => {
                this.setState({
                    isModalOpen:true,
                    modalTitle:"Errore",
                    modalMessage:error.status === 500 ? "Servizio non disponibile, riprovare più tardi." : error.status === 404 ? "Richiesta non valida: il punto vendita indicato non esiste" : error.status === 400 ? "Richiesta non valida: i codici inseriti non coincidono" : "Servizio non disponibile, riprovare più tardi.",
                })
            })
        }else{
            this.setState({
                isInsertedCodeError:false,
                isEqualCodeError:true,
            })
        }
        }else{
            this.setState({
                isInsertedCodeError:true,
                isEqualCodeError:false,
            })
        }
    }

    render(){
        return(
            <Fragment>
                <div style={styles.root}>
                    <Typography variant="h4">Reset Passcode</Typography>
                    <Paper style={styles.paper} >
                    <Container id="maincontainer" style={styles.maincontainer} >
                        <form autoComplete="off" onSubmit={this.onSubmit}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                            <Grid container style={styles.textfieldscontainer}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography>Inserire il nuovo passcode</Typography>
                                {(this.state.isEqualCodeError && !this.state.isInsertedCodeError) && <Typography style={{color:"red"}}>Attenzione, i passcode devono essere identici</Typography>}
                                {(!this.state.isEqualCodeError && this.state.isInsertedCodeError) && <Typography style={{color:"red"}}>Attenzione, compilare entrambi i campi</Typography>}   
                                    <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                    <TextField
                                    label="Passcode"
                                    name='code'
                                    value={this.state.codiceValidazione}
                                    onInput={e => this.setState({ codiceValidazione: e.target.value })}
                                    InputProps={{
                                        
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Conferma Passcode"
                                    name='confirm'
                                    value={this.state.codiceValidazioneConferma}
                                    onInput={e => this.setState({ codiceValidazioneConferma: e.target.value })}
                                    InputProps={{
                                        
                                    }}
                                    style={styles.textfield}
                                />
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                <Button
                                    type="button"
                                    onClick={this.onSubmit}
                                    variant="contained"
                                    size="medium"
                                    disabled={false}
                                    style={{
                                        color: theme.palette.secondary.main,
                                        margin: '10px',
                                        borderRadius:30,
                                        backgroundColor:theme.palette.cndColors.yellow,
                                    }}
                                >
                                Salva
                            </Button>
                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </form>
                    </Container>
                    </Paper>
                </div>
                <ErrorDialog
                title={this.state.modalTitle}
                message={this.state.modalMessage}
                onCloseButtonClicked={() => {
                    this.setState({
                        isModalOpen:false,
                    },() => {
                        if(this.state.modalTitle !== "Errore"){
                            const { history } = this.props;
                            history.push("/");
                        }
                    })
                }}
                open={this.state.isModalOpen}
                ></ErrorDialog>
            </Fragment>
        )
    }
}
export default withRouter(CodiceValidazioneForm);