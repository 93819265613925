import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RestoreIcon from '@material-ui/icons/Restore';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import { CancelRounded, Search } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import {ReactComponent as CreaManualeDa} from "../../../CreaManualeDa.svg";
import {ReactComponent as Scarica} from "../../../Scarica.svg";

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import LoadingOverlay from 'react-loading-overlay';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import ActionButton from '../../../components/ActionButton';
import ModalSelezioneConsulente from '../components/ModalSelezioneConsulente';
import ModalSelezioneEsercizioCommerciale from '../components/ModalSelezioneEsercizioCommerciale';
import DettaglioManualeButton from '../components/DettaglioManualeButton';

import { clearToken, setChapterIndex, setOpenParti, setParagraphIndex } from '../../../utils/storage';
import { getRevisioniManuali, cancellaRevisione, getStatoFile } from '../../../utils/api/revisioni_manuali_api';
import { getGruppi } from '../../../utils/api/gruppi_api';
import { getCapitoliCount } from '../../../utils/api/capitoli_api';
import { creaManualePersonalizzato }  from '../../../utils/api/modelli_and_manuali_api';

import { withRouter } from 'react-router-dom';

import theme from '../../../theme.js';

import moment from 'moment';
import 'moment/locale/it';

import PropTypes from 'prop-types';
import ModalSelezioneModalita from '../components/ModalSelezioneModalita';
import ModalSelezioneGruppo from '../components/ModalSelezioneGruppo';
import LoadingModal from '../components/LoadingModal';
import { InputAdornment, InputBase, TextField, styled } from '@material-ui/core';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

const dateFormat = "DD-MM-YYYY HH:mm";

export const Component = withRouter(({ history, location }) =>{
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616,#ff4600) border-box;",
    borderRadius: "50em",
    border: "1px solid transparent",
    padding: "1em 2em 1em 1em",
  },
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
  }
}));

class ManualiPersonalizzatiTable extends React.Component {
  intervalId = 0;
  constructor(props) {
    super(props);
    this.state = {
      manuali: [],
      gruppoId: null,
      selectedGruppoId:null,
      consulenteId: null,
      esercizioId:null,
      gruppi: [],
      loading: true,
      pdfloading: false,
      errorDialogVisible: false,
      errorDialogMessage: '',
      manualeBaseId: null,
      showHiddenManuali: false,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: false,
      showModalSelezioneModalita:false,
      showModalSelezioneGruppo: false,
      origin:"Manuale",
      loadingModal:false,
      readyToDownload:false,
    };
  }

  componentDidMount() {
    let gruppoId = this.props.gruppoId;
    this.setState({
      gruppoId,
    });
    if (this.props.consulenteId === null) { // user is admin
      this.fetchGruppi();
    } else {
      this.fetchManuali(gruppoId, this.state.showHiddenManuali);
    }
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  fetchGruppi = () => {
    getGruppi(null, null, 0, 3000, "id")
      .then(result => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId
        });
        this.fetchManuali(gruppoId, this.state.showHiddenManuali);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  fetchManuali = (gruppoId, hidden) => {
    let consulenteId = null;
    if (this.props.consulenteId !== null && !this.props.superconsulenteView) {
      consulenteId = this.props.consulenteId; // Admin and superconsulente do not filter manuali by consulenteId 
    }
    getRevisioniManuali(null, hidden, 101, consulenteId, 0, 3000, "id")
      .then(result => {
        this.setState({
          loading: false,
          manuali: result
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }

  hideManuale = (manuale) => {
    this.setState({
      loading: false,
    });
    let isHiding = this.state.showHiddenManuali ? false : true;
    cancellaRevisione(manuale.id, isHiding)
      .then(result => {
        this.fetchManuali(this.state.gruppoId, this.state.showHiddenManuali);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }
  checkStatus = (revisione,interval) => {
    if(!this.state.readyToDownload){
    getStatoFile(revisione.id,revisione.stato).then((result) => {
      if(result.stato === "DONE"){
        this.setState({
          readyToDownload:true,
        })
      }
    }).catch((error) => {
      this.setState({
        pdfloading: false
      },() => {
        if(!this.state.readyToDownload){
        clearInterval(this.intervalId);
        this.handleError(true, "Si è verificato un errore: download del PDF non riuscito.");
        }
      });
    })
    }
  }

  downloadPdf = (revisione) => {
    this.setState({
      pdfloading: true,
      readyToDownload:false,
    });
    getStatoFile(revisione.id,revisione.stato).then((result) => {
      if(result.stato === "PROGRESS"){
        let counter = 0;
        this.intervalId = setInterval(() => {
          if(counter < 40){
            this.checkStatus(revisione,this.intervalId);
            counter++;
          }
          else{
            clearInterval(this.intervalId);
            this.setState({
              pdfloading:false,
            },() => {
              this.handleError(true, "Attenzione, timeout della connessione raggiunta");
            })
          }
          if(this.state.readyToDownload){
            clearInterval(this.intervalId);
            this.setState({
              pdfloading:false,
              //readyToDownload:false,
            },() => {
              const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
              if (revisione.stato === 'BOZZA') {
                window.location.href = ENDPOINT + '/api/revisioni/pdf_anteprima/' + revisione.id
              } else {
                window.location.href = ENDPOINT + '/api/revisioni/pdf/' + revisione.id
              }
            })
          }
        },3000)
      }else{
        this.setState({
          pdfloading:false,
        },() => {
          const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
          if (revisione.stato === 'BOZZA') {
            window.location.href = ENDPOINT + '/api/revisioni/pdf_anteprima/' + revisione.id
          } else {
            window.location.href = ENDPOINT + '/api/revisioni/pdf/' + revisione.id
          }
        })
      }
    }).catch((error) => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          pdfloading: false
        },() => {
          this.handleError(true, "Si è verificato un errore: download del PDF non riuscito.");
        });
      }
    })
    /*let isDone = false;
    let counter = 0;
    var interval = setInterval(() => {
      if(isDone && counter === 4){
        clearInterval(interval);
        this.setState({
          pdfloading:false,
        },() => {
          const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
          if (revisione.stato === 'BOZZA') {
            window.location.href = ENDPOINT + '/api/revisioni/pdf_anteprima/' + revisione.id
          } else {
            window.location.href = ENDPOINT + '/api/revisioni/pdf/' + revisione.id
          }
        })
      }else{
        isDone = true;
        counter++;
        console.log(counter);
      }
    },5000);*/
    /*const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    if (revisione.stato === 'BOZZA') {
      window.location.href = ENDPOINT + '/api/revisioni/pdf_anteprima/' + revisione.id
    } else {
      window.location.href = ENDPOINT + '/api/revisioni/pdf/' + revisione.id
    }*/
  }

  onCancellaOrRipristinaButtonClicked = (revisione, e) => {
    this.setState({
      loading: false,
    });
    let hidden = this.state.showHiddenManuali ? false : true;
    cancellaRevisione(revisione.id, hidden)
      .then(result => {
        this.fetchManuali(this.state.gruppoId, this.state.showHiddenManuali);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, error.message);
        }
      });
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId,
      loading: true
    });
    this.fetchManuali(gruppoId, this.state.showHiddenManuali);
  }

  onNewGruppoAdded = () => {
    this.setState({
      loading: true
    });
    this.fetchGruppi();
  }

  onHiddenManualiSelection = (e) => {
    let hidden = e.target.value;
    this.setState({
      showHiddenManuali: hidden,
      loading: true,
    });
    this.fetchManuali(this.state.gruppoId, hidden);
  }

  onCreaDaManualeButtonClicked = (manuale, event) => {
    let manualeBaseId = manuale === null ? null : manuale.id;
    const isAdmin = this.props.consulenteId === null;
    const isSuperconsulente = this.props.superconsulenteView;
    if(isAdmin){
      this.setState({
        gruppoId:manuale.gruppoId,
        manualeBaseId,
        showModalSelezioneConsulente:true,
      })
    }
    else if (isSuperconsulente) {
      this.setState({
        loading: true,
        manualeBaseId,
        showModalSelezioneConsulente: true
      });
    } else { // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        manualeBaseId:manualeBaseId,
        showModalSelezioneEsercizio: true,
      });
    }
  }
  onCreaManualeButtonClicked = (manuale, event) => {
    let manualeBaseId = manuale === null ? null : manuale.id;
    const isAdmin = this.props.consulenteId === null;
    const isSuperconsulente = this.props.superconsulenteView;
    if (isSuperconsulente || isAdmin) {
      this.setState({
        loading: true,
        manualeBaseId,
        gruppoId:isSuperconsulente ? this.props.gruppoId : -1,
        showModalSelezioneConsulente: true
      });
    } else { // If user is not admin nor superconsulente:
      let consulenteId = this.props.consulenteId;
      this.setState({
        loading: true,
        consulenteId,
        manualeBaseId:manualeBaseId,
        showModalSelezioneEsercizio: true,
      });
    }
  }

  onCloseModalConsulenti = () => {
    this.setState({
      loading: false,
      showModalSelezioneConsulente: false
    });
  }

  onOpenModalEserciziCommerciali = (consulenteId) => {
    this.setState({
      consulenteId,
      showModalSelezioneConsulente: false,
      showModalSelezioneEsercizio: true,
    })
  }

  onCloseModalEserciziCommerciali = () => {
    this.setState({
      loading: false,
      showModalSelezioneEsercizio: false,
    })
  }

  onProseguiButtonModalitaClicked = (modalita,esercizioId) => {
    const { history } = this.props;
    this.setState({
      showModalSelezioneModalita: false,
      loadingModal:true,
    }, () => {
      creaManualePersonalizzato(esercizioId,this.state.manualeBaseId, this.state.consulenteId,modalita).then((result) => {
        setChapterIndex(0);
        setParagraphIndex(0);
        setOpenParti(JSON.stringify({
          parti:[],
          capitoli:[],
          paragrafi:[]
        }))
        if(modalita === false)
        history.push('/amministrazione/manuali/nuovomanuale/' + result.manuale.id + '/manuale/false/'+result.id);
        else{
          history.push('/amministrazione/manuali/nuovomanuale/' + result.manuale.id + '/manuale/true/'+result.id);
        }
      }).catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loadingModal: false
          });
          this.handleError(true, error.message);
        }
      })
    })
  }

  onOpenModalSelezioneModalita = (consulenteId,esercizioId) => {
    const { history } = this.props;
    if (this.state.manualeBaseId === null) {
      this.setState({
        consulenteId,
        esercizioId,
        showModalSelezioneEsercizio: false,
        showModalSelezioneModalita: false,
      },() => {
        this.onProseguiButtonModalitaClicked(true,esercizioId);
      });
    } else {
      getCapitoliCount(this.state.manualeBaseId)
      .then(result => {
        if (result === 0) {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio: false,
            showModalSelezioneModalita: false,
          },() => {
            this.onProseguiButtonModalitaClicked(true,esercizioId);
          });
        } else {
          this.setState({
            consulenteId,
            esercizioId,
            showModalSelezioneEsercizio:false,
            showModalSelezioneModalita: false,
            loadingModal:true,
          }, () => creaManualePersonalizzato(esercizioId, this.state.manualeBaseId, consulenteId, false)
            .then(result => {
              setChapterIndex(0);
              setParagraphIndex(0);
              setOpenParti(JSON.stringify({
                parti:[],
                capitoli:[],
                paragrafi:[]
              }))
              history.push('/amministrazione/manuali/nuovomanuale/' + result.manuale.id + '/manuale/'+true+"/"+result.id);
            })
            .catch(error => {
              if (error.status === 403) {
                this.handleInvalidToken();
              } else {
                this.setState({
                  loading: false,
                  loadingModal: false,
                });
                this.handleError(true, error.message);
              }
            }));
        }
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    }
  }
  onCloseModalModalita = () => {
    this.setState({
      loading:false,
      showModalSelezioneModalita:false,
    })
  }

  

  onCancelGruppoSelection = () => {
    let currentGruppoId = this.state.gruppoId;
    this.setState({
      showModalSelezioneGruppo: false,
      loading:false,
      selectedGruppoId: currentGruppoId,
    });
  }

  saveSelectedGruppoForModello = (gruppoId) => {
    this.setState({
      selectedGruppoId: gruppoId,
      gruppoId: gruppoId,
    });
  }
  onCreaButtonInModalClicked = (origin) => {
    this.setState({
      showModalSelezioneConsulente:true,
      showModalSelezioneGruppo:false,
    })
  }

  getColumns = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID manuale',
          id: 'id',
          accessor: d => d.manuale.id,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID manuale base',
          id: 'manualeBaseId',
          accessor: d => d.manuale.manualeBaseId === null ? ' - ' : d.manuale.manualeBaseId,
          width: 154,
        },
        {
          Header: 'ID gruppo',
          id: 'gruppoId',
          accessor: d => d.manuale.gruppoId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID consulente',
          id: 'consulenteId',
          accessor: d => d.consulenteId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID punto vendita',
          id: 'esercizioCommercialeId',
          accessor: d => d.esercizioCommercialeId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'Punto vendita',
          id: 'esercizioNome',
          accessor: d => d.esercizioCommercialeNome,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          minWidth: 200,
        },
        {
          Header: 'Titolo',
          id: 'titolo',
          accessor: d => d.manuale.titolo,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          minWidth: 180
        },
        {
          Header: 'Data creazione',
          id: 'dataCreazione',
          accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
          width: 160,
        },
        {
          Header: 'Data aggiornamento manuale',
          id: 'dataUltimoAggiornamento',
          accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
          width: 224,
        },
        {
          Header: 'Data aggiornamento revisione',
          id: 'dataRevisione',
          accessor: d => moment(d.dataRevisione).format(dateFormat),
          width: 224,
        },
        /*{
          Header: 'Indice revisione',
          id: 'indiceRevisione',
          accessor: d => d.indiceRevisione,
          minWidth: 80,
        },
        */
        {
          Header: 'Motivo Revisione',
          id: 'motivoRevisione',
          accessor: d => d.motivoRevisione,
          minWidth: 180,
        },
        {
          Header: 'Stato Pubblicazione',
          id: 'stato',
          accessor: d => d.stato,
          minWidth: 160,
        },
        {
          Header: 'Stato Log',
          id: 'statoCalendario',
          accessor: d => d.statoLogDescrizione,
          minWidth: 160,
        },
        {
          id: 'creaManualeBtn',
          Header: 'Crea manuale da',
          show: this.state.showHiddenManuali ? false : true,
          accessor: row => row,
          width: 160,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{
                color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow
              }}
              onClick={(e) => { this.onCreaDaManualeButtonClicked(props.value.manuale, e) }}
            >
               <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'modificaButton',
          Header: 'Modifica',
          show: this.state.showHiddenManuali ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <DettaglioManualeButton revisioneManualeId={props.value.id} disabled={props.value.stato === 'BOZZA' ? false : true} />
        },
        {
          id: 'downloadPdfButton',
          Header: 'Scarica',
          show: this.state.showHiddenManuali ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{ color:theme.palette.cndColors.yellow }}
              size="small"
              onClick={() => this.downloadPdf(props.value)}
              disabled={false}
            >
               <Scarica fill={theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'cancellaButton',
          Header: this.state.showHiddenManuali ? 'Ripristina' : 'Elimina',
          accessor: row => row,
          width: 80,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.red  }}
              size="small"
              onClick={(e) => { this.onCancellaOrRipristinaButtonClicked(props.value, e) }}
            >
              {this.state.showHiddenManuali ? <RestoreIcon /> : <CancelRounded />}
            </IconButton>
        }
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID manuale',
          id: 'id',
          accessor: d => d.manuale.id,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID manuale base',
          id: 'manualeBaseId',
          accessor: d => d.manuale.manualeBaseId === null ? ' - ' : d.manuale.manualeBaseId,
          width: 154,
        },
        {
          Header: 'ID consulente',
          id: 'consulenteId',
          accessor: d => d.consulenteId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'ID punto vendita',
          id: 'esercizioCommercialeId',
          accessor: d => d.esercizioCommercialeId,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          width: 120,
        },
        {
          Header: 'Punto vendita',
          id: 'esercizioNome',
          accessor: d => d.esercizioCommercialeNome,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          minWidth: 200,
        },
        {
          Header: 'Titolo',
          id: 'titolo',
          accessor: d => d.manuale.titolo,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <TextField
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              style={{width:"100%"}}
              InputProps={{
                disableUnderline:true,
                endAdornment: (<InputAdornment position="end">
                <IconButton size="small">
                <Search>
  
                </Search>
                </IconButton>
                </InputAdornment>)
              }}
            />
          ),
          minWidth: 180
        },
        {
          Header: 'Data creazione',
          id: 'dataCreazione',
          accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
          width: 160,
        },
        {
          Header: 'Data aggiornamento manuale',
          id: 'dataUltimoAggiornamento',
          accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
          width: 224,
        },
        {
          Header: 'Data aggiornamento revisione',
          id: 'dataRevisione',
          accessor: d => moment(d.dataRevisione).format(dateFormat),
          width: 224,
        },
        /*{
          Header: 'Indice revisione',
          id: 'indiceRevisione',
          accessor: d => d.indiceRevisione,
          minWidth: 80,
        },
        */
        {
          Header: 'Motivo Revisione',
          id: 'motivoRevisione',
          accessor: d => d.motivoRevisione,
          minWidth: 180,
        },
        {
          Header: 'Stato Pubblicazione',
          id: 'stato',
          accessor: d => d.statoLog === "IN_ERRORE" ? "ERRORE PUBBLICAZIONE" : d.stato,
          minWidth: 160,
        },
        /*{
          Header: 'Stato Calendario',
          id: 'statoCalendario',
          accessor: d => d.statoLogDescrizione,
          minWidth: 160,
        },*/
        {
          id: 'creaManualeBtn',
          Header: 'Crea manuale da',
          show: this.state.showHiddenManuali ? false : true,
          accessor: row => row,
          width: 160,
          Cell: props =>
            <IconButton
              size="small"
              disabled={props.value.stato === 'BOZZA' ? true : false}
              style={{
                color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow
              }}
              onClick={(e) => { this.onCreaDaManualeButtonClicked(props.value.manuale, e) }}
            >
               <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'modificaButton',
          Header: 'Modifica',
          show: this.state.showHiddenManuali ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <DettaglioManualeButton revisioneManualeId={props.value.id} disabled={props.value.stato === 'BOZZA' ? false : true} />
        },
        {
          id: 'downloadPdfButton',
          Header: 'Scarica',
          show: this.state.showHiddenManuali ? false : true,
          width: 80,
          accessor: row => row,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.yellow }}
              size="small"
              onClick={() => this.downloadPdf(props.value)}
              disabled={false}
            >
               <Scarica fill={theme.palette.cndColors.yellow} width={23} height={23} />
            </IconButton>
        },
        {
          id: 'cancellaButton',
          Header: this.state.showHiddenManuali ? 'Ripristina' : 'Elimina',
          accessor: row => row,
          width: 80,
          Cell: props =>
            <IconButton
              style={{ color: theme.palette.cndColors.red  }}
              size="small"
              onClick={(e) => { this.onCancellaOrRipristinaButtonClicked(props.value, e) }}
            >
              {this.state.showHiddenManuali ? <RestoreIcon /> : <CancelRounded />}
            </IconButton>
        }
      ];
    }
    return [
      {
        Header: 'Numero',
        id: 'numero',
        accessor: row => row,
        width: 120,
        Cell: props => props.index
      },
      {
        Header: 'ID manuale',
        id: 'id',
        accessor: d => d.manuale.id,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
        width: 120,
      },
      {
        Header: 'ID manuale base',
        id: 'manualeBaseId',
        accessor: d => d.manuale.manualeBaseId === null ? ' - ' : d.manuale.manualeBaseId,
        width: 154,
      },
      {
        Header: 'ID punto vendita',
        id: 'esercizioCommercialeId',
        accessor: d => d.esercizioCommercialeId,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
        width: 120,
      },
      {
        Header: 'Punto vendita',
        id: 'esercizioNome',
        accessor: d => d.esercizioCommercialeNome,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
        minWidth: 200,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.manuale.titolo,
        filterable: true,
        Filter: ({filter, onChange}) => (
          <TextField
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            style={{width:"100%"}}
            InputProps={{
              disableUnderline:true,
              endAdornment: (<InputAdornment position="end">
              <IconButton size="small">
              <Search>

              </Search>
              </IconButton>
              </InputAdornment>)
            }}
          />
        ),
        minWidth: 180
      },
      {
        Header: 'Data creazione',
        id: 'dataCreazione',
        accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
        width: 160,
      },
      {
        Header: 'Data aggiornamento manuale',
        id: 'dataUltimoAggiornamento',
        accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Data aggiornamento revisione',
        id: 'dataRevisione',
        accessor: d => moment(d.dataRevisione).format(dateFormat),
        width: 224,
      },
      /*{
        Header: 'Indice revisione',
        id: 'indiceRevisione',
        accessor: d => d.indiceRevisione,
        minWidth: 80,
      },
      */
      {
        Header: 'Motivo Revisione',
        id: 'motivoRevisione',
        accessor: d => d.motivoRevisione,
        minWidth: 180,
      },
      {
        Header: 'Stato Pubblicazione',
        id: 'stato',
        accessor: d => d.statoLog === "IN_ERRORE" ? "ERRORE PUBBLICAZIONE" : d.stato,
        minWidth: 160,
      },
      /*{
        Header: 'Stato Calendario',
        id: 'statoCalendario',
        accessor: d => d.statoLogDescrizione,
        minWidth: 160,
      },*/
      {
        id: 'creaManualeBtn',
        Header: 'Crea manuale da',
        show: this.state.showHiddenManuali ? false : true,
        accessor: row => row,
        width: 160,
        Cell: props =>
          <IconButton
            size="small"
            disabled={props.value.stato === 'BOZZA' ? true : false}
            style={{
              color: props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow
            }}
            onClick={(e) => { this.onCreaDaManualeButtonClicked(props.value.manuale, e) }}
          >
             <CreaManualeDa fill={props.value.stato === 'BOZZA' ? theme.palette.disabled.main : theme.palette.cndColors.yellow} width={23} height={23} />
          </IconButton>
      },
      {
        id: 'modificaButton',
        Header: 'Modifica',
        show: this.state.showHiddenManuali ? false : true,
        width: 80,
        accessor: row => row,
        Cell: props =>
          <DettaglioManualeButton revisioneManualeId={props.value.id} disabled={props.value.stato === 'BOZZA' ? false : true} />
      },
      {
        id: 'downloadPdfButton',
        Header: 'Scarica',
        show: this.state.showHiddenManuali ? false : true,
        width: 80,
        accessor: row => row,
        Cell: props =>
          <IconButton
            style={{ color: theme.palette.cndColors.yellow }}
            size="small"
            onClick={() => this.downloadPdf(props.value)}
            disabled={false}
          >
            <Scarica fill={theme.palette.cndColors.yellow} width={23} height={23}/>
          </IconButton>
      },
      {
        id: 'cancellaButton',
        Header: this.state.showHiddenManuali ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        width: 80,
        Cell: props =>
          <IconButton
            style={{ color: theme.palette.cndColors.red  }}
            size="small"
            onClick={(e) => { this.onCancellaOrRipristinaButtonClicked(props.value, e) }}
          >
            {this.state.showHiddenManuali ? <RestoreIcon /> : <CancelRounded />}
          </IconButton>
      }
    ];
  }

  render() {
    const columns = this.getColumns();
    const isAdmin = this.props.consulenteId === null;
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px' }}>
        <LoadingOverlay
          active={this.state.pdfloading}
          spinner
          text='Si prega di attendere senza chiudere o uscire da questa schermata, la generazione del manuale può richiedere, a seconda dei casi, anche vari minuti. Il download inizierà automaticamente al termine'
        >
          {this.state.loading ?
            <SpinnerComponent size={24} />
            :
            <div style={styles.mainContainer}>
              {/*isAdmin ?
                <SelezioneGruppo
                  gruppoId={this.state.gruppoId}
                  gruppi={this.state.gruppi}
                  disabled={false}
                  description="Selezionare il gruppo su cui filtrare i risultati:"
                  onGruppoSelected={this.handleSelectedGruppo}
                  onGruppoAdded={this.onNewGruppoAdded}
                  onError={this.handleError}
                  onAuthError={this.handleInvalidToken}
                />
                : null
              */}
              <div style={{ marginBottom: '30px' }}>
                <Select
                  value={this.state.showHiddenManuali}
                  variant='outlined'
                  name="activated"
                  style={{borderRadius:50}}
                  input={<BootstrapInput></BootstrapInput>}
                  onChange={(e) => { this.onHiddenManualiSelection(e) }}
                >
                  <MenuItem value={false} >Manuali non cancellati</MenuItem>
                  <MenuItem value={true} >Manuali cancellati</MenuItem>
                </Select>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {isAdmin ? <ActionButton
                  label="MANUALE"
                  startIcon={<AddIcon />}
                  disabled={this.state.loading}
                  onClick={(e) => { this.onCreaManualeButtonClicked(null, e) }}
                  grayVersion={false}
                /> : null}
              </div>
              <ReactTable
                filterable={false}
                resizable={true}
                sortable={false}
                showPageSizeOptions={true}
                showPageJump={true}
                defaultPageSize={10}
                columns={columns}
                data={this.state.manuali}
                defaultFilterMethod={(filter, row,column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                }}
                previousText='Precedente'
                nextText='Successivo'
                noDataText='Nessun record'
                pageText='Pagina'
                ofText='di'
                rowsText='righe'
                pageJumpText='Vai a pagina'
                rowsSelectorText='righe per pagina'
              />
            </div>
          }
        </LoadingOverlay>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        {this.state.showModalSelezioneConsulente &&
          <ModalSelezioneConsulente
            isModalOpen={this.state.showModalSelezioneConsulente}
            openEserciziModal={this.onOpenModalEserciziCommerciali}
            gruppoId={101}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.onCloseModalConsulenti}
          />
        }
        {this.state.showModalSelezioneEsercizio &&
          <ModalSelezioneEsercizioCommerciale
            idConsulente={this.state.consulenteId}
            gruppoId={this.state.gruppoId}
            manualeBaseId={this.state.manualeBaseId}
            isModalOpen={this.state.showModalSelezioneEsercizio}
            handleError={this.handleError}
            handleInvalidToken={this.handleInvalidToken}
            closeModal={this.onCloseModalEserciziCommerciali}
            openModalitaModal={this.onOpenModalSelezioneModalita}
          />
        }
        {this.state.showModalSelezioneModalita &&
        <ModalSelezioneModalita
        idConsulente={this.state.consulenteId}
        selectedEsercizio = {this.state.esercizioId}
        gruppoId={this.state.gruppoId}
        manualeBaseId={this.state.manualeBaseId}
        open={this.state.showModalSelezioneModalita}
        handleError={this.handleError}
        handleInvalidToken={this.handleInvalidToken}
        onClose={this.onCloseModalModalita}
        onProseguiButton = {this.onProseguiButtonModalitaClicked}
        >
        </ModalSelezioneModalita>
        }
        {this.state.showModalSelezioneGruppo &&
          <ModalSelezioneGruppo
            gruppoId={this.state.gruppoId}
            gruppi={this.state.gruppi}
            open={this.state.showModalSelezioneGruppo}
            onCancel={this.onCancelGruppoSelection}
            onGruppoSelected={this.saveSelectedGruppoForModello}
            onCreaButtonClicked={this.onCreaButtonInModalClicked}
            origin={this.state.origin}
          />
        }
        {this.state.loadingModal && 
        <LoadingModal
        open={this.state.loadingModal}
        testo={"Generazione manuale in corso..."}
        >
        </LoadingModal>
        }
      </div>
    );
  }

}

ManualiPersonalizzatiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}

export default withRouter(ManualiPersonalizzatiTable);