import { retrieveToken } from "../storage";
import { wrapFetch, ApiError } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
export const getDiagnostica = (page, size, id, responsabileSicurezzaId) => {
  const PATH = `/api/cnd/diagnostiche`;
  let query = `?page=${page}&size=${size}`

  if (id) {
    query += `&id.equals=${id}`;
  }
  if (responsabileSicurezzaId) {
    query += `&responsabileSicurezzaId.equals=${responsabileSicurezzaId}`;
  }
  const URL = BASE_URL + PATH + query;
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore: i dati in input non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};


export const getDiagnosticaCount = (id, responsabileId) => {
    const PATH = `/api/cnd/diagnostiche/count`;
    let query="?";
    if(id){
      query+= `&id.equals=${id}`
    }

    if(responsabileId){
      query+=`&responsabileSicurezzaId.equals=${responsabileId}`
    }
    const URL = BASE_URL + PATH + query;
    const token = retrieveToken();
    const authtoken = "Bearer ".concat(token);
    const errorFunction = (status) => {
      let message = null;
      if (status === 403) {
        message =
          "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
      } else if (status === 400) {
        message = "Errore: i dati in input non sono corretti.";
      }
      return new ApiError(status, message);
    };
    return wrapFetch(
      fetch(URL, {
        method: "GET",
        headers: {
          Authorization: authtoken,
        },
        withCredentials: true,
        credentials: "include",
      }),
      errorFunction
    );
  };
  


// /cnd/diagnostiche/count