import React, { Component } from "react";
import {
  getDiagnostica,
  getDiagnosticaCount,
} from "../../../utils/api/diagnostica_api";
import BasicTable from "../../../components/BasicTable";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { ReactComponent as DiagnosticaIcona } from "./../../../DiagnosticaIcona.svg";
export default class DiagnosticaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      diagnosticaData: "",
      currentPage: 0,
      totalPages: 0,
      data: [],
      id: null,
      responsabileId: null,
      cleanData: false,
      loading: true,
      textFilterId: "",
      textFilterResponsabileId: "",
    };
  }

  componentDidMount() {
    this.getCount();
    this.getData();
  }

  /*  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.getData();
        }
      );
    }
  } */

  getCount = () => {
    getDiagnosticaCount(this.state.id, this.state.responsabileId)
      .then((response) => {
        const pages = Math.ceil(response / 10);
        this.setState({
          totalPages: pages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getData = () => {
    getDiagnostica(
      this.state.currentPage,
      10,
      this.state.id,
      this.state.responsabileId
    )
      .then((response) => {
        this.setState({
          data: response,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formattaData(dataString) {
    const data = new Date(dataString);
    const giorno = ("0" + data.getDate()).slice(-2);
    const mese = ("0" + (data.getMonth() + 1)).slice(-2);
    const anno = data.getFullYear();
    const ora = ("0" + data.getHours()).slice(-2);
    const minuto = ("0" + data.getMinutes()).slice(-2);

    return `${giorno}-${mese}-${anno} ${ora}:${minuto}`;
  }

  handleClickOpenDialog = (id) => {
    let data = this.findById(id).risultato;
    try {
      const parsedData = JSON.parse(data);
      this.setState({
        openDialog: true,
        diagnosticaData: parsedData,
        cleanData: true,
      });
    } catch (error) {
      this.setState({
        openDialog: true,
        diagnosticaData: data,
        cleanData: false,
      });
    }
  };

  findById(id) {
    return this.state.data.find((obj) => obj.id === id);
  }
  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleSearch(id, responsabileId) {
    this.setState(
      {
        id: id,
        responsabileId: responsabileId,
        currentPage: 0,
        loading: true,
      },
      () => {
        this.getCount();
        this.getData();
      }
    );
  }

  handlePageChange = (pageIndex) => {
    this.setState({ currentPage: pageIndex, loading: true }, () =>
      this.getData()
    );
  };

  isPositiveNumber(input) {
    if (!input || isNaN(input)) {
      return false;
    }
    return parseInt(input) >= 0;
  }

  columns = [
    {
      Header: "ID ",
      id: "id",
      sortable: false,
      filterable: true,
      accessor: (d) => d.id,
      Filter: ({ filter, onChange }) => (
        <TextField
          onChange={(event) => {
            const { value } = event.target;
            if (this.isPositiveNumber(value) || value === "") {
              onChange(value);
              this.setState({
                textFilterId: value,
              });
            }
          }}
          value={filter ? filter.value : ""}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() =>
                    this.handleSearch(
                      this.state.textFilterId,
                      this.state.textFilterResponsabileId
                    )
                  }
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      Header: "ID responsabile",
      id: "responsabileId",
      sortable: false,
      filterable: true,
      accessor: (d) => d.responsabileId,
      Filter: ({ filter, onChange }) => (
        <TextField
          onChange={(event) => {
            const { value } = event.target;
            if (this.isPositiveNumber(value) || value === "") {
              onChange(value);
              this.setState({
                textFilterResponsabileId: value,
              });
            }
          }}
          value={filter ? filter.value : ""}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    this.handleSearch(
                      this.state.textFilterId,
                      this.state.textFilterResponsabileId
                    )
                  }
                  size="small"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      Header: "Data inserimento",
      id: "dataInserimento",
      filterable: false,
      sortable: false,
      accessor: (d) => d.dataInserimento,
      Cell: (props) => (
        <Typography> {this.formattaData(props.value)}</Typography>
      ),
    },

    {
      Header: "Diagnostica",
      id: "diagnostica",
      filterable: false,
      sortable: false,
      accessor: (d) => {
        return {
          id: d.id,
        };
      },
      Cell: (props) => (
        <IconButton
          onClick={() => this.handleClickOpenDialog(props.value.id)}
          size="small"
        >
          <DiagnosticaIcona width={23} height={23} />
        </IconButton>
      ),
    },
  ];

  render() {
    return (
      <Box style={styles.root}>
        <BasicTable
          loading={this.state.loading}
          defaultPageSize={10}
          columns={this.columns}
          data={this.state.data}
          page={this.state.currentPage}
          onPageChange={this.handlePageChange}
          pages={this.state.totalPages}
        />
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          PaperProps={{ style: styles.dialog }}
        >
          {this.state.cleanData ? (
            <DialogContent>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>firmato:</span>
                {this.state.diagnosticaData?.firmato ? "true" : "false"}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>email:</span>
                {this.state.diagnosticaData?.email}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}> id:</span>
                {this.state.diagnosticaData?.id}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>revisioneId:</span>
                {this.state.diagnosticaData?.revisioneId}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>
                  esercizioCommercialeId:
                </span>
                {this.state.diagnosticaData?.esercizioCommercialeId}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>nomeEsercizio:</span>
                {this.state.diagnosticaData?.nomeEsercizio}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>revisioneDate:</span>
                {this.state.diagnosticaData?.revisioneDate}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>manualeId:</span>
                {this.state.diagnosticaData?.manualeId}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>isOperatore:</span>
                {this.state.diagnosticaData?.isOperatore ? "true" : "false"}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>isCapoNegozio:</span>
                {this.state.diagnosticaData?.isCapoNegozio ? "true" : "false"}
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>device:</span>
                <span style={{ wordWrap: "break-word" }}>
                  {JSON.stringify(this.state.diagnosticaData?.device)}
                </span>
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>versioneApp:</span>
                {this.state.diagnosticaData?.versioneApp}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>esitoChiamate:</span>
                {this.state.diagnosticaData?.esitoChiamate}
              </Typography>

              <Typography style={{ fontSize: 12 }}>
                <span style={{ fontWeight: "bold" }}>
                  chiamate non raggiunte:
                </span>
                <span style={{ wordWrap: "break-word" }}>
                  {this.state.diagnosticaData &&
                  this.state.diagnosticaData.chiamateNonRaggiunte
                    ? this.state.diagnosticaData.chiamateNonRaggiunte.join(", ")
                    : " []"}
                </span>
              </Typography>
            </DialogContent>
          ) : null}

          {!this.state.cleanData ? (
            <DialogContent>
              <Typography style={{ fontSize: 12, wordWrap: "break-word" }}>
                {this.state.diagnosticaData
                  ? this.state.diagnosticaData
                  : "Nessun dato"}
              </Typography>
            </DialogContent>
          ) : null}

          <DialogActions>
            <Button onClick={this.handleCloseDialog} autoFocus>
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

const styles = {
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "30px",
    minHeight: "100%",
  },
  text: {
    padding: "0px 0px 10px 0px",
  },
  dialog: {
    borderRadius: 20,
  },
};
