import React from "react";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";

import theme from '../../../theme';

export default function GoBackButton(props) {
    const history = useHistory();
    return (
        <Button
            type="button"
            variant="contained"
            size="medium"
            disabled={props.disabled}
            style={{
                color: theme.palette.secondary.main,
                margin: '10px',
                borderRadius:30,
                backgroundColor: props.disabled ?
                    theme.palette.disabled.main
                    :
                    (props.grayVersion ? theme.palette.secondary.other : theme.palette.cndColors.red)
            }}
            onClick={() => {
                history.goBack();
            }}
        >
            {props.label}
        </Button>
    );
}