import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";
import { ReactComponent as CalendarLogo } from "../../../Calendario.svg";
import moment from "moment";
import "moment/locale/it";
import itLocale from "date-fns/locale/it";
import theme from "../../../theme.js";
import { IconButton, InputAdornment, ThemeProvider } from "@material-ui/core";
import GoToPaginaCostruzioneManuale from "./GoToPaginaCostruzioneManuale.js";
import ModalSelezionePuntoVendita from "./ModalSelezionePuntoVendita.js";
import ListIcon from "@material-ui/icons/List";
import { Search } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = {
  sectioncontainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "30px",
    padding: "40px 0",
  },
  textfieldscontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "250px",
  },
  textfieldWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  pdvSearchWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  pdvSearchTextWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
  pdvTextfield: {
    width: "90%",
    maxWidth: "480px",
    backgroundColor: theme.palette.background.default,
    border: `1.5px solid ${theme.palette.cndColors.yellow}`,
    borderRadius: "50px",
    padding: "8px 20px",
  },
};

export default function FormManuale(props) {
  const dateFormat = "DD-MM-YYYY HH:mm";

  const [isModalPdVOpen, setModalPdVOpen] = useState(false);
  const [selectedPdV, setSelectedPdV] = useState(null);

  useEffect(() => {
    if (props.record.esercizioCommercialeId) {
      const currentPdV = props.esercizi.find(
        (esercizio) => esercizio.id === props.record.esercizioCommercialeId
      );
      setSelectedPdV(currentPdV || null);
    }
  }, [props.record, props.esercizi]);

  const openModalPdV = () => setModalPdVOpen(true);
  const closeModalPdv = () => setModalPdVOpen(false);

  const handleSelection = (selectedItem) => {
    setSelectedPdV(selectedItem);

    props.onPuntoVenditaSelected(selectedItem);
  };

  const displayPdvValue = () => {
    if (selectedPdV) {
      let valoreVisualizzato = "";
      if (selectedPdV.codiceMuro) {
        valoreVisualizzato += selectedPdV.codiceMuro;
      }
      if (valoreVisualizzato.length > 0 && selectedPdV.ragioneSociale) {
        valoreVisualizzato += ` - ${selectedPdV.ragioneSociale}`;
      } else if (selectedPdV.ragioneSociale) {
        valoreVisualizzato += selectedPdV.ragioneSociale;
      }
      return valoreVisualizzato;
    }
    // Se non ci sono informazioni disponibili, lascia il campo vuoto
    return "";
  };

  return (
    <ThemeProvider theme={theme}>
      <form noValidate autoComplete="off" onSubmit={props.onSubmit}>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={styles.sectioncontainer}
          >
            <Typography variant="h5" style={{ paddingBottom: "6px" }}>
              Dati identificativi del punto vendita
            </Typography>
            <Grid container style={styles.textfieldscontainer}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ paddingBottom: "10px" }}
                >
                  <TextField
                    label="ID manuale"
                    disabled
                    name="id"
                    value={props.record.manuale.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="ID manuale base"
                    disabled
                    name="manualeBaseId"
                    value={
                      props.record.manuale.manualeBaseId === null
                        ? " - "
                        : props.record.manuale.manualeBaseId
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="ID revisione"
                    disabled
                    name="id"
                    value={props.record.id}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="ID consulente"
                    disabled
                    name="consulenteId"
                    value={props.record.consulenteId || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={itLocale}
                  >
                    <KeyboardDatePicker
                      label="Data di consegna"
                      keyboardIcon={
                        <CalendarLogo
                          width={15}
                          height={15}
                          fill={theme.palette.cndColors.yellow}
                        ></CalendarLogo>
                      }
                      autoOk
                      variant="inline"
                      inputVariant="standard"
                      style={styles.textfield}
                      format="dd/MM/yyyy"
                      value={props.record.dataConsegna}
                      InputAdornmentProps={{
                        position: "end",
                        style: { marginBottom: 4 },
                      }}
                      onChange={props.onChangeDeliveryDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ paddingBottom: "10px" }}
                >
                  <TextField
                    label="Data creazione manuale"
                    disabled
                    name="dataCreazione"
                    value={
                      props.record.manuale.dataCreazione === null
                        ? ""
                        : moment(props.record.manuale.dataCreazione).format(
                            dateFormat
                          )
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="Data aggiornamento manuale"
                    disabled
                    name="dataAggiornamento"
                    value={
                      props.record.manuale.dataUltimoAggiornamento === null
                        ? ""
                        : moment(
                            props.record.manuale.dataUltimoAggiornamento
                          ).format(dateFormat)
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="Stato Pubblicazione"
                    disabled
                    name="stato"
                    value={props.record.stato}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={styles.textfield}
                  />
                  <TextField
                    label="Motivo della revisione"
                    value={props.record.motivoRevisione}
                    name="motivoRevisione"
                    onChange={props.onChange}
                    style={styles.textfield}
                    disabled={props.disabledForm}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div style={styles.textfieldWrapper}>
              <div style={styles.pdvSearchWrapper}>
                <div style={styles.pdvSearchTextWrapper}>
                  <Search style={{ color: theme.palette.cndColors.yellow }} />
                  <Typography>PUNTO VENDITA:</Typography>
                </div>
                <TextField
                  value={displayPdvValue()}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={openModalPdV}>
                          <ListIcon
                            style={{ color: theme.palette.cndColors.yellow }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  multiline
                  style={styles.pdvTextfield}
                />
              </div>

              <div style={styles.pdvSearchWrapper}>
                <Typography>RESPONSABILE ASSOCIATO:</Typography>
                <TextField
                  value={
                    props.responsabileSicurezza &&
                    props.responsabileSicurezza.length > 0
                      ? `${props.responsabileSicurezza[0].id} - ${props.responsabileSicurezza[0].email}`
                      : "Nessun responsabile associato"
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  multiline
                  style={styles.pdvTextfield}
                  disabled
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ padding: "10px 0px 0px 0px" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="body1"
              style={{
                color: theme.palette.primary.main,
                //visibility: props.saveSuccessMessageVisible ? 'inherit' : 'hidden'
                display: props.saveSuccessMessageVisible ? "inherit" : "none",
              }}
            >
              Dati aggiornati con successo.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              onClick={props.onEliminaButtonClicked}
              variant="contained"
              size="medium"
              disabled={props.record.stato === "PUBBLICATO"}
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                borderRadius: "30px",
                backgroundColor:
                  props.record.stato === "PUBBLICATO"
                    ? theme.palette.disabled.main
                    : theme.palette.cndColors.red,
              }}
            >
              Elimina
            </Button>
            <div>
              <GoToPaginaCostruzioneManuale
                manualeId={props.record.manuale.id}
                revisioneId={props.record.id}
                disabled={props.record.stato === "PUBBLICATO"}
              />
              <Button
                type="button"
                onClick={props.onSubmit}
                variant="contained"
                size="medium"
                disabled={props.disabledSubmitButton}
                style={{
                  color: theme.palette.secondary.main,
                  margin: "10px",
                  borderRadius: 30,
                  backgroundColor: props.disabledSubmitButton
                    ? theme.palette.disabled.main
                    : theme.palette.cndColors.yellow,
                }}
              >
                Salva
              </Button>
            </div>
          </Grid>
          {isModalPdVOpen && (
            <ModalSelezionePuntoVendita
              open={isModalPdVOpen}
              onClose={closeModalPdv}
              puntiVendita={props.esercizi}
              onSaveSelection={handleSelection}
              selectedValue={selectedPdV}
            />
          )}
        </Grid>
      </form>
    </ThemeProvider>
  );
}

FormManuale.propTypes = {
  record: PropTypes.object.isRequired,
  responsabileSicurezza: PropTypes.array,
  esercizi: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabledSubmitButton: PropTypes.bool.isRequired,
  onPuntoVenditaSelected: PropTypes.func,
  saveSuccessMessageVisible: PropTypes.bool.isRequired,
  onEliminaButtonClicked: PropTypes.func,
  onChangeDeliveryDate: PropTypes.func,
  onChange: PropTypes.func,
  disabledForm: PropTypes.bool,
};
