import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import IndietroButton from "../../../components/IndietroButton";
import ErrorDialog from "../../../components/ErrorDialog";
import SpinnerComponent from "../../../components/SpinnerComponent";

import { clearToken } from "../../../utils/storage";
import { registerResponsabileSicurezza } from "../../../utils/api/responsabili_sicurezza_api";
import { getGruppi } from "../../../utils/api/gruppi_api";

import validator from "validator";
import theme from "../../../theme.js";

const styles = (theme) => ({
  container: {
    paddingLeft: "4px",
    paddingRight: "4px",
    maxWidth: "700px",
    minHeight: "500px",
  },
  sectionContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  userDataContainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
});

class FormRegistraResponsabileSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        email: "",
        password: "",
        confirmPassword: "",
        nome: "",
        cognome: "",
        telefono: null,
        terminiUsoAccettati: false,
        privacyPolicyAccettata: false,
        gruppoId: 101,
      },
      validationErrors: {
        email: "",
        password: "",
        confirmPassword: "",
        nome: "",
        cognome: "",
      },
      gruppi: [],
      showPassword1: false,
      showPassword2: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: false,
      disableForm: false,
      saveButtonEnabled: false,
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    if (id !== null) {
      let record = this.state.record;
      record.gruppoId = id;
      this.setState({
        record,
      });
    }
    if (!this.props.consulenteView && !this.props.superconsulenteView) {
      this.fetchGruppi();
    }
  }

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then((result) => {
        let gruppoId = this.state.record.gruppoId;
        let record = this.state.record;
        for (let i = 0; i < result.length; i++) {
          if (gruppoId === null) {
            if (result[i].nome === "BSFOOD") {
              gruppoId = result[i].id;
              break;
            }
          }
        }
        record.gruppoId = gruppoId;
        this.setState({
          loading: false,
          gruppi: result,
          record,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleClickShowPassword1 = () => {
    let flag = this.state.showPassword1;
    this.setState({
      showPassword1: !flag,
    });
  };
  handleClickShowPassword2 = () => {
    let flag = this.state.showPassword2;
    this.setState({
      showPassword2: !flag,
    });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let insertedPsw = this.state.record.password;
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "email":
        if (value.length === 0) {
          validationErrors.email = "Inserire l'indirizzo email";
        } else {
          if (validator.isEmail(value)) {
            validationErrors.email = "";
          } else {
            validationErrors.email = "L'indirizzo email inserito non è valido";
          }
        }
        break;
      case "password":
        if (value.length >= 8) {
          validationErrors.password = "";
        } else {
          validationErrors.password =
            "Inserire una password di almeno 8 caratteri";
        }
        break;
      case "confirmPassword":
        if (value.length === 0) {
          validationErrors.confirmPassword = "Ripetere la password";
        } else {
          if (value === insertedPsw) {
            validationErrors.confirmPassword = "";
          } else {
            validationErrors.confirmPassword =
              "Le due password non corrispondono";
          }
        }
        break;
      case "nome":
        if (value.length === 0) {
          validationErrors.nome =
            "Attenzione, occorre inserire il nome del responsabile";
        }
        break;
      case "cognome":
        if (value.length === 0) {
          validationErrors.cognome =
            "Attenzione, occorre inserire il cognome del responsabile";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleSelectedGruppo = (gruppoId) => {
    this.handleFormChange("gruppoId", gruppoId);
  };

  handleChange = (event) => {
    this.handleFormChange(event.target.name, event.target.value);
  };

  handleFormChange = (fielName, value) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let validationErrors = this.state.validationErrors;
    let data = this.state.record;
    data[fielName] = value;
    //Reset validation :
    if (
      fielName === "email" ||
      fielName === "password" ||
      fielName === "confirmPassword" ||
      fielName === "nome" ||
      fielName === "cognome"
    ) {
      validationErrors[fielName] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      registerResponsabileSicurezza(this.state.record)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
            disableForm: true,
          });
          this.props.onFormSubmitted();
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            let enableSave = true;
            if (error.status === 409) {
              enableSave = false;
            }
            this.setState({
              loading: false,
              saveButtonEnabled: enableSave,
            });
            this.handleError(true, error.message);
          }
        });
    } //no-op if form is not valid
  };

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    const { classes } = this.props;
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
    return (
      <Container id="form_container" className={classes.container}>
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.sectionContainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati utente
                </Typography>
                <Grid container className={classes.userDataContainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Email*"
                        value={
                          this.state.record.email === null
                            ? ""
                            : this.state.record.email
                        }
                        name="email"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                        helperText={this.state.validationErrors.email}
                        error={this.state.validationErrors.email.length > 0}
                      />
                      <TextField
                        label="Password*"
                        value={this.state.record.password}
                        name="password"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                        helperText={this.state.validationErrors.password}
                        error={this.state.validationErrors.password.length > 0}
                        type={this.state.showPassword1 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword1}
                                >
                                  {this.state.showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Ripeti password*"
                        value={this.state.record.confirmPassword}
                        name="confirmPassword"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                        helperText={this.state.validationErrors.confirmPassword}
                        error={
                          this.state.validationErrors.confirmPassword.length > 0
                        }
                        type={this.state.showPassword2 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Mostra password">
                                <IconButton
                                  label="Mostra password"
                                  onClick={this.handleClickShowPassword2}
                                >
                                  {this.state.showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Nome*"
                        value={
                          this.state.record.nome === null
                            ? ""
                            : this.state.record.nome
                        }
                        name="nome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                        helperText={this.state.validationErrors.nome}
                        error={this.state.validationErrors.nome.length > 0}
                      />
                      <TextField
                        label="Cognome*"
                        value={
                          this.state.record.cognome === null
                            ? ""
                            : this.state.record.cognome
                        }
                        name="cognome"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                        helperText={this.state.validationErrors.cognome}
                        error={this.state.validationErrors.cognome.length > 0}
                      />
                      <TextField
                        label="Telefono"
                        defaultValue={
                          this.state.record.telefono === null
                            ? ""
                            : this.state.record.telefono
                        }
                        name="telefono"
                        onChange={this.handleChange}
                        disabled={this.state.disableForm}
                        className={classes.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* !this.props.consulenteView && !this.props.superconsulenteView ? 
                        <Container style={styles.sectionContainer}>
                            <Typography variant="h6" style={styles.text} >Dati gruppo</Typography>
                            <SelezioneGruppo
                                gruppoId={this.state.record.gruppoId}
                                gruppi={this.state.gruppi}
                                disabled={this.state.disableForm}
                                description="Scegliere il gruppo di appartenenza del nuovo responsabile sicurezza."
                                onGruppoSelected={this.handleSelectedGruppo}
                                onGruppoAdded={this.fetchGruppi}
                                onError={this.handleError}
                                onAuthError={this.handleInvalidToken}
                            />
                        </Container>
                        : null
                    */}
            <Grid
              container
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "20px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton isDisabled={this.state.loading} />
                <Button
                  type="button"
                  onClick={this.handleSubmit}
                  variant="contained"
                  size="medium"
                  disabled={disabledSubmitbutton}
                  style={{
                    color: theme.palette.secondary.main,
                    margin: "10px",
                    borderRadius: 30,
                    backgroundColor: disabledSubmitbutton
                      ? theme.palette.disabled.main
                      : theme.palette.cndColors.yellow,
                  }}
                >
                  Registra
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(FormRegistraResponsabileSicurezza);

FormRegistraResponsabileSicurezza.propTypes = {
  gruppoId: PropTypes.number,
  consulenteView: PropTypes.bool.isRequired,
  superconsulenteView: PropTypes.bool.isRequired,
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
  classes: PropTypes.object,
};
