import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import ActionButton from "../../../components/ActionButton";

import theme from '../../../theme.js'

import PropTypes from 'prop-types';

const styles = {
    modalcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '6px',
        //paddingBottom: '6px',
        //paddingLeft: '10px',
        //paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
        marginLeft: '35%',
        marginRight: '35%',
        overflowY: 'scroll',
        height: '300px',
        maxWidth: '560px',
    },
    title: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
        },
    },
};

export default class ModalSelezioneGruppo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gruppoId: ''
        }
    }

    render() {
        const gruppi = this.props.gruppi.filter((item) => item.id !== -1);
        let gruppoid = this.props.gruppoId !== null && this.props.gruppoId !== -1 ? this.props.gruppoId : '';
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onCancel}
            >
                <div style={styles.modalcontainer}>
                    <Container>
                        {this.props.origin === "Modello" ? <Typography variant="h6" style={styles.title}>Scegli il gruppo di appartenenza del nuovo modello</Typography> :
                        <Typography variant="h6" style={styles.title}>Scegli il gruppo di appartenenza del nuovo manuale</Typography>}
                        <FormControl style={styles.formcontrol}>
                            <InputLabel id="gruppo-label">
                                Seleziona un gruppo
                            </InputLabel>
                            <Select
                                value={gruppoid}
                                name="id"
                                onChange={(e) => { this.props.onGruppoSelected(e.target.value) }}
                                MenuProps={MenuProps}
                            >
                                {gruppi.map((value, index) =>
                                    (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        <Container style={{ paddingTop: '20px', textAlign: 'center' }}  >
                            <ActionButton
                                label="Indietro"
                                disabled={false}
                                onClick={this.props.onCancel}
                                grayVersion={true}
                            />
                            <ActionButton
                                label={this.props.origin === "Modello" ? "Crea modello" : "Crea manuale"}
                                disabled={gruppoid === '' ? true : false}
                                onClick={() => {this.props.onCreaButtonClicked(this.props.origin)}}
                                grayVersion={false}
                            />
                        </Container>
                    </Container>

                </div>

            </Modal>)
    }
}

ModalSelezioneGruppo.propTypes = {
    gruppoId: PropTypes.number,
    gruppi: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onGruppoSelected: PropTypes.func.isRequired,
    onCreaButtonClicked: PropTypes.func.isRequired,
}