import React, { Fragment } from "react";
import { Box, Typography } from "@material-ui/core";

import PropTypes from "prop-types";

import FormRegistraResponsabilePerConsulente from "./FormRegistraResponsabilePerConsulente";
import FormRegistraResponsabileSicurezza from "./FormRegistraResponsabileSicurezza";
import FormModificaResponsabileSicurezza from "./FormModificaResponsabileSicurezza";
import EsercizioXResponsabileComponent from "./EsercizioXResponsabileComponent";

import theme from "../../../theme.js";
import Background from "../../../Sfondo.png";

const styles = {
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
  },
  tabsbar: {
    color: "black",
    //boxShadow: '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)'
  },
  registrationMessage: {
    paddingTop: "10px",
    color: theme.palette.primary.main,
    paddingBottom: "10px",
  },
};

export default class DettaglioResponsabileSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      secondTabEnabled: true,
      showRegistrationForm: true,
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.responsabileId === "nuovo") {
      this.setState({
        secondTabEnabled: false,
        showRegistrationForm: true,
      });
    } else {
      this.setState({
        secondTabEnabled: true,
        showRegistrationForm: false,
      });
    }
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onChangesSaved = () => {
    this.setState({
      showChangesSavedMessage: true,
    });
  };

  onRecordSaved = () => {
    this.setState({
      secondTabEnabled: false,
      showRegistrationMessage: true,
    });
  };

  onFormModified = () => {
    this.setState({
      showRegistrationMessage: false,
      showChangesSavedMessage: false,
    });
  };

  render() {
    const isConsulente =
      this.props.consulenteView && !this.props.superconsulenteView;
    const showRegistrationForm = this.state.showRegistrationForm;
    const title = showRegistrationForm
      ? "Nuovo responsabile punti vendita"
      : "Dettagli responsabile punti vendita";
    return (
      <div
        style={{
          textAlign: "center",
          color: "black",
          paddingTop: "26px",
          paddingBottom: "26px",
          paddingLeft: "10px",
          paddingRight: "10px",
          backgroundImage:
            this.state.width > 1240
              ? `url("${Background}"),url("${Background}")`
              : `url(""),url("")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "",
          backgroundAttachment: "fixed",
          backgroundPositionX:
            this.state.width > 1799
              ? "140%, -40%"
              : this.state.width > 1599
              ? "160%, -60%"
              : this.state.width > 1399
              ? "180%, -80%"
              : this.state.width > 1299
              ? "200%, -100%"
              : "220%, -120%",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {/* { isConsulente ? 
                <Paper style={styles.paper} >
                    <Box style={{ paddingTop:'20px' }} >
                        <Typography 
                            variant="body1" 
                            style={{
                                color: theme.palette.primary.main,
                                visibility: this.state.showChangesSavedMessage ? 'inherit' : 'hidden'
                            }}
                        >
                        Dati aggiornati con successo.
                        </Typography>
                    </Box>
                    { showRegistrationForm ? 
                        <Fragment>
                            <Typography 
                                variant="body1" 
                                style={{
                                    color: theme.palette.primary.main,
                                    visibility: this.state.showRegistrationMessage ? 'inherit' : 'hidden'
                                }}
                            >
                            Registrazione effettuata con successo.
                            </Typography>
                            <FormRegistraResponsabilePerConsulente 
                                gruppoId={this.props.gruppoId}
                                consulenteId={this.props.consulenteId}
                                onFormModified={this.onFormModified} 
                                onFormSubmitted={this.onRecordSaved} 
                            />
                        </Fragment>
                    :
                        <FormModificaResponsabileSicurezza 
                            responsabileId={this.props.responsabileId}
                            consulenteId={this.props.consulenteId}
                            gruppoId={this.props.gruppoId}
                            superconsulenteView={this.props.superconsulenteView}
                            consulenteView={this.props.consulenteView}
                            onFormModified={this.onFormModified} 
                            onFormSubmitted={this.onChangesSaved}
                        />
                    }
                </Paper>
                : */}

        {/* <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            variant="fullWidth"
            style={styles.tabsbar}
            //centered
          >
            <Tab label="Dati responsabile punti vendita" value={0} />
            <Tab
              label="Punti vendita"
              value={1}
              disabled={secondTabDisabled}
              style={{
                backgroundColor: secondTabDisabled
                  ? theme.palette.disabled.main
                  : "transparent",
              }}
            />
          </Tabs> */}
        {/* <div hidden={this.state.tabValue !== 0}> */}
        <Box style={{ paddingTop: "20px" }}>
          {showRegistrationForm ? (
            <Fragment>
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.primary.main,
                  visibility: this.state.showRegistrationMessage
                    ? "inherit"
                    : "hidden",
                }}
              >
                Registrazione effettuata con successo.
              </Typography>
              <FormRegistraResponsabileSicurezza
                gruppoId={this.props.gruppoId}
                consulenteView={this.props.consulenteView}
                superconsulenteView={this.props.superconsulenteView}
                onFormModified={this.onFormModified}
                onFormSubmitted={this.onRecordSaved}
              />
            </Fragment>
          ) : null}
          {!showRegistrationForm ? (
            <Fragment>
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.primary.main,
                  visibility: this.state.showChangesSavedMessage
                    ? "inherit"
                    : "hidden",
                }}
              >
                Dati aggiornati con successo.
              </Typography>
              <FormModificaResponsabileSicurezza
                responsabileId={this.props.responsabileId}
                consulenteId={this.props.consulenteId}
                gruppoId={this.props.gruppoId}
                superconsulenteView={this.props.superconsulenteView}
                consulenteView={this.props.consulenteView}
                onFormModified={this.onFormModified}
                onFormSubmitted={this.onChangesSaved}
              />
            </Fragment>
          ) : null}
        </Box>
        {/* </div> */}
        {/* <div hidden={this.state.tabValue !== 1}>
            <Box style={{ paddingTop: "44px" }}>
              {this.state.tabValue === 1 && (
                <EsercizioXResponsabileComponent
                  responsabileId={this.props.responsabileId}
                  consulenteId={this.props.consulenteId}
                  gruppoId={this.props.gruppoId}
                  consulenteView={this.props.consulenteView}
                  superconsulenteView={this.props.superconsulenteView}
                />
              )}
            </Box>
          </div> */}
      </div>
    );
  }
}

DettaglioResponsabileSicurezza.propTypes = {
  responsabileId: PropTypes.string.isRequired,
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  consulenteView: PropTypes.bool.isRequired,
  superconsulenteView: PropTypes.bool.isRequired,
};
