import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from '@material-ui/core/Container';

import Moment from 'moment';
import validator from "validator";

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import { clearToken } from '../../../utils/storage';
import GiorniChiusuraEsercizio from "./GiorniChiusuraEsercizio";
import SelezioneGruppo from '../components/SelezioneGruppo';

import { registerEsercizioAsAdmin } from '../../../utils/api/esercizi_commerciali_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import theme from '../../../theme';
import { InputBase, MenuItem, Select, styled } from "@material-ui/core";
import { getConsulenti } from "../../../utils/api/consulenti_api";
import Background from '../../../Sfondo.png';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        paddingTop: '10px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '30px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    sectionSelectContainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '30px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        maxWidth:'700px',
        height:150,
    },
    selgruppoContainer : {
        border: '1px solid #e0e0e0',
        borderRadius: '30px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        maxWidth: '700px' 
    },
    fieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    }
}

export default class NuovoEsercizioCommerciale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                esercizio: {
                    id: null,
                    ragioneSociale: null,
                    rappresentanteLegale: null,
                    eMailRappresentanteLegale: null,
                    telefonoRappresentanteLegale: null,
                    indirizzoSede: null,
                    frazione: null,
                    comuneSede: null,
                    provinciaSede: null,
                    regione: null,
                    codiceMuro: null,
                    codiceSocio: null,
                    numeroAuditAnnui: null,
                    telefono:null,
                    fax:null,
                    eMailEsercizio:"",
                    consulenteId:null,
                    numeroSede: null,
                    cittaSede: null,
                    terminiUsoAccettati: null,
                    privacyPolicyAccettata: null,
                    accessKey:null,
                    gruppoId: 101,
                    codiceSdi: null,
                    pec: null,
                    responsabiliRegionali: null,
                    responsabileArea: null,
                    eMailResponsabileArea:"",
                    direttoreVendite: null,
                    direttore2023: null,
                    consulente2023: null,
                    consulente2022: null,
                    canale2023:null,
                },
                giorniChiusura: [],
                responsabiliSicurezza:[{
                    email:"",
                    password:"",
                }],
            },
            validationErrors: {
                ragioneSociale: '',
                rappresentanteLegale: '',
                eMailRappresentanteLegale: '',
                emailResponsabile:'',
                passwordResponsabile:'',
                doublePassoword:'',
                emailResponsabileArea:'',
                emailEsercizio:'',
            },
            gruppi: [],
            ripetiPassword: '',
            consulenti:[],
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            notfoundRecord: false,
            saveButtonEnabled: false,
            disabledButtons:false,
            saveMessageVisible: false,
            width:window.innerWidth,
            height:window.innerHeight,
        };
    }

    componentDidMount () {
        window.addEventListener('resize', this.updateDimensions);
        this.fetchGruppi();
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    fetchGruppi = () => {
        this.setState({
            loading: true
        });
        getGruppi(null, null, 0, 1000, "id")
        .then(result => {
            let record = this.state.record;
            let esercizio = record.esercizio;
            let gruppoId = esercizio.gruppoId;
            for (let i=0; i<result.length; i++) {
                if (gruppoId === null) {
                    if (result[i].nome === 'BSFOOD') {
                        gruppoId = result[i].id;
                        break;
                    }
                }
            }
            esercizio.gruppoId = gruppoId;
            record.esercizio = esercizio;
            getConsulenti(null,101,0,3000,"id").then((consulenti) => {
                this.setState({
                    loading: false,
                    gruppi: result,
                    consulenti: consulenti,
                    record
                });
            }).catch((error) => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                    });
                    this.handleError(true, error.message);
                } 
            })
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                });
                this.handleError(true, error.message);
            } 
        });
    }

    handleInvalidToken = () => {
        this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    updateParentState = (data) => {
        let record = this.state.record;
        record.giorniChiusura = data;
        this.setState({
            record: record, saveButtonEnabled: true
        })
    }

    handleConsulenteIdChange = (event) => {
        let record = this.state.record;
        record.esercizio.consulenteId = event.target.value;
        this.setState({
            record: record, 
            saveButtonEnabled: true
        })
    }

    handleEmailResponsabileChange = (event) => {
        let record = this.state.record;
        record.responsabiliSicurezza[0].email = event.target.value;
        this.setState({
            record: record, 
            saveButtonEnabled: true
        })
    }
    handleResponsabilePasswordChange = (event) => {
        let record = this.state.record;
        record.responsabiliSicurezza[0].password = event.target.value;
        this.setState({
            record: record, 
            saveButtonEnabled: true
        })
    }
    handleDoublePasswordChange = (event) => {
        this.setState({
            ripetiPassword: event.target.value
        })
    }

    validateForm = () => {
        let data = this.state.record.esercizio;
        let responsabileData = this.state.record.responsabiliSicurezza[0];
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        //Return false if there are validation errors:
        let valid = true;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "ragioneSociale":
                if (value === null || value.length === 0) {
                    validationErrors.ragioneSociale = "Inserire la ragione sociale del punto vendita";
                } else {
                    validationErrors.ragioneSociale = "";
                }
                break;
            case "eMailRappresentanteLegale":
                if (value === null || value.length === 0) {
                    validationErrors.eMailRappresentanteLegale = "Inserire email del rappresentante legale";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.eMailRappresentanteLegale = "";
                    } else {
                        validationErrors.eMailRappresentanteLegale = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "rappresentanteLegale":
                if (value === null || value.length === 0) {
                    validationErrors.rappresentanteLegale = "Inserire nome rappresentante legale";
                } else {
                    validationErrors.rappresentanteLegale = "";
                }
                break;
            case "email":
                if (value === null || value.length === 0) {
                    validationErrors.emailResponsabile = "Inserire email responsabile";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.emailResponsabile = "";
                    } else {
                        validationErrors.emailResponsabile = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "password":
                if (value === null || value.length === 0) {
                    validationErrors.passwordResponsabile = "Inserire password responsabile";
                }
                else if(value.length > 0 && value.length < 8){
                    validationErrors.passwordResponsabile = "La password deve essere lunga almeno 8 caratteri"
                }
                else if(value !== this.state.ripetiPassword){
                    validationErrors.passwordResponsabile = "La password inserita e la password di conferma non coincidono"
                }
                else{
                    validationErrors.passwordResponsabile = ""
                }
                break;
            case "eMailEsercizio":
                if (value.length > 0 || value === null){
                    if (validator.isEmail(value)) {
                        validationErrors.emailEsercizio = "";
                    } else {
                        validationErrors.emailEsercizio = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            case "eMailResponsabileArea":
                if (value.length > 0 || value === null){
                    if (validator.isEmail(value)) {
                        validationErrors.emailResponsabileArea = "";
                    } else {
                        validationErrors.emailResponsabileArea = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    handleChange = event => {
        this.handleFormChange(event.target.name, event.target.value);
    }

    handleSelectedGruppo = (gruppoId) => {
        this.handleFormChange("gruppoId", gruppoId);
    }

    handleError = (showModal, errorMessage) => {
        this.setState({
            errorDialogVisible: showModal,
            errorDialogMessage: errorMessage
        });
    }

    handleFormChange = (fielName, value) => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let data = this.state.record;
        data.esercizio[fielName] = value
        this.setState({
            record:data
        },() => {
            
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true
            });
            registerEsercizioAsAdmin(this.state.record)
            .then(result => {
                this.setState({
                    loading: false,
                    saveButtonEnabled: false,
                    disabledButtons: true,
                    saveMessageVisible: true
                });
            })
            .catch(error => {
                if (error.status === 403) {
                   this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.handleError(true, error.message);
                } 
            });
        }
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        Moment.locale('it-IT');
        const disabledSubmitbutton = this.state.loading || (!this.state.saveButtonEnabled) || this.state.saveMessageVisible;
        let textFieldsDisabled = this.state.saveMessageVisible;
        return (
            <div style={{
                  textAlign: 'center',
                    color: 'black',
                    paddingTop: '10px',
                    backgroundImage: this.state.width > 1160 ? `url("${Background}"),url("${Background}")` : `url(""),url("")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '',
                    backgroundAttachment: 'fixed',
                    backgroundPositionX: this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
            }} >
                <Typography variant="h4" >Nuovo Punto Vendita</Typography>
                <Grid container justify="center" style={{ marginTop: '26px', paddingBottom: '26px' }}>
                    <div style={{ minHeight: '500px' }} >

                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            : 
                            <form autoComplete="off" onSubmit={this.handleSubmit}>
                                <Container style={{ maxWidth: '700px',borderRadius:30 }}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                    <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati identificativi del punto vendita</Typography>
                                            <Grid container style={styles.fieldscontainer}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                    <TextField
                                                                    label="Ragione sociale PdV*"
                                                                    name="ragioneSociale"
                                                                    value={this.state.record.esercizio.ragioneSociale === null ? "" : this.state.record.esercizio.ragioneSociale}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.ragioneSociale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.ragioneSociale}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <TextField
                                                                    label="Socio/Legale rappresentante*"
                                                                    name="rappresentanteLegale"
                                                                    value={this.state.record.esercizio.rappresentanteLegale === null ? "" : this.state.record.esercizio.rappresentanteLegale}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.rappresentanteLegale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.rappresentanteLegale}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <TextField
                                                                    label="Email Legale rappresentante*"
                                                                    value={this.state.record.esercizio.eMailRappresentanteLegale === null ? "" : this.state.record.esercizio.eMailRappresentanteLegale}
                                                                    name="eMailRappresentanteLegale"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    error={this.state.validationErrors.eMailRappresentanteLegale.length > 0 ? true : false}
                                                                    helperText={this.state.validationErrors.eMailRappresentanteLegale}
                                                                    //helperText={this.state.validationErrors.nome}
                                                                    //error={this.state.validationErrors.nome.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Cell. Legale rappresentante"
                                                                    value={this.state.record.esercizio.telefonoRappresentanteLegale === null ? "" : this.state.record.esercizio.telefonoRappresentanteLegale}
                                                                    name="telefonoRappresentanteLegale"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    //helperText={this.state.validationErrors.ragioneSociale}
                                                                    //error={this.state.validationErrors.ragioneSociale.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Indirizzo"
                                                                    value={this.state.record.esercizio.indirizzoSede === null ? "" : this.state.record.esercizio.indirizzoSede}
                                                                    name="indirizzoSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Frazione"
                                                                    value={this.state.record.esercizio.frazione === null ? "" : this.state.record.esercizio.frazione}
                                                                    name="frazione"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Comune"
                                                                    value={this.state.record.esercizio.comuneSede === null ? "" : this.state.record.esercizio.comuneSede}
                                                                    name="comuneSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Provincia"
                                                                    value={this.state.record.esercizio.provinciaSede === null ? "" : this.state.record.esercizio.provinciaSede}
                                                                    name="provinciaSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Regione"
                                                                    value={this.state.record.esercizio.regione === null ? "" : this.state.record.esercizio.regione}
                                                                    name="regione"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice MURO"
                                                                    value={this.state.record.esercizio.codiceMuro === null ? "" : this.state.record.esercizio.codiceMuro}
                                                                    name="codiceMuro"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice socio"
                                                                    value={this.state.record.esercizio.codiceSocio === null ? "" : this.state.record.esercizio.codiceSocio}
                                                                    name="codiceSocio"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="N.Audit annuali"
                                                                    value={this.state.record.esercizio.numeroAuditAnnui === null ? "" : this.state.record.esercizio.numeroAuditAnnui}
                                                                    name="numeroAuditAnnui"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                    <TextField
                                                                    label="Telefono"
                                                                    value={this.state.record.esercizio.telefono === null ? "" : this.state.record.esercizio.telefono}
                                                                    name="telefono"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    //helperText={this.state.validationErrors.telefono}
                                                                    //error={this.state.validationErrors.emailGestore.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Fax"
                                                                    value={this.state.record.esercizio.fax === null ? "" : this.state.record.esercizio.fax}
                                                                    name="fax"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Email"
                                                                    value={this.state.record.esercizio.eMailEsercizio === null ? "" : this.state.record.esercizio.eMailEsercizio}
                                                                    name="eMailEsercizio"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.emailEsercizio}
                                                                    error={this.state.validationErrors.emailEsercizio.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Responsabili regionali"
                                                                    value={this.state.record.esercizio.responsabiliRegionali === null ? "" : this.state.record.esercizio.responsabiliRegionali}
                                                                    name="responsabiliRegionali"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />

                                                                <TextField
                                                                    label="Responsabili area"
                                                                    value={this.state.record.esercizio.responsabileArea === null ? "" : this.state.record.esercizio.responsabileArea}
                                                                    name="responsabileArea"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Email responsabile area"
                                                                    value={this.state.record.esercizio.eMailResponsabileArea === null ? "" : this.state.record.esercizio.eMailResponsabileArea}
                                                                    name="eMailResponsabileArea"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.emailResponsabileArea}
                                                                    error={this.state.validationErrors.emailResponsabileArea.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Direttore vendite"
                                                                    defaultValue={this.state.record.esercizio.direttoreVendite === null ? "" : this.state.record.esercizio.direttoreVendite}
                                                                    name="direttoreVendite"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Dir/Socio 2023"
                                                                    defaultValue={this.state.record.esercizio.direttore2023 === null ? "" : this.state.record.esercizio.direttore2023}
                                                                    name="direttore2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Consulente socio HACCP 2023"
                                                                    defaultValue={this.state.record.esercizio.consulente2023 === null ? "" : this.state.record.esercizio.consulente2023}
                                                                    name="consulente2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Canale 2023"
                                                                    defaultValue={this.state.record.esercizio.canale2023 === null ? "" : this.state.record.esercizio.canale2023}
                                                                    name="canale2023"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Consulente socio HACCP 2022"
                                                                    defaultValue={this.state.record.esercizio.consulente2022 === null ? "" : this.state.record.esercizio.consulente2022}
                                                                    name="consulente2022"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                     
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Container>
                                {/*<Container>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={7} style={styles.sectionSelectContainer}>
                                    <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Selezionare il consulente associato al nuovo punto vendita</Typography>
                                    <Select
                                    style={{width:'75%'}}
                                    variant="outlined"
                                    defaultValue={null}
                                    displayEmpty={true}
                                    input={<BootstrapInput></BootstrapInput>}
                                    value={this.state.record.esercizio.consulenteId}
                                    onChange={this.handleConsulenteIdChange}
                                    >
                                        <MenuItem key={0} value={null}>Nessun consulente</MenuItem>
                                        {this.state.consulenti.map((consulente,index) => (
                                            <MenuItem key={index+1} value={consulente.id}>{consulente.email}</MenuItem>
                                        ))}
                                    </Select>
                                    </Grid>
                                    </Grid>
                                </Container>*/}
                                {/*<Container>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={7} style={styles.sectioncontainer}>
                                    <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Aggiungi responsabile del punto vendita</Typography>
                                    <Grid container style={styles.fieldscontainer}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                    <TextField
                                                                    label="Email*"
                                                                    value={this.state.record.responsabiliSicurezza[0].email === null ? "" : this.state.record.responsabiliSicurezza[0].email}
                                                                    name="emailResponsabile"
                                                                    onChange={this.handleEmailResponsabileChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.emailResponsabile}
                                                                    error={this.state.validationErrors.emailResponsabile.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Ripeti Password*"
                                                                    value={this.state.ripetiPassword === null ? "" : this.state.ripetiPassword}
                                                                    name="doublePassword"
                                                                    type="password"
                                                                    onChange={this.handleDoublePasswordChange}
                                                                    style={styles.textfield}
                                                                />
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px' }} >
                                                    <TextField
                                                                    label="Password*"
                                                                    value={this.state.record.responsabiliSicurezza[0].password === null ? "" : this.state.record.responsabiliSicurezza[0].password}
                                                                    name="passwordResponsabile"
                                                                    type="password"
                                                                    onChange={this.handleResponsabilePasswordChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.passwordResponsabile}
                                                                    error={this.state.validationErrors.passwordResponsabile.length > 0 ? true : false}
                                                                />
                                                    </Grid>
                                                    </Grid>
                                                    </Grid>
                                    </Grid>
                                    </Grid>
                                </Container>*/}
                                {/*<Container style={styles.selgruppoContainer}>
                                    <Typography variant="h6" style={styles.text} >Dati gruppo</Typography>
                                    <SelezioneGruppo
                                        gruppoId={this.state.record.esercizio.gruppoId}
                                        gruppi={this.state.gruppi}
                                        disabled={textFieldsDisabled}
                                        description="Scegliere il gruppo di appartenenza del nuovo esercizio commerciale."
                                        onGruppoSelected={this.handleSelectedGruppo}
                                        onGruppoAdded={this.fetchGruppi}
                                        onError={this.handleError}
                                        onAuthError={this.handleInvalidToken}
                                    />
                                </Container>*/}

                                <GiorniChiusuraEsercizio
                                    esercizioId={this.state.record.esercizio.id}
                                    giorniChiusura={this.state.record.giorniChiusura}
                                    updateParentState={this.updateParentState}
                                    disabledButtons={this.state.disabledButtons}
                                />
                                <Grid container justify="center" style={{ paddingTop: '10px' }} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }} >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: theme.palette.primary.main,
                                                visibility: this.state.saveMessageVisible ? 'inherit' : 'hidden'
                                            }}
                                        >
                                            Nuovo punto vendita registrato con successo.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                        <IndietroButton isDisabled={this.state.loading} />
                                        <Button
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            variant="contained"
                                            size="medium"
                                            disabled={disabledSubmitbutton}
                                            style={{
                                                color: theme.palette.secondary.main,
                                                margin: '10px',
                                                borderRadius:30,
                                                backgroundColor: disabledSubmitbutton ? theme.palette.disabled.main : theme.palette.cndColors.yellow,
                                            }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        }

                    </div>
                </Grid>

                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>

            </div>
        );
    }

}