import { Component } from "react";
import React from "react";
import Modal from '@material-ui/core/Modal';
import {InputBase, Typography, styled } from "@material-ui/core";
import {MenuItem, Select } from '@material-ui/core';
import ActionButton from "../../../components/ActionButton";

const styles = {
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '200px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #ff4600, #f7a616,#ff4600) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      padding: "1em 2em 1em 1em",
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }));

export default class AggiuntaSottoparagrafoModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            tipologiaItem:"Testo",
        }
    }
    render(){
    return(
    <Modal
            open={this.props.open}
            onClose={this.props.onClose}
            >
            <div style={styles.modalcontainer}>
                    <div style={styles.modalsectioncontainer}>
                    <Typography style={styles.modalTitle}>Selezionare la tipologia di sottoparagrafo da inserire:</Typography>
                    <Select style={{marginTop:"5%"}} 
                    input={<BootstrapInput></BootstrapInput>}
                    value = {this.state.tipologiaItem} onChange={(e) => {
                        this.setState({
                            tipologiaItem: e.target.value,
                        })
                    }}>
                        <MenuItem value="Testo">Testo</MenuItem>
                        <MenuItem value="Tabella">Tabella</MenuItem>
                    </Select>
                    <div style={styles.buttoncontainer}>
                        <ActionButton disabled={false} grayVersion={false} label="Conferma" onClick = {() => {
                            this.props.addSottoparagrafo(this.props.paragrafo,this.props.index,this.props.index2,this.props.index3,this.state.tipologiaItem);
                        }}></ActionButton>
                        <ActionButton disabled={false} grayVersion={false} label="Indietro" onClick = {this.props.onClose}></ActionButton>
                    </div>
                    </div>
            </div>

    </Modal>
    )}
}