import React from "react";
import { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import ActionButton from "../../../components/ActionButton";

const styles = {
    mainContainer: {
      textAlign: "center",
      color: "black",
      padding: "26px 10px",
      minHeight: "90vh",
    },
    typo: {
      align: "center",
    },
    root: {
      flexGrow: 1,
      display: "flex",
    },
    buttonContainer: {},
    modalcontainer: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
      textAlign: "center",
      backgroundColor: "white",
      color: "black",
      //paddingTop: '26px',
      //paddingBottom: '26px',
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "10px",
      paddingRight: "10px",
      marginTop: "26px",
      marginBottom: "26px",
      boxShadow:
        "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
      marginLeft: "20%",
      marginRight: "20%",
      //height: '85%'
      overflowY: "scroll",
      height: "600px",
    },
    textfield: {
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "12px",
      width: "250px",
    },
    sectioncontainer: {
      // border: '1px solid #e0e0e0',
      //borderRadius: '6px',
      //marginTop: '10px',
      marginBottom: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    rowcontainer: {
      flexDirection: "row",
    },
    modalsectioncontainer: {
      flex: 1,
      display: "flex",
      //marginTop: 20,
      //marginBottom: 20,
      flexDirection: "column",
      justifyContent: "space-evely",
    },
    periodo: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
  
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    tableModalText: {
      marginTop: "2%",
      marginBottom: "2%",
    },
    notSelectedTab:{
      display:"flex",
      flexDirection:"row"
    },
    selectedTab:{
      display:"flex",
      flexDirection:"row",
    }
  };

export default class ModalCreazioneSottoparagrafoTabella extends Component{
    constructor(props){
        super(props);
        this.state = {
           colonneTabellaCustom:[],
        }
    }
    render(){
        return(
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                Inserire il nome della tabella che si vuole creare
              </Typography>
              {/*<Typography variant="h6" style={{ paddingTop: '8px', paddingBottom: '8px' }} >
                                Tipologia Di Tabella
                        </Typography>
                        <Select
                        style={{alignSelf:"center",width:500}}
                        value={this.state.tipoDiTabella}
                              name="tipoDiTabella"
                              onChange={this.handleChangeTipo}
                        >
                          <MenuItem value={'Custom'}>Personalizzato</MenuItem>
                        </Select>*/}
              <Typography variant="h6" style={styles.tableModalText}>
                Cliccare su "Aggiungi colonna" per aggiungere una colonna alla
                tabella
              </Typography>
              <Box style={{ marginBottom: "2%" }}>
                {this.state.colonneTabellaCustom.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>Nome colonna: </Typography>
                      <TextField
                        style={{ marginLeft: 10, marginTop: -2, width: "50%" }}
                        onChange={(e) => {
                          let columnName = e.target.value;
                          if (columnName === "") {
                            columnName = " ";
                          }
                          let array = this.state.colonneTabellaCustom;
                          array[index].header = columnName;
                          this.setState({
                            colonneTabellaCustom: array,
                          });
                        }}
                        value={item.header}
                      ></TextField>
                      <IconButton
                        size="small"
                        style={{ marginTop: 0 }}
                        onClick={() => {
                          let columnArray = [
                            ...this.state.colonneTabellaCustom,
                          ];
                          columnArray.splice(index, 1);
                          this.setState(
                            {
                              colonneTabellaCustom: columnArray,
                            },
                            () => {
                              
                            }
                          );
                        }}
                      >
                        <CloseIcon></CloseIcon>
                      </IconButton>
                    </div>
                  );
                })}
              </Box>
              <ActionButton
                grayVersion={false}
                disabled={false}
                label={"Aggiungi colonna"}
                onClick={() => {
                  if (this.state.colonneTabellaCustom.length < 16) {
                    let updateColumn = [...this.state.colonneTabellaCustom];
                    let valueToSave = this.state.colonneTabellaCustom.length;
                    updateColumn.push({
                      header: "Colonna " + valueToSave++,
                      id: "Colonna" + valueToSave++,
                    });
                    this.setState({
                      colonneTabellaCustom: updateColumn,
                    });
                  } else {
                    alert("Errore, sono state inserite più di 16 colonne");
                  }
                }}
              ></ActionButton>

              <Typography
                variant="h6"
                style={{ paddingTop: "10%", paddingBottom: "8px" }}
              ></Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButton
                grayVersion={false}
                disabled={false}
                onClick={() => {this.props.handleAggiuntaSottoparagrafoTabella(this.state.colonneTabellaCustom)}}
                label="Aggiungi Tabella"
              ></ActionButton>
            </div>
          </div>
        </Modal>
        )
    }
}