import React from "react";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ActionButton from "../../../components/ActionButton";

import PropTypes from 'prop-types';

const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        marginTop: '20px',
        marginBottom: '20px',
        minHeight: '296px',
    },
    text: {
        margin: '5px 0px 10px 0px',
    },
    gridContainer: {
        paddingTop:'10px'
    },
    gridElement: {
        padding:'10px 0px',
    },
    paper: {
        padding: '12px',
        boxShadow: '2px 2px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 2px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
    },
    image: {
        //width: 146,
        height: 146,
        margin: 'auto',
        display: 'block',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}

export default function LoghiBrand (props) {

    const URL = process.env.REACT_APP_BACKEND_ENDPOINT;

    return(
        <Container maxWidth='md' style={styles.root}>
            <Typography variant="h6" style={styles.text}>
                Loghi del brand
            </Typography>
            <Typography variant="body1" style={styles.text}>
                I seguenti loghi saranno mostrati sulla prima pagina dei manuali in formato PDF generati attraverso la piattaforma BS-CONAD.
            </Typography>
            <Grid container justify="center" alignItems="center" style={styles.gridContainer} >
                { props.loading ?
                <SpinnerComponent size={32} />
                :   
                ( props.loghiIds.map((id, index) => { 
                    return (
                    <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.gridElement} >
                        <Paper style={styles.paper} >
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <div style={styles.image}>
                                        <img style={styles.img} alt="logo_brand" src={URL+ "/api/loghi/" + id} />
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <ActionButton 
                                        label="Rimuovi logo"
                                        disabled={false}
                                        onClick={(e) => {props.onRimuoviLogo(id, e)}}
                                        grayVersion={false}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    );
                }) )
            }  
            </Grid>
            <Grid container justify="center" style={{ paddingTop:'10px', paddingBottom:'10px' }} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <ActionButton 
                        label="Salva modifiche"
                        disabled={props.submitButtonDisabled || props.loading}
                        onClick={props.onSubmit}
                        grayVersion={false}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

LoghiBrand.propTypes = {
    loading: PropTypes.bool.isRequired,
    loghiIds: PropTypes.array.isRequired,
    onRimuoviLogo: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitButtonDisabled: PropTypes.bool.isRequired,
}