import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import PropTypes from 'prop-types';
import theme from '../theme.js';
import { Typography } from "@material-ui/core";

const styles = {
    div_spinner: {
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    spinner: {
        color: theme.palette.cndColors.yellow
    },
}

export default function SpinnerComponentWithText (props) {
    return(
        <div style={styles.div_spinner}>
            <CircularProgress
                style={styles.spinner}
                size={props.size}
            />
            <Typography>Caricamento {props.isModello ? "modello" : "manuale"} in corso...</Typography>
        </div>
    );
}