import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../../components/ActionButton'
import Background from '../../Sfondo.png';
const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        height:'100vh',
        padding: '10px',
        backgroundImage: `url("${Background}"),url("${Background}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '',
        backgroundAttachment: 'fixed',
        backgroundPositionX: "140%, -40%",
    }
}

export default class LoginResult extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            width:window.innerWidth,
            height:window.innerHeight,
        }
    }

    goBackToForm = (event) => {
        event.preventDefault();
        this.props.onBackButtonPressed();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        if (!this.props.hasError) {
            window.setTimeout(function () {
                window.location.href = "/";
            }, 4000);
        }
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    render() {
        const message = this.props.message;
        const hasError = this.props.hasError
        return (
            <div style={{
                   textAlign: 'center',
                    color: 'black',
                    height:'100vh',
                    padding: '10px',
                    backgroundImage: this.state.width > 1180 ? `url("${Background}"),url("${Background}")` : `url(""),url("")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '',
                    backgroundAttachment: 'fixed',
                    backgroundPositionX:  this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
            }}>
                <p 
                style={{ fontSize: "20px" }} >
                    {message}
                </p>
                <div style={{ textAlign: 'center' }} >
                    {hasError ? 
                    <ActionButton
                        onClick={this.goBackToForm}
                        label="Indietro"
                        disabled={false}
                        grayVersion={true}
                    />
                    : 
                    <a href="/" >Stai per essere reinderizzato alla home page</a>
                    }
                </div>
            </div>
        );
    }

}

LoginResult.propTypes = {
    hasError: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onBackButtonPressed: PropTypes.func.isRequired
}