import React from "react";
import { Checkbox, Container, Grid, IconButton, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import theme from "../../../theme";

const styles = {
    mainContainer: {
      textAlign: "center",
      color: "black",
      padding: "26px 10px",
      minHeight: "90vh",
    },
    typo: {
        align: "center",
    },
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '600px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

export default class ModalSelezioneAssociazioneScheda extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            records : [],
            columns : [],
            isButtonsAllChecked: true,
        }
    }
    searchForIdReparto = (name) => {
        let a = null;
        this.props.reparti.map(item => {
            if(item.nome === name){
               a = item.id;
            }
        })
        return a;
    }
 
    componentDidMount = () => {
        let a = [];
        if(this.props.mode === "Attrezzature"){
            let attrezzatureRecords = [];
            this.props.attrezzature.map((item) => {
                let newRecord = {
                    checked:true,
                    nomeReparto: item.nomeReparto,
                    nomeLocale: item.nomeLocale,
                    id:item.id,
                    idTipologia:item.tipologiaStrumentazioneId,
                    nomeElemento:item.nome
                }
                let idToAdd = this.searchForIdReparto(item.nomeReparto);
                newRecord["repartoId"] = idToAdd;

                attrezzatureRecords.push(newRecord);
            })
            this.setState({
            records : attrezzatureRecords,
            isButtonsAllChecked:true,
            },() => {
                this.checkIfAllAreSelected();
            })
            a = this.getColumnTabellaAttrezzature();
        }else{
            let repartiRecords = [];
            this.props.reparti.map((item,index) => {
                let newRecord = {};
                if(!item.repartoDefault){
                if(this.props.schedaData[0].reparti.includes(item.id)){
                    newRecord = {
                        nomeReparto: item.nome,
                        checked: true,
                        idReparto: item.id,
                        repartoDefault:false,
                    }
                }else{
                    newRecord = {
                        nomeReparto: item.nome,
                        checked: false,
                        idReparto: item.id,
                        repartoDefault:false,
                    }
                }
                repartiRecords.push(newRecord);
                }if(this.props.reparti.length === 1){
                    //console.log(this.props.reparti[0]);
                    let newRecord = {
                        nomeReparto:this.props.reparti[0].nome,
                        checked:true,
                        idReparto:this.props.reparti[0].id,
                        repartoDefault:true,
                    }
                    repartiRecords.push(newRecord);
                }
            })
            this.setState({
                records : repartiRecords,
                isButtonsAllChecked:true,
            },() => {
                this.checkIfAllAreSelected();
            })
            a = this.getColumnTabellaReparti();
        }
        this.setState({
            columns : a
        })
    }

    getColumnTabellaReparti = () => {
        return [{
            Header:"Reparto",
            id: "Reparto",
            width:250,
            accessor:d => d,
            Cell: props => <TextField contentEditable={false} style={{margin:10}} InputProps={{ disableUnderline: true }} 
            inputProps={{min: 0, style: { textAlign: 'center' }}}  value = {props.row.Reparto.nomeReparto}></TextField>
        },{
            Header:"Associa",
            id: "Associa",
            width:250,
            accessor:d => d,
            Cell: props => <Checkbox onClick={() => {
                let array = [...this.state.records];
                array[props.index].checked =!array[props.index].checked;
                this.setState({
                 records:array,
                 isButtonsAllChecked:true,
                },() => {
                    this.checkIfAllAreSelected();
                })
            }} checked={props.row.Reparto.checked} disabled={props.row.Associa.repartoDefault} style={{ margin:10,
                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                }}}></Checkbox>
        }]
    }

    getColumnTabellaAttrezzature = () => {
        return [{
            Header:"Elemento Osservato",
            id: "ElementoOsservato",
            width:250,
            accessor:d => d.nomeElemento,
            Cell: props => <TextField contentEditable={false} style={{margin:10}} InputProps={{ disableUnderline: true }} 
            inputProps={{min: 0, style: { textAlign: 'center' }}}  value = {props.row.ElementoOsservato}></TextField>
        },{
            Header:"Reparto",
            id: "Reparto",
            width:250,
            accessor:d => d.nomeReparto,
            Cell: props => <TextField contentEditable={false} style={{margin:10}} InputProps={{ disableUnderline: true }} 
            inputProps={{min: 0, style: { textAlign: 'center' }}}  value = {props.row.Reparto}></TextField>
        },{
            Header:"Locale",
            id: "Locale",
            width:250,
            accessor:d => d.nomeLocale,
            Cell: props => <TextField contentEditable={false} style={{margin:10}} InputProps={{ disableUnderline: true }} 
            inputProps={{min: 0, style: { textAlign: 'center' }}}  value = {props.row.Locale}></TextField>
        },{
            Header:"Associa",
            id: "Associa",
            width:250,
            accessor:d => d,
            Cell: props => <Checkbox onClick={() => {
                let array = [...this.state.records];
                array[props.index].checked =!array[props.index].checked;
                this.setState({
                 records:array,
                 isButtonsAllChecked:true,
                },() => this.checkIfAllAreSelected())
            }} checked={props.row.Associa.checked} style={{ margin:10,
                color: theme.palette.cndColors.yellow,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.cndColors.yellow,
                                                                }}}></Checkbox>
        }]
    }
    selectAll = () => {
        let array = [...this.state.records];
        if(array.length !== 0 && !array[0].repartoDefault){
        if(this.state.isButtonsAllChecked === true){
            array.map((item) => {
                item.checked = false;
            })
        }
        else{
            array.map((item) => {
                item.checked = true;
            })
        }
        this.setState({
            records:array,
            isButtonsAllChecked:!this.state.isButtonsAllChecked
        })
        }
    }
    checkIfAllAreSelected = () => {
        let array = [...this.state.records];
        array.map((item) => {
            if(!item.checked){
              this.setState({
                isButtonsAllChecked:false,
              })
            }
        })
    }

    render(){
        return(
            <Modal 
            open={this.props.open}
            onClose={this.props.onClose}>
                <div style={styles.modalcontainer}>
                <div style={styles.modalsectioncontainer}>
                {this.props.mode === "Attrezzature" ?
                <Typography style={{ align: "center",fontSize:30}}>Attrezzature</Typography> :
                <Typography style={{ align: "center",fontSize:30}}>Reparti</Typography>
                }
                <ActionButton  disabled={false} grayVersion={false} label={this.state.isButtonsAllChecked ? "Deseleziona tutto" : "Seleziona tutto"} onClick = {this.selectAll}></ActionButton>
                    <ReactTable
                    filterable={false}
                    resizable={true}
                    enableEditing={true}
                    showPageSizeOptions={false}
                    showPageJump={true}
                    defaultPageSize={5}
                        data={this.state.records}
                        columns={this.state.columns}
                    >

                    </ReactTable>
                </div>
                {this.props.mode === "Attrezzature" ? <ActionButton label="Salva" onClick = {() => this.props.saveValues(this.state.records,"Attrezzatura")}></ActionButton> : 
                <ActionButton  disabled={false} grayVersion={false} label="Salva" onClick = {() => this.props.saveValues(this.state.records,"Reparto")}></ActionButton>}
                </div>
            </Modal>
        )
    }
}