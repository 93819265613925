import React, { Component } from 'react';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { retrieveToken } from '../utils/storage.js';
import logo from '../logo.png';
import '../App.css';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme.js';

const styles = {
  logo: {
    height: '50px',
    pointerEvents: 'none'
  },
  button: {
    background: theme.palette.secondary.main,
    borderRadius: '6px',
    border: 0,
    color: theme.palette.cndColors.yellow,
    height: 48,
    fontWeight: 'bold',
  },
  list: {
    width: 250,
  },
};

export default class CustomHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      logged: false,
    };

  };

  componentDidMount() {
    const token = retrieveToken();
    if (token !== null) {
      this.setState({
        logged: true
      });
    } //else page does not display a content, only the header appears

  }

  toggleDrawer = (open) => {
    this.setState({
      isDrawerOpen: open
    });
  };

  render() {

    let isDrawerOpen = this.state.isDrawerOpen;

    let homeButton = (
      <NavLink to="/" style={{ textDecoration: 'none' }}>
        <Button style={styles.button}>Home</Button>
      </NavLink>
    );

    let loginButton = this.state.logged ? (
      <NavLink to="/logout" style={{ textDecoration: 'none' }}>
        <Button style={styles.button}>Logout</Button>
      </NavLink>
    ) : null;

    let drawerHomeButton = (
      <NavLink to="/" style={{ textDecoration: 'none', color: theme.palette.primary.main }} >
        <ListItem button key="Home" >
          <ListItemText primary="Home" />
        </ListItem>
      </NavLink>
    );

    let drawerLoginButton = this.state.logged ? (
      <NavLink to="/logout" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
        <ListItem button key="Logout">
          <ListItemText primary="Logout" />
        </ListItem>
      </NavLink>
    ) : null;

    let sideList =
      <div
        style={styles.list}
        role="presentation"
        onClick={() => { this.toggleDrawer(false) }}
        onKeyDown={() => { this.toggleDrawer(false) }}
      >
        <List>
          {drawerHomeButton}
          {drawerLoginButton}
        </List>
      </div>;

    /**
     * <AppBar> is a component of material-ui library.
     * The top App Bar provides content and actions related to the current screen. It’s used for branding, screen titles, navigation, and actions.
     */
    return (
      <div>
        <ThemeProvider theme={theme}>
        <MediaQuery minWidth={1000}>
          <div style={{ flexGrow: 1, }}>
            <AppBar style={{ backgroundColor: theme.palette.secondary.main}} position="static" >
              <Toolbar>
                <img src={logo} style={styles.logo} alt="logo" />
                <div style={{ flexGrow: 1, }}></div>
                {homeButton}
                {loginButton}
              </Toolbar>
            </AppBar>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={1000}>
          <div style={{ flexGrow: 1, }} >
            <AppBar position="static" style={{ backgroundColor: theme.palette.secondary.main }}>
              <Toolbar>
                <img src={logo} style={styles.logo} alt="logo" />
                <div style={{ flexGrow: 1, }}></div>
                <IconButton aria-haspopup="true" onClick={() => { this.toggleDrawer(true) }}>
                  <MenuIcon style={{ color: theme.palette.primary.main }} />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={isDrawerOpen}
                  onClose={() => { this.toggleDrawer(false) }}
                >
                  {sideList}
                </Drawer>
              </Toolbar>
            </AppBar>
          </div>
        </MediaQuery>
        </ThemeProvider>
      </div>
    );
  }

}