import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import theme from "../../../theme.js";

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {" "}
          <CloseIcon />{" "}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(4),
  },
}))(MuiDialogActions);

export default function ModalAvvisoResponsabile(props) {
  return (
    <Dialog open={props.open} onClose={props.onIndietroSelected}>
      <DialogTitle>ATTENZIONE</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* Uno o più responsabili del punto vendita scelto non risultano associati ad un reparto. */}
          Il punto vendita scelto risulta non associato ad alcun responsabile.
        </DialogContentText>
        <DialogContentText>
          {/* Senza un reparto assegnato, il responsabile del punto vendita non potrà accedere ai controlli da effettuare tramite l'app mobile. */}
          Per procedere con la pubblicazione del manuale è necessario associare
          un responsabile al punto vendita scelto.
        </DialogContentText>
        <DialogContentText>
          {/* Per sospendere l'operazione di pubblicazione e correggere il contenuto del paragrafo di definizione dei reparti cliccare su Indietro.
            Per pubblicare il manuale ignorando questo messaggio cliccare su "Pubblica comunque". */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onIndietroSelected}
          style={{ color: theme.palette.primary.main }}
        >
          Indietro
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAvvisoResponsabile.propTypes = {
  open: PropTypes.bool.isRequired,
  onIndietroSelected: PropTypes.func.isRequired,
};
