import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import theme from '../../theme.js';
import image from '../../BS_FOOD_logo.png';
import conadFront from '../../conadFront.png';
import conadBack from '../../conadBack.png';
import Background from '../../Sfondo.png';
import { retrieveToken } from '../../utils/storage.js';

const styles = {
    loginButton: {
        backgroundColor: theme.palette.cndColors.yellow,
        borderRadius: '30px',
        border: 0,
        color: theme.palette.secondary.main,
        height: 54,
        width: 104,
        fontSize: 16,
        fontWeight: 'bold',
    },
    controlpanelButton: {
        backgroundColor: theme.palette.cndColors.yellow,
        borderRadius: 30,
        border: 0,
        color: theme.palette.secondary.main,
        height: 68,
        width: 234,
        fontSize: 16,
        fontWeight: 'bold',
    }
}

export default class HomePage extends Component {

    constructor() {
        super();
        this.state = {
            isAuthenticated: false,
            message: 'Effettua il Login per gestire la piattaforma.',
            img: image,
            titleImg: 'BS_FOOD_logo',
            width:window.innerWidth,
            height:window.innerHeight,
        };
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidMount() {
        const token = retrieveToken();
        window.addEventListener('resize', this.updateDimensions);
        if (token !== null) {
            this.setState({
                isAuthenticated: true,
                message: 'Clicca sul pulsante per procedere'
            });
        } else {
            this.setState({
                isAuthenticated: false,
                message: 'Effettua il Login per gestire la piattaforma.'
            });
        }
    }

    render() {

        var accessButton = this.state.isAuthenticated ? (
            <NavLink to="/amministrazione/responsabili_sicurezza" style={{ textDecoration: 'none' }}>
                <Button variant="contained" style={styles.controlpanelButton}>Pannello Controllo</Button>
            </NavLink>
        ) : (
                <NavLink to="/login" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" style={styles.loginButton}>Login</Button>
                </NavLink>
            );
        var msg = this.state.message;

        return (
            <React.Fragment>
                <CssBaseline />
                <Container id='home_container' fixed style={{
                    display:"flex",
                    minWidth:"100%",
                    minHeight:"85vh",
                    backgroundImage:  this.state.width > 1320 ? `url("${Background}"),url("${Background}")` : `url(""),url("")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '',
                    backgroundAttachment: 'fixed',
                    backgroundPositionX: this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
                }} >
                {/*<img src={Background} alt='' style={{marginLeft: "-20%"}}></img>*/}
                    <Typography component="div" style={{display:"flex",flexDirection:"column",flex:1 }} >
                        <Typography component="div" style={{ paddingTop: '10px', paddingBottom: '10px',flex:1 }} >
                            <Typography variant="h4" style={{ padding: '20px',fontSize:40,fontWeight:'bold', color:'black' }} >
                                Pannello di controllo di Conad
                            </Typography>
                        </Typography>
                        <div style={{flex:2}}>
                        <img src={this.state.img} alt={this.state.titleImg} style={{width:'500px'}} />
                        </div>
                        <Typography component="div" style={{ marginTop: '20px', marginBottom: '20px' }} >
                            <Typography variant="h6" style={{ padding: '10px', color:theme.palette.cndColors.yellow  }} >
                                {msg}
                            </Typography>
                            <Typography component="div" style={{ padding: '10px' }} >
                                {accessButton}
                            </Typography>
                        </Typography>
                        <div style={{flex:1}}>

                        </div>
                    </Typography>
                    {/*<img src={Background} style={{marginRight: "-20%"}} alt=''></img>*/}
                </Container>
            </React.Fragment>
        );
    }

}