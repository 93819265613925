import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { retrieveToken } from '../utils/storage.js';

export default function withAuthentication(ComponentToProtect) {

  return class extends Component {

    constructor() {
      super();
      this.state = {
        isAuthenticated: false,
        redirect: false,
      };
    }

    componentDidMount() {
      const token= retrieveToken();
      if (token !== null){
        this.setState({ isAuthenticated: true });
      }
    }
   
    render() {
      const { isAuthenticated, redirect } = this.state;
      if (!isAuthenticated) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
    
  }

}