import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getProceduraHaccpTramiteId = (id) => {
  const PATH = "/api/paragrafi/procedurehaccp/"+id; 
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}