import React from 'react';
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

import theme from '../theme.js';

export default function IndietroButton(props) {

    let history = useHistory();
    return (
        <Button
            onClick={() => {
                if (props.alert === true) {
                    var r = window.confirm("Modifiche non salvate, andare indietro?");
                    if (r === true) {
                        history.goBack();
                    }
                }
                else { history.goBack(); }
            }}
            variant="contained"
            className="float-right"
            size="medium"
            disabled={props.isDisabled}
            style={{
                color: 'white', 
                margin: '10px',
                borderRadius:30,
                backgroundColor: props.isDisabled ? theme.palette.disabled.main : theme.palette.cndColors.red
            }}
        >
            { props.label === null || props.label === undefined ? "Indietro" : props.label }
        </Button>
    );

}

IndietroButton.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    alert: PropTypes.bool,
    label: PropTypes.string
}