import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const salvataggioCodiceValidazione = (idEsercizio,body) => {
    const PATH = "/api/responsabili_sicurezza/genera_password_validazione/"+idEsercizio;
    const URL = BASE_URL + PATH;
    const errorFunction = (status) => {
        let message = null;
        if (status === 401 || status === 403 || status === 404) {
          message = 'Errore invio codice di validazione.';
        } else if (status === 400) {
          message = 'Richiesta non valida.';
        }
        return new ApiError(status, message);
      };
    return wrapFetch(
        fetch(URL, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }), errorFunction
      );
}