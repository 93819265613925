import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const uploadLogoImage = (file) => {
    const PATH = "/api/loghi/"; 
    const URL = BASE_URL + PATH;
    let formData = new FormData();
    formData.append("file", file);
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        } else if (status === 400 || status === 413) {
            message = "Caricamento fallito: il formato dell'immagine o la dimensione dell'immagine non sono corretti.";
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'POST',
            body: formData
        }), errorFunction
    );
}

export const updateLogo = (id, nome) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = '/api/loghi';
    const URL = BASE_URL + PATH;
    const requestBody = {
        id,
        nomeFile: nome
    };
    const errorFunction = (status) => {
      let message = null;
      if (status === 403) {
        message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
      } else if (status === 400 || status === 404) {
        // Status 404 if id does not correspond to a registered Logo.
        message = 'Aggiornamento logo non riuscito: i dati non sono corretti.';
      }
      return new ApiError(status, message);
    };
    return wrapFetch(
      fetch(URL, {
        method: 'PUT',
        headers: {
            'Authorization' : authtoken,
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(requestBody)
      }), errorFunction
    );
}