import React from "react";
import { Container, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    border: `1px solid ${theme.palette.disabled.main}`,
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
}));

const DatiEsercizioCommerciale = ({
  record,
  handleChange,
  validationErrors,
}) => {
  const classes = useStyles();

  return (
    <Container style={{ maxWidth: "700px" }}>
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.sectionContainer}
        >
          <Typography
            variant="h5"
            style={{
              paddingTop: "6px",
              paddingBottom: "6px",
            }}
          >
            Dati identificativi del punto vendita
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Ragione sociale PdV*"
                  name="ragioneSociale"
                  value={record.esercizio.ragioneSociale || ""}
                  className={classes.textfield}
                  onChange={handleChange}
                  helperText={validationErrors.ragioneSociale}
                  error={validationErrors.ragioneSociale.length > 0}
                />
                <TextField
                  label="Socio/Legale rappresentante*"
                  name="rappresentanteLegale"
                  value={record.esercizio.rappresentanteLegale || ""}
                  className={classes.textfield}
                  onChange={handleChange}
                  helperText={validationErrors.rappresentanteLegale}
                  error={validationErrors.rappresentanteLegale.length > 0}
                />
                <TextField
                  label="Email Legale rappresentante*"
                  value={record.esercizio.eMailRappresentanteLegale || ""}
                  name="eMailRappresentanteLegale"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.eMailRappresentanteLegale}
                  error={validationErrors.eMailRappresentanteLegale.length > 0}
                />
                <TextField
                  label="Cell. Legale rappresentante"
                  value={record.esercizio.telefonoRappresentanteLegale || ""}
                  name="telefonoRappresentanteLegale"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Indirizzo"
                  value={record.esercizio.indirizzoSede || ""}
                  name="indirizzoSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Frazione"
                  value={record.esercizio.frazione || ""}
                  name="frazione"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Comune"
                  value={record.esercizio.comuneSede || ""}
                  name="comuneSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Provincia"
                  value={record.esercizio.provinciaSede || ""}
                  name="provinciaSede"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Regione"
                  value={record.esercizio.regione || ""}
                  name="regione"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Codice MURO"
                  value={record.esercizio.codiceMuro || ""}
                  name="codiceMuro"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Codice socio"
                  value={record.esercizio.codiceSocio || ""}
                  name="codiceSocio"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="N.Audit annuali"
                  value={record.esercizio.numeroAuditAnnui || ""}
                  name="numeroAuditAnnui"
                  onChange={handleChange}
                  className={classes.textfield}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ paddingBottom: "10px" }}
              >
                <TextField
                  label="Telefono"
                  value={record.esercizio.telefono || ""}
                  name="telefono"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Fax"
                  value={record.esercizio.fax || ""}
                  name="fax"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Email"
                  value={record.esercizio.eMailEsercizio || ""}
                  name="eMailEsercizio"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.eMailEsercizio}
                  error={validationErrors.eMailEsercizio.length > 0}
                />
                <TextField
                  label="Responsabili regionali"
                  value={record.esercizio.responsabiliRegionali || ""}
                  name="responsabiliRegionali"
                  onChange={handleChange}
                  className={classes.textfield}
                />

                <TextField
                  label="Responsabili area"
                  value={record.esercizio.responsabileArea || ""}
                  name="responsabileArea"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Email responsabile area"
                  value={record.esercizio.eMailResponsabileArea || ""}
                  name="eMailResponsabileArea"
                  onChange={handleChange}
                  className={classes.textfield}
                  helperText={validationErrors.eMailResponsabileArea}
                  error={validationErrors.eMailResponsabileArea.length > 0}
                />
                <TextField
                  label="Direttore vendite"
                  defaultValue={record.esercizio.direttoreVendite || ""}
                  name="direttoreVendite"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Dir/Socio 2023"
                  defaultValue={record.esercizio.direttore2023 || ""}
                  name="direttore2023"
                  onChange={handleChange}
                  className={classes.textfield}
                />
                <TextField
                  label="Consulente socio HACCP 2023"
                  defaultValue={record.esercizio.consulente2023 || ""}
                  className={classes.textfield}
                  name="consulente2023"
                  onChange={handleChange}
                />
                <TextField
                  label="Canale 2023"
                  defaultValue={record.esercizio.canale2023 || ""}
                  className={classes.textfield}
                  name="canale2023"
                  onChange={handleChange}
                />
                <TextField
                  label="Consulente socio HACCP 2022"
                  defaultValue={record.esercizio.consulente2022 || ""}
                  className={classes.textfield}
                  name="consulente2022"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

DatiEsercizioCommerciale.propTypes = {
  record: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default DatiEsercizioCommerciale;
