import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import SpinnerComponent from "../../../components/SpinnerComponent";
import IndietroButton from "../../../components/IndietroButton";
import ErrorDialog from "../../../components/ErrorDialog";

import { clearToken } from "../../../utils/storage";
import {
  getResponsabiliSicurezzaPerConsulente,
  getResponsabiliSicurezza,
  updateResponsabileSicurezza,
} from "../../../utils/api/responsabili_sicurezza_api";
import { getGruppi } from "../../../utils/api/gruppi_api";

import moment from "moment";

import theme from "../../../theme.js";

const styles = {
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  userdatacontainer: {
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
};

export default class FormModificaResponsabileSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        id: null,
        email: null,
        nome: null,
        cognome: null,
        telefono: null,
        activated: null,
        userId: null,
        validated: null,
        dataRegistrazione: null,
        terminiUsoAccettati: null,
        privacyPolicyAccettata: null,
        gruppoId: null,
      },
      validationErrors: {
        nome: "",
        cognome: "",
      },
      nomeGruppo: "",
      errorDialogVisible: false,
      errorDialogMessage: "",
      loading: true,
      notfoundRecord: false,
      saveButtonEnabled: false,
    };
  }

  componentDidMount() {
    this.fetchRecord();
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  handleChange = (event) => {
    this.props.onFormModified();
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let name = event.target.name;
    let value = event.target.value;
    let data = this.state.record;
    data[name] = value;
    let validationErrors = this.state.validationErrors;
    if (name === "nome" || name === "cognome") {
      validationErrors[name] = "";
    }
    this.setState({
      data,
      validationErrors,
    });
  };

  // This code is a 'patch': at the moment there is not an API request
  // to fetch a single ResponsabileSicurezza by ID.
  // Using the same GET request to fetch all responsabili associated to the consulente.
  fetchRecord = () => {
    this.setState({
      loading: true,
    });
    if (this.props.consulenteView && !this.props.superconsulenteView) {
      getResponsabiliSicurezzaPerConsulente(
        this.props.consulenteId,
        0,
        1000,
        "id"
      )
        .then((result) => {
          this.handleFetchSuccess(result);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
              notfoundRecord: true,
            });
          }
        });
    } else {
      getResponsabiliSicurezza(
        this.props.responsabileId,
        this.props.gruppoId,
        0,
        1000,
        "id"
      )
        .then((result) => {
          this.handleFetchSuccess(result);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
              notfoundRecord: true,
            });
          }
        });
    }
  };

  handleFetchSuccess = (result) => {
    if (result.length === 0) {
      this.setState({
        loading: false,
        notfoundRecord: true,
      });
    } else {
      let responsabile = null;
      for (let i = 0; i < result.length; i++) {
        let respSicurezza = result[i];
        if (
          respSicurezza.id === Number.parseInt(this.props.responsabileId, 10)
        ) {
          responsabile = respSicurezza;
          break;
        }
      }
      if (responsabile === null) {
        this.setState({
          loading: false,
          notfoundRecord: true,
        });
      } else {
        if (!this.props.superconsulenteView && !this.props.consulenteView) {
          this.setState({
            record: responsabile,
          });
          this.fetchGruppo();
        } else {
          this.setState({
            record: responsabile,
            loading: false,
          });
        }
      }
    }
  };

  fetchGruppo = () => {
    getGruppi(this.state.record.gruppoId, null, 0, 1, "id")
      .then((result) => {
        let nomeGruppo = "";
        if (result.length > 0) {
          nomeGruppo = result[0].nome;
        }
        this.setState({
          loading: false,
          nomeGruppo,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  validateForm = () => {
    let data = this.state.record;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "nome":
        if (value.length === 0) {
          validationErrors.nome =
            "Attenzione, occorre inserire il nome del responsabile";
        }
        break;
      case "cognome":
        if (value.length === 0) {
          validationErrors.cognome =
            "Attenzione, occorre inserire il cognome del responsabile";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = this.validateForm();
    if (isFormValid) {
      this.setState({
        loading: true,
      });
      updateResponsabileSicurezza(this.state.record)
        .then((result) => {
          this.setState({
            loading: false,
            saveButtonEnabled: false,
          });
          this.props.onFormSubmitted();
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
            });
          }
        });
    } //No-op if form isn't valid
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    moment.locale("it-IT");
    const dateFormat = "DD-MM-YYYY HH:mm";
    const disabledSubmitbutton =
      this.state.loading || !this.state.saveButtonEnabled;
    return (
      <Container
        id="form_container"
        style={{
          paddingLeft: "4px",
          paddingRight: "4px",
          maxWidth: "700px",
          minHeight: "500px",
        }}
      >
        {this.state.loading ? (
          <SpinnerComponent size={24} />
        ) : this.state.notfoundRecord ? (
          <Typography
            variant="h6"
            style={{ paddingTop: "6px", paddingBottom: "6px" }}
          >
            Nessun record trovato
          </Typography>
        ) : (
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati registrazione utente
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="ID responsabile"
                        disabled
                        value={
                          this.state.record.id === null
                            ? ""
                            : this.state.record.id
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Email"
                        disabled
                        value={
                          this.state.record.email === null
                            ? ""
                            : this.state.record.email
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Data registrazione"
                        disabled
                        value={
                          this.state.record.dataRegistrazione === null
                            ? ""
                            : moment(
                                this.state.record.dataRegistrazione
                              ).format(dateFormat)
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      {!this.props.superconsulenteView &&
                      !this.props.consulenteView ? (
                        <TextField
                          label="Gruppo di appartenenza"
                          disabled
                          value={this.state.nomeGruppo}
                          InputProps={{
                            readOnly: true,
                          }}
                          style={styles.textfield}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ paddingBottom: "10px" }}
                    >
                      <TextField
                        label="Registrazione confermata"
                        name="validated"
                        disabled
                        value={
                          this.state.record.validated === true ? "Sì" : "No"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Privacy policy accettata:"
                        disabled
                        value={
                          this.state.record.privacyPolicyAccettata ? "Si" : "No"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                      <TextField
                        label="Termini d'uso accettati:"
                        disabled
                        value={
                          this.state.record.terminiUsoAccettati ? "Si" : "No"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={styles.sectioncontainer}
              >
                <Typography
                  variant="h5"
                  style={{ paddingTop: "6px", paddingBottom: "6px" }}
                >
                  Dati responsabile punti vendita
                </Typography>
                <Grid container style={styles.userdatacontainer}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="column" alignItems="center">
                      <TextField
                        label="Nome*"
                        value={
                          this.state.record.nome === null
                            ? ""
                            : this.state.record.nome
                        }
                        name="nome"
                        onChange={this.handleChange}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.nome}
                        error={
                          this.state.validationErrors.nome.length > 0
                            ? true
                            : false
                        }
                      />
                      <TextField
                        label="Cognome*"
                        value={
                          this.state.record.cognome === null
                            ? ""
                            : this.state.record.cognome
                        }
                        name="cognome"
                        onChange={this.handleChange}
                        style={styles.textfield}
                        helperText={this.state.validationErrors.cognome}
                        error={
                          this.state.validationErrors.cognome.length > 0
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="column" alignItems="center">
                      <TextField
                        label="Telefono"
                        value={
                          this.state.record.telefono === null
                            ? ""
                            : this.state.record.telefono
                        }
                        name="telefono"
                        onChange={this.handleChange}
                        style={styles.textfield}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "20px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "right" }}
              >
                <IndietroButton isDisabled={this.state.loading} />
                <Button
                  type="button"
                  onClick={this.handleSubmit}
                  variant="contained"
                  size="medium"
                  disabled={disabledSubmitbutton}
                  style={{
                    color: theme.palette.secondary.main,
                    margin: "10px",
                    borderRadius: 30,
                    backgroundColor: disabledSubmitbutton
                      ? theme.palette.disabled.main
                      : theme.palette.cndColors.yellow,
                  }}
                >
                  Salva modifiche
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        ></ErrorDialog>
      </Container>
    );
  }
}

FormModificaResponsabileSicurezza.propTypes = {
  responsabileId: PropTypes.string.isRequired,
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
  consulenteView: PropTypes.bool.isRequired,
  onFormModified: PropTypes.func.isRequired,
  onFormSubmitted: PropTypes.func.isRequired,
};
