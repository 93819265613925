import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import selectTableHOC from "react-table/lib/hoc/selectTable";

import ActionButton from '../../../components/ActionButton';

import ReactTable from "react-table";
import { getConsulenti, getConsulentiFiltered, getTotaleConsulenti } from "../../../utils/api/consulenti_api";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const styles = {
  modalcontainer: {
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    backgroundColor: 'white',
    color: 'black',
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '26px',
    marginBottom: '26px',
    boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
    marginLeft: '20%',
    marginRight: '20%',
    //height: '85%'
    overflowY: 'scroll',
    height: '600px',
  },
  textfield: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '12px',
    width: '250px'
  },
  sectioncontainer: {
    // border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    //marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  rowcontainer: {
    flexDirection: 'row',
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: 'space-evely',

  },
  modalTitle: {
    fontSize: 30,
    alignSelf: "center",
    width: "40%"
  }
}
const SelectTable = selectTableHOC(ReactTable);

export default class ModalSelezioneConsulente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consulenti: [],
      colonneConsulenti: [],
      consulentiIdArray: [],
      selectedConsulente: -1,
      valueToPassForFilter:"",
      emailToPassForFilter:"",
      nameToPassForFilter:"",
      cognomeToPassForFilter:"",
      elementiTotali: 0,
      loadedPage: 0,
      totalPages: 1,
      loading: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("superconsulente") === "true") {
      let a = 0;
      if (this.props.gruppoId != null) {
        getTotaleConsulenti(this.props.gruppoId).then(result => {
          a = result;
          this.setState({
            totalPages: Math.ceil(a / 10),
          })
          this.getConsulentiForTable(0);
        }).catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
      }
    }
  }

  isNumber = (value) => {
    if (typeof value === "string") {
        return !isNaN(value);
    }
  }

  getConsulentiForTable = (page) => {
    getConsulenti(null, this.props.gruppoId, page, 10, "id").then(
      result => {
        if (result != null) {
          let newArray = this.state.consulenti;
          newArray = result;
          this.setState({
            consulenti: newArray,
          }, () => {
            let a = this.getConsulenteColumn(newArray);
            this.setState({
              colonneConsulenti: a,
              loading: false,
            })
          })
        }
      }
    ).catch((error) => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    });
  }
  getConsulentiForTableAfterLoading = (page) => {
    getConsulenti(null, this.props.gruppoId, page, 10, "id").then(
      result => {
        if (result != null) {
          let newArray = this.state.consulenti;
          newArray = result;
          this.setState({
            consulenti: newArray,
          })
        }
      }
    ).catch((error) => {
      if (error.status === 403) {
        this.props.handleInvalidToken();
      } else {
        this.props.handleError(true, error.message);
      }
    });
  }
 //Loaded page will allow to load the selected table page instead of forcing the loading of the first one
  getConsulenteColumn = (data) => {
    return [
      {
        Header: "Id",
        id: "IdConsulente",
        width: 155,
        filterable:false,
        /*Filter: cellInfo => <TextField InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => {
                if(this.isNumber(this.state.valueToPassForFilter)){
                if(this.state.valueToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",null,null,null).then((result) => {
            this.setState({
              consulenti:result,
            })
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }else{
                getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, null, null, "id",null,null,null).then((result) => {
                  this.setState({
                    consulenti:result,
                  })
                  }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
                })
              }}else{
                alert("Attenzione, l'id deve avere un valore numerico")
              }}}><Search></Search></IconButton>
            </InputAdornment>
          ),
        }} variant="standard" inputMode="numeric" value={this.state.valueToPassForFilter} onInput={(event) => {
          this.setState({
            valueToPassForFilter:event.target.value
          })
        }} onBlur={() => {
          if(this.state.valueToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
            this.setState({
              consulenti:result,
            })
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }
        }}></TextField>,*/
        accessor: data => data.id
      },
      {
        Header: "Email",
        id: "EmailConsulente",
        width: 205,
        Filter: cellInfo => <TextField InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => {
                if(this.isNumber(this.state.valueToPassForFilter)){
                if(this.state.emailToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage,100, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
            })
            }else{
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            }
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
        }else{
                getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, null, null, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
                  let numberOfPages = Math.ceil(result.length/10);
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
              totalPages:numberOfPages,
            })
            }else{
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            }
              }).catch((error) => {
              if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
                })
                }
              }else{
                alert("Attenzione, l'id deve avere un valore numerico")
              }}}><Search></Search></IconButton>
            </InputAdornment>
          ),
        }} variant="standard" value={this.state.emailToPassForFilter} onInput={(event) => {
          this.setState({
            emailToPassForFilter:event.target.value
          })
        }} onBlur={() => {
          if(this.isNumber(this.state.valueToPassForFilter)){
          if(this.state.emailToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage,100, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((response) => {
            getTotaleConsulenti(this.props.gruppoId).then((result) => {
            this.setState({
              consulenti:response,
              totalPages:Math.ceil(result/10),
            })
            })
           
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
        }
        }else{
          alert("Attenzione, l'id deve avere un valore numerico")
        }}}></TextField>,
        accessor: data => data.email
      },
      {
        Header: "Nome",
        id: "NomeConsulente",
        width: 180,
        Filter: cellInfo => <TextField InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => {
                if(this.isNumber(this.state.valueToPassForFilter)){
                if(this.state.nameToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
            })
            }else{
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            }
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }else{
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, null, null, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
            let numberOfPages = Math.ceil(result.length/10);
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
              totalPages:numberOfPages,
            })
            }else{
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            }
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }
          }else{
            alert("Attenzione, l'id deve avere un valore numerico")
          }}}><Search></Search></IconButton>
            </InputAdornment>
          ),
        }} variant="standard" value={this.state.nameToPassForFilter} onInput={(event) => {
          this.setState({
            nameToPassForFilter:event.target.value
          })
        }} onBlur={() => {
          if(this.isNumber(this.state.valueToPassForFilter)){
          if(this.state.nameToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((response) => {
            getTotaleConsulenti(this.props.gruppoId).then((result) => {
            this.setState({
              consulenti:response,
              totalPages:Math.ceil(result/10),
            })
            })
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }
        }else{
          alert("Attenzione, l'id deve avere un valore numerico")
        }}}></TextField>,
        accessor: data => data.nome
      },
      {
        Header: "Cognome",
        id: "CognomeConsulente",
        width: 180,
        Filter: cellInfo => <TextField InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => {
                if(this.isNumber(this.state.valueToPassForFilter)){
                if(this.state.cognomeToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
            })
            }else{
              this.setState({
              consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[]   
            })
            }
            }
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }else{
            getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, null,null, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((result) => {
              let numberOfPages = Math.ceil(result.length/10);
            if(this.state.consulentiIdArray.length === 0){
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            else{
            let item = result.find((element) => element.id === this.state.consulentiIdArray[0].id);
            if(item !== undefined){
            this.setState({
              consulenti:result,
              selectedConsulente:item.id,
              consulentiIdArray:this.state.consulentiIdArray,
              totalPages:numberOfPages,
            })
            }else{
              this.setState({
                consulenti:result,
              selectedConsulente:-1,
              consulentiIdArray:[],
              totalPages:numberOfPages,   
            })
            }
            }
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }
              }else{
                alert("Attenzione, l'id deve avere un valore numerico");
              }}}><Search></Search></IconButton>
            </InputAdornment>
          ),
        }} variant="standard" value={this.state.cognomeToPassForFilter} onInput={(event) => {
          this.setState({
            cognomeToPassForFilter:event.target.value
          })
        }} onBlur={() => {
          if(this.isNumber(this.state.valueToPassForFilter)){
          if(this.state.cognomeToPassForFilter.length === 0){
          getConsulentiFiltered(this.state.valueToPassForFilter, this.props.gruppoId, this.state.loadedPage, 10, "id",this.state.emailToPassForFilter,this.state.nameToPassForFilter,this.state.cognomeToPassForFilter).then((response) => {
            getTotaleConsulenti(this.props.gruppoId).then((result) => {
            this.setState({
              consulenti:response,
              totalPages:Math.ceil(result/10),
            })
            })
          }).catch((error) => {
            if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
          })
          }
        }else{alert("Attenzione, l'id deve avere un valore numerico")}}}></TextField>,
        accessor: data => data.cognome
      },
    ]
  }

  toggleSelection = (key, shift, row) => {
    this.setState({
      selectedConsulente: key.split("select-")[1]
    })
    let consulentiIdArray = [...this.state.consulentiIdArray];
    const keyIndex = consulentiIdArray.indexOf(key);
    if (keyIndex >= 0) {
      consulentiIdArray = [
        ...consulentiIdArray.slice(0, keyIndex),
        ...consulentiIdArray.slice(keyIndex + 1)
      ];
    } else {
      consulentiIdArray = []
      consulentiIdArray.push(row);
    }
    this.setState({ consulentiIdArray });
  };
  isSelected = key => {
    if (this.state.consulentiIdArray.some(e => e.id === key)) {
      return true
    }
  }

  render() {
    return (
      <Modal
        open={this.props.isModalOpen}
        onClose={this.props.closeModal}
      >
        <div style={styles.modalcontainer}>
          <div style={styles.modalsectioncontainer}>
            <Typography style={styles.modalTitle}>Selezionare il consulente da associare al nuovo manuale</Typography>
            <SelectTable
              selectType="radio"
              ref={r => { this.checkboxTable = r }}
              toggleSelection={this.toggleSelection}
              isSelected={this.isSelected}
              keyField={'id'}
              filterable={true}
              resizable={true}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              style={{ width: "67%", alignSelf: "center" }}
              defaultPageSize={10}
              loading={this.state.loading}
              pages={this.state.totalPages}
              onPageChange={(pageIndex,state) => {
                this.setState({
                    loadedPage:pageIndex,
                })
              }}
              manual
              onFetchData={(state, instance) => {
                this.setState({
                  loading: true,
                })
                let a = 0;
                if (this.props.gruppoId != null && state.filtered[0] === undefined) {
                  getTotaleConsulenti(this.props.gruppoId).then(result => {
                    a = result;
                    this.setState({
                      totalPages: Math.ceil(a / state.pageSize),
                    })
                  }).then(result => {
                    getConsulenti(null, this.props.gruppoId, state.page, state.pageSize, "id").then(
                      result => {
                        if (result != null) {
                          let newArray = this.state.consulenti;
                          newArray = result;
                          this.setState({
                            consulenti: newArray,
                          }, () => {
                            let a = this.getConsulenteColumn(newArray);
                            this.setState({
                              colonneConsulenti: a,
                              loading: false,
                            })
                          })
                        }
                      }
                    )
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.props.handleInvalidToken();
                    } else {
                      this.props.handleError(true, error.message);
                    }
                  });
                  this.setState({
                    loading: false,
                  })
                }else{
                  this.setState({
                    loading: false,
                  })
                }
              }}
              columns={this.state.colonneConsulenti}
              data={this.state.consulenti}
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun Consulente Trovato'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
              <ActionButton 
                onClick={this.props.closeModal} 
                label="Annulla"
                disabled={false}
                grayVersion={false}
                isRed={true}
              />
              <ActionButton 
                onClick={() => { this.props.openEserciziModal(this.state.selectedConsulente) }} 
                label="Prosegui"
                disabled={false}
                grayVersion={false}
              />
              {/*<GoToCreazioneManualeButton gruppoId={this.props.gruppoId} handleError={this.props.handleError} handleInvalidToken={this.props.handleInvalidToken} mode="Manuale" selectedConsulente={this.state.selectedConsulente} path={"/testWork/nuovoManuale"} label="Prosegui"></GoToCreazioneManualeButton>*/}
            </div>
          </div>
        </div>
      </Modal>)
  }
}